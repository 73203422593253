import React from "react";
import Button from "../../../components/button";
import { useNavigate, useNavigation } from "react-router-dom";

const NoClass = () => {
  const navigate = useNavigate();
  return (
    <section className='flex justify-center items-center flex-col gap-5 pt-20 pb-20'>
      <div className='text-center max-w-[28rem]'>
        <span className='font-semibold text-lg'>Oops, no item here.</span>
        <p className='text-sm'>
          Don’t panic, kindly click on the button below to add new classes.
          Classes will be visible here after being added.
        </p>
      </div>
      <Button
        iconClassName='h-[0.9rem] w-3'
        // isLoading={loading}
        title={"Add class"}
        onClick={() => navigate("/admin/classes/create-class")}
        bgColor={"bg-other"}
        hoverColor={""}
        textColor={"text-white"}
        className={"py-4 rounded-lg bg-brand w-[12rem]"}
      />
    </section>
  );
};

export default NoClass;

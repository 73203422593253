import { API } from '@/config';

export async function getStudentDashboard(data: any) {
	const response = await API.get(`/student/dashboard?date_type=${data?.date}&term=${data?.term}`);
	return response.data;
}

export async function getStudentSubjects(term: any) {
	const response = await API.get(`/student/subjects?term=${term}`);
	return response.data;
}
export async function getClassManagagementAPI() {
	const response = await API.get(`/student/classes`);
	return response.data;
}
export async function getCourseReportAPI(data: any) {
	const response = await API.get(
		`/student/course-reports?term=${data?.term}&student_class_id=${data?.classId}`
	);
	return response.data;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { registerStudentAPI } from '../../../services/authServices';
import { getClass, getRoles, getUsers } from '../../../services/Admin/dashboard';
import ImageInput from '../../image-input';
import Input from '../Input';
import Select from '../Select';
import DatePicker from '../DatePicker';

import Button from '../../button';
import SuccessModal from '../../Modal/SuccessModal';
import Modal from '@/components/Modal';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

const AddStudentForm = () => {
	const [modal, setModal] = useState(false);
	const [page, setPage] = useState(1);
	const registerStudentMutation = useMutation(registerStudentAPI);
	const [file, setFile] = useState<any>([]);
	const [parentFile, setParentFile] = useState<any>([]);

	const { data: classData, refetch } = useQuery(['class', ''], () => getClass(''));
	const [openParent, setOpenParent] = useState(false);

	const params = {
		page,
		role: 'PARENT',
	};
	const {
		data: parentData,
		refetch: parentRefetch,
		isLoading,
	} = useQuery(['subject', params], () => getUsers(params));

	const [date_joined, setDate_joined] = useState<any>();
	const [dob, setDob] = useState<any>();
	const [search, setSearch] = useState('');
	// Initialize state to keep track of selected parents
	const [selectedParents, setSelectedParents] = useState<any>([]);

	// Function to toggle selection of a parent
	const toggleSelectParent = (item: any) => {
		const isSelected = selectedParents.some((parent: any) => parent?.id === item.id);

		if (isSelected) {
			// If already selected, remove from the selected list
			const updatedParents = selectedParents.filter((parent: any) => parent?.id !== item.id);
			setSelectedParents(updatedParents);
		} else {
			// If not selected, add to the selected list
			const updatedParents = [...selectedParents, item];
			setSelectedParents(updatedParents);
		}
	};

	const navigate = useNavigate();

	let parentSearch = isLoading
		? []
		: parentData?.data?.filter((item: { [x: string]: { toString: () => string } }) => {
				return Object.keys(item)?.some((key) =>
					item[key].toString().toLowerCase().includes(search?.toString()?.toLowerCase())
				);
		  });
	const onParentSearch = (event: any) => {
		setSearch(event.target.value);
	};

	const darkMode = useSelector(selectDarkmode);

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className=''>
				<Formik
					initialValues={{
						role: '',
						first_name: '',
						last_name: '',
						other_name: '',
						gender: '',
						phone_number: '',
						email: '',
						residential_address: '',
						blood_group: '',
						genotype: '',
						disability: '',
						unique_number: '',
						account_number: '',
						account_name: '',
						next_of_kin_name: '',
						next_of_kin_phone_number: '',
						next_of_kin_email: '',
						next_of_kin_address: '',
						next_of_kin_relationship: '',
						dob: '',
						class: '',
						subject: '',
						password: '',
						date_joined: '',
						parent: '',

						first_name_parent: '',
						last_name_parent: '',
						other_name_parent: '',
						gender_parent: '',
						phone_number_parent: '',
						email_parent: '',
						residential_address_parent: '',
					}}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const formData = new FormData();

						formData.append('role', values.role);
						formData.append('first_name', values.first_name);
						formData.append('last_name', values.last_name);
						formData.append('other_name', values.other_name);
						formData.append('gender', values.gender);
						formData.append('phone_number', values.phone_number);
						formData.append('email', values.email);
						formData.append('address', values.residential_address);
						formData.append('blood_group', values.blood_group);
						formData.append('genotype', values.genotype);
						formData.append('disability', values.disability);
						formData.append('unique_number', values.unique_number);
						formData.append('role', 'STUDENT');
						formData.append('password', values.password);
						formData.append('dob', dob);
						formData.append('date_joined', date_joined);
						formData.append('profile_image', file[0]);
						formData.append('class_id', values.class);

						if (selectedParents?.length > 0) {
							selectedParents?.forEach((item: any, i: any) => {
								formData.append(`parent_ids[${i}]`, item.id);
							});
						} else {
							formData.append('parents[0][first_name]', values.first_name_parent);
							formData.append('parents[0][last_name]', values.last_name_parent);
							formData.append('parents[0][other_name]', values.other_name_parent);
							formData.append('parents[0][gender]', values.gender_parent);
							formData.append('parents[0][phone_number]', values.phone_number_parent);
							formData.append('parents[0][email]', values.email_parent);
							formData.append('parents[0][address]', values.residential_address_parent);
						}

						try {
							const res = await registerStudentMutation.mutateAsync(formData);

							if (res?.data) {
								setModal(true);
								resetForm();
								file.length = 0;
							}
						} catch (err: any) {
							if (err?.response?.data.message) {
								toast.error(err?.response?.data.message, {
									duration: 3000,
								});
							} else {
								toast.error('Something went wrong. Try again later', {
									duration: 3000,
								});
							}
						}
					}}
				>
					{({
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form>
							<div
								className={`border mt-6 lg:px-7 px-4 rounded-lg ${
									darkMode ? 'border-gray-700' : ''
								}`}
							>
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Personal information</span>
									<div className='py-5 w-40'>
										<ImageInput file={file} setFile={setFile} />
									</div>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												First name
											</label>
											<Input
												name={'first_name'}
												placeholder={'Full name'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Last name
											</label>
											<Input
												name={'last_name'}
												placeholder={'Last name'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Gender
											</label>
											<Select
												name='gender'
												className={`${darkMode ? 'bg-dark-200  border-gray-700' : ''}`}
											>
												<option value=''>Select a gender</option>
												<option value='male'>Male</option>
												<option value='female'>Female</option>
											</Select>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Phone number
											</label>
											<Input
												name={'phone_number'}
												placeholder={'Phone number'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'datejoined'}
												type={'date'}
												placeholder={'Date joined'}
												value={date_joined}
												setValue={setDate_joined}
												className={`${darkMode ? 'bg-transparent  border-gray-700' : ''}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'dob'}
												type={'date'}
												placeholder={'Date of Birth'}
												value={dob}
												setValue={setDob}
												className={`${darkMode ? 'bg-dark-200  border-gray-700' : ''}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Residential address
											</label>
											<Input
												name={'residential_address'}
												placeholder={'Enter residential address'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Email address
											</label>
											<Input
												name={'email'}
												placeholder={'Enter Email address'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Unique number
											</label>
											<Input
												name={'unique_number'}
												placeholder={'Enter unique number'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Class
											</label>
											{classData?.data?.length > 0 && (
												<Select
													name='class'
													placeholder=''
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												>
													<option value=''></option>
													{classData?.data?.map((item: any, index: number) => (
														<option key={index} value={item.id}>
															{item?.name}
														</option>
													))}
												</Select>
											)}
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? ' border-gray-700' : ''}`} />

								<div className='pb-7 py-6'>
									<span className='font-medium text-sm'>Parent information</span>
									<div
										className={`flex ${
											selectedParents?.length < 1 ? 'justify-between' : 'justify-end'
										} items-start flex-wrap w-full`}
									>
										{selectedParents?.length < 1 && (
											<div className='py-3 w-40'>
												<ImageInput file={parentFile} setFile={setParentFile} />
											</div>
										)}

										<Button
											iconClassName='h-[0.9rem] w-3'
											title={'Select parent'}
											bgColor={'bg-other'}
											hoverColor={''}
											type={'button'}
											onClick={() => setOpenParent(true)}
											textColor={` ${darkMode ? 'text-gray-300' : 'text-white'}`}
											className={`py-0 rounded-lg ${darkMode ? 'bg-black ' : 'bg-other '} w-40`}
										/>
									</div>

									{selectedParents?.length > 0 ? (
										<>
											<div className='w-full px-6  overflow-y-auto flex justify-between items-center flex-wrap mt-4'>
												{selectedParents?.map((item: any, index: any) => (
													<div
														key={index}
														onClick={() => toggleSelectParent(item)}
														className={`w-full lg:w-[48%] cursor-pointer mb-4 flex justify-start items-center flex-wrap rounded-lg p-2 ${
															selectedParents.some((parent: any) => parent.id === item.id)
																? `bg-brand/5`
																: 'bg-[#F5F7F9]'
														}`}
													>
														<img
															src={item?.profile_image}
															alt='success'
															className='rounded-full h-12 w-12'
														/>

														<div className='ml-4 w-[60%]'>
															<p className={`text-xs font-semibold`}>
																{item?.first_name} {item?.last_name}
															</p>
														</div>

														<div className='flex justify-end items-center'>
															{selectedParents.some((parent: any) => parent.id === item?.id) ? (
																<small className='text-green-700 text-xs'>unselect</small>
															) : (
																<small className='text-green-700 text-xs'>select</small>
															)}
														</div>
													</div>
												))}
											</div>
										</>
									) : (
										<div className='flex justify-between items-start flex-wrap'>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													First name
												</label>
												<Input
													name={'first_name_parent'}
													placeholder={'Full name'}
													className={`${
														darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
													}`}
												/>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Last name
												</label>
												<Input
													name={'last_name_parent'}
													placeholder={'Last name'}
													className={`${
														darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
													}`}
												/>
											</div>

											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Gender
												</label>

												<Select
													name='gender_parent'
													className={`${darkMode ? 'bg-dark-200  border-gray-700' : ''}`}
												>
													<option value=''>Select a gender</option>
													<option value='male'>Male</option>
													<option value='female'>Female</option>
												</Select>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Phone number
												</label>
												<Input
													name={'phone_number_parent'}
													placeholder={'Phone number'}
													className={`${
														darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
													}`}
												/>
											</div>

											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Residential address
												</label>
												<Input
													name={'residential_address_parent'}
													placeholder={'Enter residential address'}
													className={`${
														darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
													}`}
												/>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Email address
												</label>
												<Input
													name={'email_parent'}
													placeholder={'Enter Email address'}
													className={`${
														darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
													}`}
												/>
											</div>
										</div>
									)}
								</div>

								<hr className={`${darkMode ? '  border-gray-700' : ''}`} />

								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Medical information</span>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400'>
												Blood Group
											</label>
											<Input
												name={'blood_group'}
												placeholder={'Blood group'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400'>
												Genotype
											</label>
											<Input
												name={'genotype'}
												placeholder={'genotype'}
												className={`${
													darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400'>
												Disability
											</label>
											<Select
												name='disability'
												className={`${darkMode ? 'bg-dark-200  border-gray-700' : ''}`}
											>
												<option value=''>select an option</option>
												<option value='yes'>Yes</option>
												<option value='no'>No</option>
											</Select>
										</div>
									</div>
								</div>

								<hr className={`${darkMode ? '  border-gray-700' : ''}`} />
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Login information</span>

									<div className=' w-full lg:w-[49%] mt-6'>
										<label htmlFor='text' className='text-gray-400'>
											Password
										</label>
										<Input
											name={'password'}
											placeholder={'Enter password'}
											password
											className={`${
												darkMode ? 'bg-dark-200  border-gray-700 placeholder:text-gray-600' : ''
											}`}
										/>
									</div>
								</div>
								<div className='flex justify-center items-center'>
									<Button
										iconClassName='h-[0.9rem] w-3'
										isLoading={isSubmitting}
										title={'Add Student'}
										bgColor={'bg-other'}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  '}
									/>
								</div>

								<SuccessModal
									isOpen={modal}
									isClose={() => {
										navigate('/admin/students');
										setModal(false);
									}}
									title={'Student has been added successfully to the system.'}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>

			<Modal maxWidth='xs' open={openParent} close={() => setOpenParent(false)}>
				<div className='justify-center items-center flex flex-col px-6'>
					<input
						placeholder='search parent'
						value={search}
						onChange={onParentSearch.bind(this)}
						className={`appearance-none border   ${
							darkMode ? 'border-none bg-dark-200 text-gray-300' : 'border-gray-300'
						} rounded-sm w-full py-2 px-3 mb-4`}
					/>
					<div className='w-full px-6 h-[50vh]  overflow-y-auto'>
						{parentSearch?.map((item: any, index: any) => (
							<div
								key={index}
								onClick={() => toggleSelectParent(item)}
								className={`w-full mb-4 cursor-pointer  flex justify-start items-center flex-wrap rounded-lg p-2 ${
									selectedParents.some((parent: any) => parent.id === item.id)
										? `  ${darkMode ? 'bg-other' : ' bg-brand/5'}`
										: `${darkMode ? 'bg-gray-300' : 'bg-[#F5F7F9]'}`
								}`}
							>
								<img src={item?.profile_image} alt='success' className='rounded-full h-12 w-12' />

								<div className='ml-4 w-[60%]'>
									<p className={`text-xs font-semibold ${darkMode ? 'text-black' : ''}`}>
										{item?.first_name} {item?.last_name}
									</p>
								</div>

								<div className='flex justify-end items-center'>
									{selectedParents.some((parent: any) => parent.id === item?.id) ? (
										<small className='text-green-700 text-xs'>unselect</small>
									) : (
										<small className='text-green-700 text-xs'>select</small>
									)}
								</div>
							</div>
						))}
					</div>
					<div>
						<Button
							iconClassName='h-[0.9rem] w-3'
							// isLoading={loading}

							title={'Select parent'}
							bgColor={'bg-other'}
							onClick={() => (selectedParents?.length > 0 ? setOpenParent(false) : {})}
							hoverColor={''}
							textColor={selectedParents?.length > 0 ? 'text-white' : 'text-gray-700'}
							className={`py-2 rounded-lg ${
								selectedParents?.length > 0 ? 'bg-brand' : 'bg-brand/30'
							} w-[12rem] mt-4`}
						/>
						<Button
							iconClassName='h-[0.9rem] w-3'
							// isLoading={loading}
							title={'Close'}
							//   bgColor={"bg-other"}
							onClick={() => setOpenParent(false)}
							hoverColor={''}
							textColor={'text-gray-800'}
							className={`py-2 rounded-lg  ${
								darkMode ? 'bg-dark-200 text-white border-none' : 'bg-white border'
							}   border-gray-500 w-[12rem] mt-1  mb-8`}
						/>
					</div>
				</div>
			</Modal>
		</main>
	);
};

export default AddStudentForm;

import React, { useState } from "react";
import DashboardHeader from "../../../../components/headers/dashboard-header";
import Button from "../../../../components/button";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import { success } from "../../../../assets";
import { Formik, Form } from "formik";
import toast from "react-hot-toast";
import Input from "../../../../components/form/Input";
import { useMutation } from "react-query";
import { createSubject } from "../../../../services/Admin/subject";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const AddSubject = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const createSubjectMutation = useMutation(createSubject);
  const darkMode = useSelector(selectDarkmode);

  return (
    <main className="lg:px-8 xs:px-4 mt-7">
      <DashboardHeader
        title="Subjects 📚"
        subtitle="Add and manage all subjects here"
      />
      <div className="">
        <span>Add new subject</span>
        <div className="mt-7 lg:px-24 xs:px-0">
          <div
            className={`border rounded-lg ${darkMode ? " border-gray-700" : ""}
                         `}
          >
            <Formik
              initialValues={{
                name: "",
                description: "",
              }}
              // validationSchema={loginSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const formData = {
                  name: values.name,
                  description: values.description,
                };

                try {
                  const res = await createSubjectMutation.mutateAsync(formData);

                  if (res?.data) {
                    setModal(true);
                    toast.success("Event Created Successfully", {
                      duration: 5000,
                    });
                    resetForm();
                  }
                } catch (err: any) {
                  if (err?.response?.data.message) {
                    toast.error(err?.response?.data.message, {
                      duration: 3000,
                    });
                  } else {
                    toast.error("Something went wrong. Try again later", {
                      duration: 3000,
                    });
                  }
                }
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="p-4 lg:p-8 ">
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Subject Title
                      </label>
                      <Input
                        name={"name"}
                        placeholder={""}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className="flex flex-col">
                      {" "}
                      <label htmlFor="text" className="text-gray-400">
                        Description
                      </label>
                      <textarea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        placeholder=""
                        rows={4}
                        className={`border rounded-lg outline-none px-3 pt-1 ${
                          darkMode ? "border-gray-700 bg-dark-200" : ""
                        }`}
                      />
                    </div>

                    <div className="justify-center items-center flex">
                      <Button
                        iconClassName="h-[0.9rem] w-3"
                        isLoading={isSubmitting}
                        title={"Add subject"}
                        bgColor={"bg-other"}
                        hoverColor={""}
                        textColor={"text-white"}
                        className={
                          "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-7 self-center "
                        }
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {modal && (
        <Modal
          maxWidth="xs"
          open={() => setModal(true)}
          close={() => setModal(false)}
        >
          <div className="justify-center items-center flex flex-col gap-3">
            <img src={success} alt="success" width={100} height={70} />
            <Typography textStyle="text-center">
              <div className="lg:px-0 xs:px-4">
                <span className="text-2xl font-medium">Action Successful</span>
                <p>Subject has been added successfully to the system.</p>
              </div>
            </Typography>
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-other"}
              onClick={() => {
                navigate("/admin/subjects");
                setModal(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={
                "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-7 self-center "
              }
            />
          </div>
        </Modal>
      )}
    </main>
  );
};

export default AddSubject;

import { darkmode, lightmode } from '../../../assets';
import Modal from '../../../components/Modal';
import { useState } from 'react';
import Button from '../../../components/button';
import { useDispatch } from 'react-redux';
import { selectDarkmode, setDarkMode } from '@/states/slices/authReducer';
import { useAppSelector } from '@/hooks';

const modes = [
	{
		mode1: darkmode,
		label: 'dark mode',
	},
	{
		mode1: lightmode,

		label: 'light mode',
		// text: 'text-gray-600',
	},
];
const ThemeBox = () => {
	const [isOpen, setIsopen] = useState<boolean>(false);
	const [data, setData] = useState<string>('');
	const dispatch = useDispatch();
	const darkMode = useAppSelector(selectDarkmode);
	const isClose = () => {
		setIsopen(false);
	};
	return (
		<div
			className={`${!darkMode ? 'bg-white text-dark-200' : 'text-white bg-dark-200'} px-6 w-full`}
		>
			<span>Change theme</span>
			<div className='py-7 flex gap-6'>
				{modes.map((type) => (
					<div
						className='hover:border-brand hover:border rounded-lg cursor-pointer'
						onClick={() => {
							setData(type.label);
							setIsopen(true);
						}}
					>
						<img src={type.mode1} alt='mode' width={500} height={500} />
						<span className={`capitalize flex justify-center items-center mt-4 font-medium`}>
							{type.label}
						</span>
					</div>
				))}
			</div>
			<Modal open={isOpen} close={isClose} maxWidth='xs'>
				<div className={`${!darkMode ? 'text-dark' : 'text-white'} `}>
					<div className='justify-center flex items-center flex-col gap-6'>
						<span className='text-2xl font-semibold'>Switch to dark mode?</span>
						<p className='text-center'>
							Are you sure you want to switch to dark mode theme now, select an option below to
							continue.
						</p>
					</div>

					<Button
						iconClassName='h-[0.9rem] w-3'
						// isLoading={loading}
						onClick={() => {
							dispatch(setDarkMode(data === 'light mode' ? false : true));
							setIsopen(false);
						}}
						title={`Switch to ${data}`}
						bgColor={'bg-brand'}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-4 rounded-lg bg-brand w-full flex mt-10 mb-4'}
					/>
					<Button
						iconClassName='h-[0.9rem] w-3'
						// isLoading={loading}
						onClick={() => setIsopen(false)}
						title={'cancel'}
						bgColor={'bg-brand'}
						hoverColor={''}
						textColor={`${!darkMode ? 'text-dark' : 'text-white'} `}
						className={'py-4 rounded-lg bg-transparent border w-full mb-10'}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default ThemeBox;

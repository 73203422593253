/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { registerParentAPI, registerTeacherAPI } from '../../../services/authServices';
import {
	getAllUsers,
	getClass,
	getRoles,
	getStudent,
	getUsers,
} from '../../../services/Admin/dashboard';
import { getSubject } from '../../../services/Admin/subject';
import ImageInput from '../../image-input';
import Input from '../Input';
import Select from '../Select';
import Button from '../../button';
import SuccessModal from '../../Modal/SuccessModal';
import DropDownV2 from '@/components/menu-dropdown/dropDownV2';
import MultipleSelectCheckmarksV2 from '@/components/Multiselect/variant';
import { subjectData } from '@/mock/data';
import { TableComponent } from '@/components/table/Table';
import EmptyStudentData from '@/pages/admin/Students/contents/emptyStudentData';
import StudentSelector from '@/components/Multiselect/selctor';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

const AddParentForm = () => {
	const [modal, setModal] = useState(false);
	const registerParentMutation = useMutation(registerParentAPI);
	const [classValue, setClassValue] = useState<any>([]);
	const [nameSelected, SetNameSelected] = useState<any>([]);
	const [file, setFile] = useState<any>([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);

	const { data: studentData, refetch } = useQuery(['all students', 'STUDENT'], () =>
		getAllUsers('STUDENT')
	);
	const navigate = useNavigate();
	const darkMode = useSelector(selectDarkmode);
	const filteredStudents = studentData?.data?.filter((studentData: any) => {
		const fullName = `${studentData?.first_name} ${studentData?.last_name}`;
		return nameSelected?.includes(fullName);
	});

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className=''>
				<Formik
					initialValues={{
						role: '',
						first_name: '',
						last_name: '',
						other_name: '',
						gender: '',
						phone_number: '',
						email: '',
						residential_address: '',
						blood_group: '',
						genotype: '',
						disability: '',
						bank_name: '',
						account_number: '',
						account_name: '',
						next_of_kin_name: '',
						next_of_kin_phone_number: '',
						next_of_kin_email: '',
						next_of_kin_address: '',
						next_of_kin_relationship: '',
						dob: '',
						class: '',
						subject: '',
						password: '',
						date_joined: '',
					}}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const formData = new FormData();

						formData.append('first_name', values.first_name);
						formData.append('last_name', values.last_name);
						formData.append('other_name', values.other_name);
						formData.append('gender', values.gender);
						formData.append('phone_number', values.phone_number);
						formData.append('email', values.email);
						formData.append('address', values.residential_address);

						for (let i = 0; i < classValue.length; i++) {
							formData.append(`permissions[]`, classValue[i]?.name);
						}

						if (file?.length > 0) {
							formData.append('profile_image', file[0]);
						}

						if (filteredStudents?.length > 0) {
							for (let i = 0; i < filteredStudents?.length; i++) {
								formData.append(`children[${i}]`, filteredStudents[i]?.id);
							}
						}

						try {
							const res = await registerParentMutation.mutateAsync(formData);

							if (res?.data) {
								setModal(true);
								resetForm();
								file.length = 0;
							}
						} catch (err: any) {
							if (err?.response?.data.message) {
								toast.error(err?.response?.data.message, {
									duration: 3000,
								});
							} else {
								toast.error('Something went wrong. Try again later', {
									duration: 3000,
								});
							}
						}
					}}
				>
					{({
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form>
							<div
								className={`border mt-6 lg:px-7 px-4 rounded-lg ${
									darkMode ? 'border-gray-700' : ''
								}`}
							>
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Personal information</span>
									<div className='py-5 w-40'>
										<ImageInput file={file} setFile={setFile} />
									</div>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												First name
											</label>
											<Input
												name={'first_name'}
												placeholder={'Full name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Last name
											</label>
											<Input
												name={'last_name'}
												placeholder={'Last name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Gender
											</label>
											<Select
												name='gender'
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											>
												<option value=''>Select a gender</option>
												<option value='male'>Male</option>
												<option value='female'>Female</option>
											</Select>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Phone number
											</label>
											<Input
												name={'phone_number'}
												placeholder={'Phone number'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Residential address
											</label>
											<Input
												name={'residential_address'}
												placeholder={'Enter residential address'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Email address
											</label>
											<Input
												name={'email'}
												placeholder={'Enter Email address'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div>
											<label htmlFor='text' className='text-xs text-gray-400'>
												Add student
											</label>
											{studentData?.data?.length > 0 && (
												// this was created for any array of objects and keys for online the student

												<StudentSelector
													data={studentData?.data}
													studentName={nameSelected}
													setStudentName={SetNameSelected}
												/>

												// the multiselector wasn't passing the object key corectly
												// <MultipleSelectCheckmarksV2
												//   data={studentData?.data}
												//   personName={nameSelected}
												//   setPersonName={SetNameSelected}
												// />
											)}
										</div>
									</div>
								</div>
								<hr />

								<hr className={`${darkMode ? ' border-gray-700' : ''}`} />
								{/* <div className='pb-7 py-6'>
                  <span className='font-medium text-lg'>Login information</span>

                  <div className=' w-full lg:w-[49%] mt-6'>
                    <label htmlFor='text' className='text-gray-400'>
                      Password
                    </label>
                    <Input
                      name={"password"}
                      placeholder={"Enter password"}
                      password
                    />
                  </div>
                </div> */}

								{/* <div className=' w-full lg:w-[49%]'>
                      <label htmlFor='text' className='text-gray-400 text-xs'>
                        Subject
                      </label>
                      {subjectData?.data?.length > 0 && (
                        <MultipleSelectCheckmarksV2
                          data={subjectData?.data}
                          personName={subjectValue}
                          setPersonName={setSubjectValue}
                        />
                      )}
                    </div> */}
								<div className='flex justify-center items-center'>
									<Button
										iconClassName='h-[0.9rem] w-3'
										isLoading={isSubmitting}
										title={'Add Parent'}
										bgColor={'bg-other'}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  '}
									/>
								</div>

								<SuccessModal
									isOpen={modal}
									isClose={() => {
										navigate('/admin/users-and-roles');
										setModal(false);
									}}
									title={'Parent has been added successfully to the system.'}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</main>
	);
};

export default AddParentForm;

import ClassManagement from '@/pages/teachers/Class-management';
import CourseReport from '@/pages/teachers/Course-report';
import Dashboard from '@/pages/teachers/Dashboard';
import Student from '@/pages/teachers/Students';
import Settings from '@/pages/Settings';
import Attendance from '@/pages/teachers/Attendance/attendance';
import AddStudents from '@/pages/teachers/Students/contents/add_student';
import EditStudents from '@/pages/teachers/Students/contents/edit_students';
import EditReport from '@/pages/teachers/Course-report/EditReport';

import Profile from '@/pages/teachers/Profile';
import EditProfile from '@/pages/teachers/Profile/EditProfile';
import MarkAttendance from '@/pages/teachers/Attendance/MarkAttendance';
import ViewStudents from '@/pages/teachers/Students/ViewStudents';

export const teachersRoutes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Dashboard,
		path: 'teacher',
	},
	{
		id: '2',
		title: 'Students',
		component: Student,
		path: 'teacher/students',
	},
	{
		id: '39',
		title: 'Students',
		path: 'teacher/Students/view',
		component: ViewStudents,
		alt: '',
	},
	{
		id: '3',
		title: 'Classes',
		component: ClassManagement,
		path: 'teacher/class-management',
	},
	{
		id: '4',
		title: 'Course report',
		component: CourseReport,
		path: 'teacher/course-report',
	},
	{
		id: '5',
		title: 'Edit Course report',
		component: EditReport,
		path: 'teacher/course-report/edit',
	},

	{
		id: '7',
		title: 'Settings',
		component: Settings,
		path: 'teacher/settings',
	},

	{
		id: '11',
		title: '',
		component: EditStudents,
		path: 'teacher/edit-page',
	},
	{
		id: '12',
		title: '',
		component: AddStudents,
		path: 'teacher/add-students',
	},

	{
		id: '13',
		title: 'Profile',
		component: Profile,
		path: 'teacher/profile',
	},
	{
		id: '14',
		title: 'Edit Profile',
		component: EditProfile,
		path: 'teacher/profile/edit-profile',
	},
	{
		id: '15',
		title: 'Settings',
		component: Settings,
		path: 'teacher/settings',
	},
	{
		id: '16',
		title: 'Attendance',
		component: Attendance,
		path: 'teacher/attendance',
	},
	{
		id: '17',
		title: 'Attendance',
		component: MarkAttendance,
		path: 'teacher/attendance/mark-attendance',
	},
];

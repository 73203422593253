import * as React from 'react';
import { useTable } from 'react-table';
import { bag, edit } from '../../../../../assets';
import { UsersData } from '../../../../../mock/data';
import DeleteUser from '../../modals/DeleteUser';

const PopModal = () => {
	const [newModal, setNewModal] = React.useState(false);

	return (
		<div className='flex flex-col top-0 right-0 rounded-lg mt-[24rem] mr-14 absolute shadow-md px-8  border-2 bg-white gap-2 pt-5 pb-5'>
			<a
				href='/admin/users-and-roles/edit_users'
				type='button '
				className='hover:bg-gray-200 px-2 py-2 rounded-lg flex gap-3'
			>
				<img src={edit} width={20} height={20} alt='edit icon' />
				<span>Edit user details</span>
			</a>

			<div>
				<button
					type='button '
					className='flex gap-3 hover:bg-gray-200 px-2 py-2 rounded-lg'
					onClick={() => setNewModal(true)}
				>
					<img src={bag} width={20} height={20} alt='delete icon' />
					<span className='text-brand'>Delete user</span>
				</button>
				{newModal && (
					<DeleteUser isOpen={() => setNewModal(true)} isClose={() => setNewModal(false)} />
				)}
			</div>
		</div>
	);
};
const UserData = () => {
	const [openModal, setOpenModal] = React.useState(false);
	const data = React.useMemo(() => UsersData, []);
	const columns = React.useMemo(
		() => [
			{
				Header: 'USER NAME',
				accessor: 'name',
			},
			{
				Header: 'EMAIL ADDRESS',
				accessor: 'email',
			},
			{
				Header: 'ROLE',
				accessor: 'role',
			},
			{
				Header: 'DATE ADDED',
				accessor: 'date',
			},
			{
				Header: 'STATUS',
				accessor: 'status',
			},
			{
				Header: '',
				accessor: 'end',
			},
		],
		[]
	);
	const cellStylesArray = [
		{ color: '#93002F', fontSize: '2rem' },
		{ color: '#93002F', fontSize: '2rem' },
		{ color: '#93002F', fontSize: '2rem' },
	];

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});

	return (
		<div>
			<div className='overflow-x-hidden lg:overflow-x-hidden xs:overflow-x-scroll'>
				<table
					{...getTableProps()}
					className=' lg:w-full xs:w-[1000px] table-auto rounded border-collapse border border-gray-300'
				>
					<thead className=' bg-[#93002F]'>
						{headerGroups.map((headerGroup, i) => (
							<tr
								key={i}
								{...headerGroup.getHeaderGroupProps()}
								className='px-6 border rounded-t-lg'
							>
								{headerGroup.headers.map((column, i) => (
									<th
										key={i}
										{...column.getHeaderProps()}
										className='py-4 pr-4 text-white font-[500]'
									>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, rowIndex) => {
							prepareRow(row);
							return (
								<tr key={row.id} {...row.getRowProps} className='border'>
									{row.cells.map((cell, cellIndex) => {
										const lastIndex = cellIndex === row.cells.length - 1;
										const lastColor = lastIndex
											? cellStylesArray[rowIndex % cellStylesArray.length]
											: {};

										if (cellIndex === 0) {
											if (row.original.Student === row.original.Student.length > 0) {
												const StudentData = row.Student;
												const name = StudentData.name;
												const image = StudentData.image;
												return (
													<td
														key={cellIndex}
														{...cell.getCellProps()}
														className='px-8 py-3 rounded-full mt-4 text-center'
														onClick={() => setOpenModal((prev) => !prev)}
														style={lastColor}
													>
														<div>
															<img
																src={image}
																alt={name}
																width={40}
																height={40}
																className='rounded-full'
															/>
															<span>{name}</span>
														</div>
													</td>
												);
											} else {
												//  fallback if Student is undefined
												return (
													<td
														key={cellIndex}
														{...cell.getCellProps()}
														className='px-8 py-3 rounded-full mt-4 text-center'
													>
														No Student Data
													</td>
												);
											}
										} else {
											return (
												<td
													key={cellIndex}
													{...cell.getCellProps()}
													className={`px-8 py-3 rounded-full mt-4 text-center ${
														lastIndex ? 'cursor-pointer' : ''
													}`}
													onClick={() => setOpenModal((prev) => !prev)}
													style={lastColor}
												>
													{cell.render('Cell')}
												</td>
											);
										}
									})}
								</tr>
							);
						})}
						{openModal && <PopModal />}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UserData;

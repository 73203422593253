import {
	Admin,
	Dot,
	EVENTIMG,
	Line,
	Staff,
	Teacher,
	UserAdmin,
	UserParent,
	UserStaff,
	UserStudent,
	UserTeacher,
	arrowRight,
	avatar,
	babe,
	blue,
	download,
	eventImage,
	green,
	ongoing,
	parent,
	stepper,
	stepper2,
	stepper3,
	student,
	upcoming,
	yellow,
} from '../../assets';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import AddTeachersForm from '../../components/form/authForms/AddTeacher';

export const eventData = [
	{
		title: 'Interhouse sports competition',
		date: '24th Mar, 2022.',
	},
	{
		title: 'Interhouse sports competition',
		date: '24th Mar, 2022.',
	},
	{
		title: 'Interhouse sports competition',
		date: '24th Mar, 2022.',
	},
	{
		title: 'Interhouse sports competition',
		date: '24th Mar, 2022.',
	},
];
export const eventDetails = [
	{
		title: 'Interhouse sports competition',
		date: 'Event title:',
	},
	{
		title: '24th march, 2023',
		date: 'Event date:',
	},
	{
		title: '12:34PM',
		date: 'Event time:',
	},
	{
		title: 'School stadium',
		date: 'Location:',
	},
];

export const SideLinks = [
	{
		href: '/dashboard',
		title: 'Dashboard',
		label: 'dashboard',
	},
	{
		href: '/subject-page',
		title: 'Subject',
		label: 'subject',
	},
	{
		href: '/class-page',
		title: 'Classes',
		label: 'class',
	},
	{
		href: '/result-page',
		title: 'My result',
		label: 'result',
	},
	{
		href: '/profile-page',
		title: 'Profile',
		label: 'profile',
	},
	{
		href: '/setting-page',
		title: 'Settings',
		label: 'setting',
	},
];
export const TeacherSideLink = [
	{
		href: '/teachers/dashboard',
		title: 'Dashboard',
		label: 'dashboard',
	},
	{
		href: '/teachers/student-page',
		title: 'Student',
		label: 'subject',
	},
	{
		href: '/class-page',
		title: 'Class management',
		label: 'class',
	},
	{
		href: '/result-page',
		title: 'Course report',
		label: 'result',
	},
	{
		href: '/profile-page',
		title: 'Profile',
		label: 'profile',
	},
	{
		href: '/setting-page',
		title: 'Settings',
		label: 'setting',
	},
];
export const AdminSideLink = [
	{
		href: '/admin/dashboard',
		title: 'Dashboard',
		label: 'dashboard',
	},
	{
		href: '',
		title: 'Attendance',
		label: 'attendance',
	},
	{
		href: '/admin/calender_event',
		title: 'Calender Event',
		label: 'calenderEvent',
	},
	{
		href: '',
		title: 'Time Table',
		label: 'subject',
	},
	{
		href: '/admin/classes',
		title: 'Classes',
		label: 'classes',
	},
	{
		href: '/admin/Subject',
		title: 'Subject',
		label: 'subject',
	},
	{
		href: '/admin/Student',
		title: 'Student',
		label: 'student',
	},
	{
		href: '/admin/Teachers',
		title: 'Teachers',
		label: 'teachers',
	},
	{
		href: '/admin/Users&Roles',
		title: 'Users & Roles',
		label: 'usersRoles',
	},
	{
		href: '/parents/profile-page',
		title: 'Profile',
		label: 'profile',
	},
	{
		href: '/parents/setting-page',
		title: 'Settings',
		label: 'setting',
	},
];
export const ParentSideLink = [
	{
		href: '/parents/dashboard',
		title: 'My Ward',
		label: 'MyWard',
	},

	{
		href: '/parents/class_management',
		title: 'Class management',
		label: 'class',
	},

	{
		href: '/parents/profile-page',
		title: 'Profile',
		label: 'profile',
	},
	{
		href: '/parents/setting-page',
		title: 'Settings',
		label: 'setting',
	},
];

export const Activty = [
	{
		imageUrl: green,
		label: 'Student attendance',
		icon: arrowRight,
	},
	{
		imageUrl: blue,
		label: 'Lesson notes',
		icon: arrowRight,
	},
	{
		imageUrl: yellow,
		label: 'Teaching materials',
		icon: arrowRight,
	},
];
export const AllDetails = [
	{
		imageUrl: Admin,
		label: 'Admins',
		fig: 15,
	},
	{
		imageUrl: Teacher,
		label: 'Teachers',
		fig: 23,
	},
	{
		imageUrl: student,
		label: 'Students',
		fig: 196,
	},
	{
		imageUrl: parent,
		label: 'Parents',
		fig: 243,
	},
	{
		imageUrl: Staff,
		label: 'Non-teaching staff',
		fig: 25,
	},
];

export const EventActivity = [
	{
		imageUrl: eventImage,
		label: 'Mark class attendent',
		date: 'Thursday 23rd June 2023',
		line: '/line.svg',
		time: '11:23am - 11:30am',
		status: ongoing,
	},
	{
		imageUrl: eventImage,
		label: 'PTA meeting and open day ceremony',
		date: 'Thursday 23rd June 2023',
		line: '/line.svg',
		time: '11:23am - 11:30am',
		status: upcoming,
	},
	{
		imageUrl: eventImage,
		label: 'PTA meeting and open day ceremony',
		date: 'Thursday 23rd June 2023',
		line: '/line.svg',
		time: '11:23am - 11:30am',
		status: upcoming,
	},
];
export const TableData = [
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: {
			image: '/woman.png',
			name: 'Shulamite Offua',
		},
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
	{
		Student: [
			{
				image: '/woman.png',
				name: 'Shulamite Offua',
			},
		],
		admissionData: 'BHSS0001',
		gender: 'Female',
		date: 'Thur 23rd July, 2023.',
		end: '...',
	},
];

export const PersonalInfo = [
	{
		label: 'Full name',
		details: 'Shulamite ofua',
	},
	{
		label: 'Date of Birth',
		details: '23rd June, 2023',
	},
	{
		label: 'Subjects offered',
		details: 10,
	},
	{
		label: 'Gender',
		details: 'Female',
	},
	{
		label: 'Admission number',
		details: '087THEF',
	},
	{
		label: 'Address',
		details: '4A, Powerline Avenue, Lagos.',
	},
	{
		label: 'Current class',
		details: 'Js1',
	},
	{
		label: 'Date joined',
		details: 'Thurs 23rd july, 2023',
	},
];

export const medicalInfo = [
	{
		label: 'Blood group',
		details: 'o+',
	},
	{
		label: 'Genotype',
		details: 'AA',
	},
	{
		label: 'Disability',
		details: 'None',
	},
];

export const FirstParentInfo = [
	{
		label: 'Full name',
		details: 'Mocheal Opara',
	},
	{
		label: 'Phone number',
		details: 1989247819,
	},
	{
		label: 'Residentil Address',
		details: '4A, Powerline Avenue, Lagos.',
	},
	{
		label: 'Gender',
		details: 'Female',
	},
	{
		label: 'Email adress',
		details: 'parent@gmail.com',
	},
];

export const InputDetails = [
	{
		label: 'Full name',
	},
	{
		label: 'Gender',
	},
	{
		label: 'current class',
	},
	{
		label: 'Residential address',
	},
	{
		label: 'Date of birth',
	},
	{
		label: 'Admission number',
	},
	{
		label: 'Date joined',
	},
	{
		label: 'Parent/guardian number',
	},
];

export const medicalDetails = [
	{
		label: 'Blood group',
	},
	{
		label: 'Genotype',
	},
	{
		label: 'Disabilty',
	},
];
export const ParentDetails = [
	{
		label: 'Full name',
	},
	{
		label: 'Phone number',
	},
	{
		label: 'Gender',
	},

	{
		label: 'Residential address',
	},

	{
		label: 'Email address',
	},
];

export const EmptyInputDetails = [
	{
		label: 'Full name',
		placeholder: 'Enter full name',
	},
	{
		label: 'Gender',
		placeholder: 'Enter gender',
	},
	{
		label: 'current class',
		placeholder: 'Enter current class',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
	},
	{
		label: 'Date of birth',
		placeholder: 'Enter date of birth',
	},
	{
		label: 'Admission number',
		placeholder: 'Enter admission number',
	},
	{
		label: 'Date joined',
		placeholder: 'Enter date joined',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
	},
];

export const EmptyMedicalDetails = [
	{
		label: 'Blood group',
		placeholder: 'Enter blood group',
	},
	{
		label: 'Genotype',
		placeholder: 'Enter genotype',
	},
	{
		label: 'Disabilty',
		placeholder: 'Enter disability',
	},
];
export const EmptyParentDetails = [
	{
		label: 'Full name',
		placeholder: 'Enter full name',
	},
	{
		label: 'Phone number',
		placeholder: '0809670487',
	},
	{
		label: 'Gender',
		placeholder: 'Enter gender',
	},

	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
	},

	{
		label: 'Email address',
		placeholder: 'Enter email address',
	},
];

export const EventDetails = [
	{
		imageUrl: EVENTIMG,
		event: 'Interhouse sports competition',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Parent Teachers Meeting',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Cultural day 2023!',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Lagos state quiz comoetition',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Lagos state quiz comoetition',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Lagos state quiz comoetition',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
	{
		imageUrl: EVENTIMG,
		event: 'Lagos state quiz comoetition',
		time: '11:23am',
		date: '24th Mar, 2022.',
	},
];

export const Schedule = [
	{
		imageUrl: avatar,
		subJects: 'Mathematics',
		time: '8:00 - 9:00',
		bgColor: 'bg-[#F0FAF3]',
		class: 'jss1 class',
		line: Line,
		ongoing: 'done',
	},
	{
		imageUrl: avatar,
		subJects: 'Further Mathematics',
		time: '9:00 - 10:00',
		// bgColor: "bg-[#F5FAF7]",
		class: 'ss2 class',
		icon: Dot,
		line: Line,
		ongoing: 'undone',
	},
	{
		imageUrl: avatar,
		subJects: 'Break time',
		// bgColor: "bg-[#EBEBEB]",
		line: Line,
		ongoing: 'break',
	},
	{
		imageUrl: avatar,
		subJects: 'Further Mathematics',
		time: '9:00 - 10:00',
		// bgColor: "bg-[#F5FAF7]",
		class: 'ss2 class',
		icon: Dot,
		line: Line,
		ongoing: 'undone',
	},
	{
		imageUrl: avatar,
		subJects: 'Further Mathematics',
		time: '9:00 - 10:00',
		// bgColor: "bg-[#F5FAF7]",
		class: 'ss2 class',
		icon: Dot,
		line: Line,
		ongoing: 'undone',
	},
	{
		imageUrl: avatar,
		subJects: 'Further Mathematics',
		time: '9:00 - 10:00',
		// bgColor: "bg-[#F5FAF7]",
		class: 'ss2 class',
		icon: Dot,
		line: Line,
		ongoing: 'undone',
	},
	{
		imageUrl: avatar,
		subJects: 'Further Mathematics',
		time: '9:00 - 10:00',
		// bgColor: "bg-[#F5FAF7]",
		class: 'ss2 class',
		icon: Dot,
		line: Line,
		ongoing: 'undone',
	},
];

export const newTaskdetails = [
	{
		label: 'Task title',
		placeholder: 'Enter title',
	},
	{
		label: 'Description',
		placeholder: 'Enter description',
	},
	{
		label: 'Date',
		placeholder: 'Enter date',
	},
	{
		label: 'Time',
		placeholder: 'Enter time',
	},
];

export const subjectData = [
	{
		Subject: 'English Language',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
	{
		Subject: 'Home Economics',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
	{
		Subject: 'Computer Science',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
	{
		Subject: 'Literature-in-english',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
	{
		Subject: 'Home Economics',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
	{
		Subject: 'Agric Science',
		First_Test: 0,
		Second_Test: 0,
		Exam: 0,
		Total: 0,
	},
];

export const personalDetails = [
	{ name: 'firstName', label: 'First name', type: 'name' },
	{ name: 'lastName', label: 'Last name', type: 'name' },
	{ name: 'dob', label: 'Date of birth', type: 'text' },
	{ name: 'email', label: 'Email address', type: 'email' },
	{ name: 'phone', label: 'Parent phone number', type: 'text' },
	{ name: 'parentEmail', label: 'Parent email address', type: 'email' },
];

export const addTeacherInfo = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
		component: Input,
	},
	{
		label: 'Date of birth',
		placeholder: 'Enter date of birth',
		type: 'name',
		component: Input,
	},
	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
		component: Select,
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
		component: Input,
	},
	{
		label: 'Current class',
		placeholder: 'Enter current class',
		type: 'text',
		component: Input,
	},
	{
		label: 'Date joined',
		placeholder: 'Enter date joined',
		type: 'text',
		component: Select,
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
		component: Input,
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
		component: Input,
	},
	{
		label: 'Bank',
		placeholder: 'Select bank',
		type: 'select',
		component: Select,
	},
	{
		label: 'Account number',
		placeholder: 'Enter account number',
		type: 'text',
		component: Input,
	},
];

export const addMedicalInfo = [
	{
		label: 'Blood group',
		placeholder: 'Enter blood group',
	},
	{
		label: 'Genotype',
		placeholder: 'Enter genotype',
	},
	{
		label: 'Disability',
		placeholder: 'Enter disability',
	},
];

export const addNextofKin = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'text',
	},
	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Relationship',
		placeholder: 'Select relationship',
		type: 'select',
	},
	{
		label: 'Email address',
		placeholder: 'Enter Email address',
		type: 'email',
	},
];
export const loginInfo = [
	{
		label: 'Staff ID',
		placeholder: 'Enter staff ID',
		type: 'text',
	},
	{
		label: 'Password',
		placeholder: 'Enter password',
		type: 'Password',
	},
];

export const studentInfo = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},
	{
		label: 'Date of birth',
		placeholder: 'Enter date of birth',
		type: 'name',
	},
	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
	},
	{
		label: 'Admission number',
		placeholder: 'Enter admission number',
		type: 'text',
	},
	{
		label: 'Current class',
		placeholder: 'Enter current class',
		type: 'text',
	},
	{
		label: 'Date joined',
		placeholder: 'Enter date joined',
		type: 'text',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
];
export const studentMedicalInfo = [
	{
		label: 'Blood group',
		placeholder: 'Enter blood group',
	},
	{
		label: 'Genotype',
		placeholder: 'Enter genotype',
	},
	{
		label: 'Disability',
		placeholder: 'Enter disability',
	},
];
export const parentInfo1 = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},

	{
		label: 'Gender',
		placeholder: 'Enter parent gender',
		type: 'text',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
];
export const parentInfo2 = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},

	{
		label: 'Gender',
		placeholder: 'Enter parent gender',
		type: 'text',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
];
export const studentloginInfo = [
	{
		label: 'Staff ID',
		placeholder: 'Enter staff ID',
		type: 'text',
	},
	{
		label: 'Password',
		placeholder: 'Enter password',
		type: 'Password',
	},
];

export const AdminInfo = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},

	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
	{
		label: 'Date joined',
		placeholder: 'Enter date joined',
		type: 'text',
	},
	{
		label: 'Bank',
		placeholder: 'Select bank',
		type: 'select',
	},
	{
		label: 'Account number',
		placeholder: 'Enter account number',
		type: 'text',
	},
];
export const StaffInfo = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},

	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
	{
		label: 'Date joined',
		placeholder: 'Enter date joined',
		type: 'text',
	},
	{
		label: 'Bank',
		placeholder: 'Select bank',
		type: 'select',
	},
	{
		label: 'Account number',
		placeholder: 'Enter account number',
		type: 'text',
	},
	{
		label: 'Role',
		placeholder: 'Select role',
		type: 'select',
	},
];
export const ParentInfo = [
	{
		label: 'Full name',
		placeholder: 'Enter name',
		type: 'name',
	},

	{
		label: 'Gender',
		placeholder: 'Select gender',
		type: 'select',
	},
	{
		label: 'Phone number',
		placeholder: 'Enter phone number',
		type: 'text',
	},
	{
		label: 'Email address',
		placeholder: 'Enter email address',
		type: 'email',
	},
	{
		label: 'Residential address',
		placeholder: 'Enter residential address',
		type: 'text',
	},
];

export const UsersData = [
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Adult',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Staff',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Teacher',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Admin',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
	{
		Student: [
			{
				image: babe,
				name: 'Shulamite Offua',
			},
		],
		email: 'nobodyknowsyou@gmail.com',
		role: 'Student',
		date: 'Thur 23rd July, 2023.',
		status: 'active',
		end: '...',
	},
];

export const optionsData = [
	{ id: '1', label: 'Jss 1' },
	{ id: '2', label: 'Jss 2' },
	{ id: '3', label: 'Jss 3' },
	{ id: '4', label: 'SS 1' },
	{ id: '5', label: 'SS 2' },
	{ id: '6', label: 'Ss 3' },
];

export const data = [
	{
		id: '27',
		uuid: 'dc959791-4529-4e3e-a466-3b08d6c46e4d',
		first_name: 'Claire',
		last_name: 'Robel',
		full_name: 'Claire Robel',
		gender: 'male',
		phone_number: '(820) 827-8295',
		email: 'sipes.ahmad@example.com',
		unique_number: 'BHSS-ST-63551153',
		profile_image: null,
		blood_group: 'O+',
		genotype: 'AB',
		dob: '2011-03-21',
		role: 'STUDENT',
		date_joined: '2011-06-21',
		class: 'JSS 2',
		address: 'Lagos, Nigeria',
		subjects: '8',
		disability: 'No',
		parent1: 'Joseph Opara',
		parent2: 'Brokey josephine',
		number: 19787645452,
		parent_address: '4A, Powerline Avenue, Lagos.',
		parent_gender_1: 'Female',
		parent_gender_2: 'Male',
		parent_email_1: 'parentMe@gmail.com',
		parent_email_2: 'parent2Me@gmail.com',
	},

	{
		id: '28',
		uuid: 'dc959791-4529-4e3e-a466-3b08d6c46e4d',
		first_name: 'Claire',
		last_name: 'Robel',
		full_name: 'Claire Robel',
		gender: 'male',
		phone_number: '(820) 827-8295',
		email: 'sipes.ahmad@example.com',
		unique_number: 'BHSS-ST-63551153',
		profile_image: null,
		blood_group: 'O+',
		genotype: 'AB',
		dob: '2011-03-21',
		role: 'STUDENT',
		date_joined: '2011-06-21',
		class: 'JSS 3',
		address: 'Lagos, Nigeria',
		subjects: '10',
		disability: 'No',
		parent1: 'Mocheal Opara',
		parent2: 'Mikel josephine',
		number: 1989247819,
		parent_address: '4A, Powerline Avenue, Lagos.',
		parent_gender_1: 'Female',
		parent_gender_2: 'Male',
		parent_email_1: 'parent@gmail.com',
		parent_email_2: 'parent2@gmail.com',
	},
];

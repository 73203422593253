import {
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import React from "react";

interface TimePickerTypes {
  value: any;
  onChange: (e: any) => void;
  label: string;
}
function TimePickerComp(props: TimePickerTypes) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoItem label={props.label}>
        <TimePicker
          views={["hours", "minutes"]}
          format='hh:mm'
          sx={{ height: "100%" }}
          value={props.value}
          onChange={props.onChange}
        />
      </DemoItem>
    </LocalizationProvider>
  );
}

export default TimePickerComp;

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './App';
import { Store, persistor } from './states/store';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './config';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<PersistGate loading={null} persistor={persistor}>
		<Provider store={Store}>
			<QueryClientProvider client={queryClient}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</LocalizationProvider>
			</QueryClientProvider>
		</Provider>
	</PersistGate>
);

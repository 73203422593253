import { selectDarkmode } from "@/states/slices/authReducer";
import React from "react";
import { useSelector } from "react-redux";

interface headerTypes {
  title: string;
  subtitle: string;
}
function DashboardHeader(props: headerTypes) {
  const darkMode = useSelector(selectDarkmode);
  return (
    <div className="flex flex-col lg:mb-6 mb-3">
      <span
        className={`${
          !darkMode ? "bg-white text-gray-600" : "text-gray-300"
        } text-black text-xl font-bold leading-8`}
      >
        {props.title}
      </span>
      <p
        className={` ${
          darkMode ? "  text-[#F8F8FA]/60" : "text-gray-400"
        } font-normal text-sm`}
      >
        {props.subtitle}
      </p>
    </div>
  );
}

export default DashboardHeader;

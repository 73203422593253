import { useAppDispatch, useAppSelector } from "@/hooks";
import {
  reset,
  selectDarkmode,
  selectRole,
  selectUser,
} from "@/states/slices/authReducer";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowDown,
  CHEVRON_DOWN,
  USER_ICON,
  WHITE_ARROW_DOWN,
  logout,
} from "../../assets";
import { FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
// import { useAuth } from './AuthContext';

interface UserDropdownProps {
  isOpen: any;
  setIsOpen: any;
}

const UserDropdown: React.FC<UserDropdownProps> = ({
  isOpen,
  setIsOpen,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const ROLE = useAppSelector(selectRole);

  const logout_routes =
    ROLE === "ADMIN"
      ? "admin-login"
      : ROLE === "PARENT"
      ? "parent-login"
      : ROLE === "STUDENT"
      ? "student-login"
      : ROLE === "TEACHER"
      ? "teacher-login"
      : null;

  const handleLogout = () => {
    dispatch(reset());
    navigate(`/${logout_routes}`);
    setIsOpen(false);
  };

  const routes =
    ROLE === "ADMIN"
      ? "admin"
      : ROLE === "PARENT"
      ? "parent"
      : ROLE === "STUDENT"
      ? "student"
      : ROLE === "TEACHER"
      ? "teacher"
      : null;

  const darkMode = useSelector(selectDarkmode);

  return (
    <div className="relative">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsOpen((prev: any) => !prev)}
      >
        <img
          src={user?.profile_image ? user?.profile_image : USER_ICON}
          alt="user"
          className="w-12 h-12 rounded-full"
        />
        {darkMode ? (
          <img
            src={WHITE_ARROW_DOWN}
            alt="arrow_down"
            className="pt-1 pl-1 w-3"
          />
        ) : (
          <img src={ArrowDown} alt="arrow_down" className="pt-1" />
        )}
      </div>
      {isOpen && (
        <div
          className={`absolute top-10 right-0  border rounded-md shadow-md w-[10rem] px-2 py-2 z-50 ${
            darkMode ? "bg-black border-none" : "bg-white"
          }`}
        >
          {/* Profile item */}
          <div
            className={`py-1 px-2 cursor-pointer   ${
              darkMode ? "text-gray-300" : "text-black"
            } flex items-center gap-3 rounded-lg hover:text-white hover:bg-brand ${
              location.pathname === `/${routes}/profile` ? " text-brand" : ""
            }`}
            onClick={() => {
              navigate(`/${routes}/profile`);
              setIsOpen(false);
            }}
          >
            {/* <img src={USER_ICON} alt="profile" className="w-8 h-8 mr-2" /> */}
            <FaUserAlt />
            <span>Profile</span>
          </div>
          <hr className={`my-[1.5px] ${darkMode ? "border-gray-700" : ""}`} />
          {/* Logout item */}
          <div
            className={`py-1 px-2 cursor-pointer hover:bg-brand  ${
              darkMode ? "text-gray-300" : "text-black"
            } flex items-center rounded-lg gap-3 hover:text-white`}
            onClick={handleLogout}
            style={{ filter: "invert(0)" }}
          >
            {/* <img src={logout} alt="logout" className="w-5 h-5 mr-2" /> */}
            <FaSignOutAlt />
            <span>Log out</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;

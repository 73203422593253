import React, { useEffect, useState } from 'react';
import { TableComponent } from '@/components/table/Table';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import { useMutation } from 'react-query';
import { deleteEvents } from '@/services/Admin/dashboard';
import toast from 'react-hot-toast';
import moment from 'moment';
import EmptyStudentData from '@/pages/admin/Students/contents/emptyStudentData';
import { Skeleton } from '@mui/material';
// import { getWeekDates } from "../utils";

interface Props {
	text: string;
	color: string;
	isActive: boolean;
}

const TeacherManageMent = ({ events, refetch }: any) => {
	const [page, setPage] = useState(1);

	const [confirmationModal, setConfirmationModal] = useState(false);
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const deleteEventMutation = useMutation(deleteEvents);

	const EVENT_COLUMNS = [
		{
			Header: 'EVENT NAME',
			accessor: 'title',
		},

		{
			Header: 'DESCRIPTION',
			accessor: 'description',
		},

		{
			Header: 'LOCATION',
			accessor: 'location',
		},

		{
			Header: 'EVENT DATE',
			accessor: 'start_date',
			Cell: ({ cell: { row } }: any) => {
				return moment(row.values.start_date).format('MMM Do YYYY');
			},
		},
	];

	useEffect(() => {
		refetch();
	}, []);

	const handleDelete = async (id: any) => {
		setLoading(true);
		try {
			const res: any = await deleteEventMutation?.mutateAsync(id);
			if (res) {
				refetch();
				setLoading(false);
				toast.success(res?.message);
				setConfirmationModal(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<section className='mt-6'>
			<span className='font-[500] text-lg'>My Schedule</span>
			<div className='flex flex-1 justify-between items-center mt-3 relative'></div>

			<div className='mt-7'>
				{events?.length > 0 ? (
					<TableComponent
						className='rounded-none rounded-t-0'
						DATA={events}
						COLUMNS={EVENT_COLUMNS}
						page={page}
						setPage={setPage}
						isNotPaginanated={true}
					/>
				) : events?.length < 1 ? (
					<p className='text-center text-xs'>
						<EmptyStudentData />
					</p>
				) : (
					<div className='w-full mb-3 px-8 flex justify-between items-center'>
						<Skeleton variant='rectangular' height={50} className='w-[50px] rounded-full mb-4' />
						<div className='w-[80%]'>
							<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
							<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
						</div>
					</div>
				)}
			</div>

			<ConfirmationModal
				loading={loading}
				handleClick={() => handleDelete(data?.id)}
				isOpen={confirmationModal}
				setIsOpen={setConfirmationModal}
				title={
					'You are about to delete this event. Click cancel to cancel this action or proceed with continue'
				}
			/>
		</section>
	);
};

export default TeacherManageMent;

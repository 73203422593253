import { DashboardLayout } from "../layouts/dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectRole, selectUser } from "../states/slices/authReducer";
import { studentsRoutes } from "./data/studentsRoutes";
import { teachersRoutes } from "./data/teachersRoute";
import { AdminRoutes } from "./data/adminRoutes";
import { ParentRoutes } from "./data/parentsRoutes";

function MainRoutes() {
  const role = useAppSelector(selectRole);
  return (
    <div>
      <DashboardLayout>
        <Routes>
          {role === "STUDENT" && (
            <>
              {studentsRoutes.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<item.component />}
                />
              ))}
              <Route
                path="*"
                element={<Navigate to="/student/home" replace />} //this is a way to redirect
              />
            </>
          )}
          {role === "TEACHER" && (
            <>
              {teachersRoutes.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<item.component />}
                />
              ))}

              <Route
                path="*"
                element={<Navigate to="/teacher" replace />} //this is a way to redirect
              />
            </>
          )}
          {role === "PARENT" && (
            <>
              {ParentRoutes.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<item.component />}
                />
              ))}

              <Route
                path="*"
                element={<Navigate to="/parent/myward/home" replace />} //this is a way to redirect
              />
            </>
          )}
          {role === "ADMIN" && (
            <>
              {AdminRoutes.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<item.component />}
                />
              ))}

              <Route
                path="*"
                element={<Navigate to="/admin/dashboard" replace />} //this is a way to redirect
              />
            </>
          )}
        </Routes>
      </DashboardLayout>
    </div>
  );
}

export default MainRoutes;

import React, { useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { add } from "../../../assets";
import { Link, useNavigate } from "react-router-dom";
import UserData from "./content/pages/UserData";
import { TableComponent } from "../../../components/table/Table";
import toast from "react-hot-toast";
import DropDownV2 from "../../../components/menu-dropdown/dropDownV2";
import { useMutation, useQuery } from "react-query";
import { deleteTeacher, getUsers } from "../../../services/Admin/dashboard";
import TeacherEmptyData from "../Teachers/contents/teachersEmptyData";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { Skeleton } from "@mui/material";
import EmptyUserData from "./content/pages/EmptyuserData";

interface UserRolesProps {
  label: string;
  content: JSX.Element;
  href: string;
  type: string;
}

const usersData = true;
const userRoles: UserRolesProps[] = [
  {
    label: "Users",
    content: <div>{usersData ? <UserData /> : <EmptyUserData />}</div>,
    href: "/admin/users-and-roles/add_users",
    type: "User",
  },
  // I commented this out cause its not needed
  // {
  //   label: "Roles",
  //   content: <div>{rolesData ? <ActiveRoles /> : <EmptyRoles />}</div>,
  //   href: "/admin/users-and-roles/add_roles",
  //   type: "Role",
  // },
];
function UserRoles() {
  const [selected, setSelected] = useState<number | null>(0);

  const handleSelected = (index: number) => {
    setSelected(index);
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const deleteTeacherMutation = useMutation(deleteTeacher);
  const params = {
    page,
    role: "",
  };
  const { data: userData, refetch } = useQuery(["users", params], () =>
    getUsers(params)
  );

  const USER_COLUMNS = [
    {
      Header: "FULL NAME",

      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original.first_name} {row.original.last_name}
          </span>
        </div>
      ),
    },

    {
      Header: "ROLE",
      accessor: "role",
    },

    {
      Header: "ADDRESS",
      accessor: "address",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "UNIQUE NUMBER",
      accessor: "unique_number",
    },
    {
      Header: "ACTION",
      accessor: "",
      Cell: ({ row }: any) => {
        return (
          <div className="relative">
            <DropDownV2
              handleDelete={() => {
                setConfirmationModal(true);
                setData(row?.original);
              }}
              handleEdit={() => {
                if (row.original.role === "TEACHER") {
                  navigate("/admin/teachers/edit-teachers", {
                    state: { data: row?.original },
                  });
                } else if (row.original.role === "STUDENT") {
                  navigate("/admin/students/edit-students", {
                    state: { data: row?.original },
                  });
                } else if (row.original.role === "ADMIN") {
                  navigate("/admin/profile/edit-profile", {
                    state: { data: row?.original },
                  });
                } else if (row.original.role === "PARENT") {
                  navigate("/admin/users-and-roles/edit-parent", {
                    state: { data: row?.original },
                  });
                }
              }}
              editText="user"
              viewText="user"
            />
          </div>
        );
      },
    },
  ];

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      const res: any = await deleteTeacherMutation?.mutateAsync(id);
      if (res) {
        refetch();
        setLoading(false);
        toast.success(res?.message);
        setConfirmationModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <main className="mt-6 lg:px-8 px-1">
      <DashboardHeader
        title="Users & Roles"
        subtitle="See and manage users and roles here."
      />
      <div className="mt-8 flex justify-between flex-wrap">
        <div className="flex lg:gap-6 xs:gap-0 lg:flex-row xs:flex-col">
          {userRoles.map((item, index) => (
            <span
              className={`${
                index === selected
                  ? "text-blue-500 border-b-2 border-blue-500 p-3"
                  : "text-gray-500 p-3"
              } cursor-pointer`}
              onClick={() => handleSelected(index)}
            >
              {item.label}
            </span>
          ))}
        </div>
        <div className="flex lg:gap-7 xs:gap-3 lg:flex-row xs:flex-col flex-wrap">
          <Link to={selected !== null ? userRoles[selected].href : ""}>
            <button
              type="button"
              className="flex bg-other w-fit h-fit px-5 py-3 gap-3 rounded-lg text-white"
            >
              <img
                src={add}
                alt=""
                width={20}
                height={20}
                className="pt-[0.1rem]"
              />
              <span>
                {`Add new ${
                  selected !== null
                    ? userRoles[selected]?.type?.toLowerCase()
                    : "?"
                }`}
              </span>
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-8">
        {/* {selected !== null ? userRoles[selected].content : null} */}

        {userData?.data?.length > 0 ? (
          <TableComponent
            className="rounded-none rounded-t-0"
            DATA={userData?.data}
            COLUMNS={USER_COLUMNS}
            page={page}
            setPage={setPage}
          />
        ) : userData?.data?.length < 1 ? (
          <p className="text-center text-xs">
            <TeacherEmptyData />
            {page > 1 && (
              <div className="flex justify-end items-center w-full px-2 lg:px-4 py-2">
                <div className="flex justify-center items-center text-xs rounded-sm py-1  w-full ">
                  {page > 1 && (
                    <button
                      className=" cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand"
                      onClick={() => handlePrevious()}
                      // disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                  )}

                  <span className="mx-4 text-xs">
                    Page <strong>{page}</strong>{" "}
                  </span>
                  {data?.length > 14 && (
                    <button
                      className="cursor-pointer px-4 py-2 w-40 border-2 border-brand rounded-lg text-brand"
                      onClick={() => handleNext()}
                      // disabled={!canNextPage}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}
          </p>
        ) : (
          <div className="w-full mb-3 px-8 flex justify-between items-center">
            <Skeleton
              variant="rectangular"
              height={50}
              className="w-[50px] rounded-full mb-4"
            />
            <div className="w-[80%]">
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-full mb-4"
              />
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-[80%] mb-4"
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        loading={loading}
        handleClick={() => handleDelete(data?.uuid)}
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        title={
          "You are about to delete this user information. click cancel to cancel this action or proceed with continue"
        }
      />
    </main>
  );
}

export default UserRoles;

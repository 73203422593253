import AddAdminForm from '@/components/form/authForms/AddAdmin';
import EditProfleForm from '@/components/form/authForms/EditProfile';
import DashboardHeader from '@/components/headers/dashboard-header';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/states/slices/authReducer';

function EditProfile() {
	const profileData = useAppSelector(selectUser);

	return (
		<div className='mt-6 lg:px-8 px-1'>
			<DashboardHeader
				title={'Edit Profile 🧑'}
				subtitle='Edit and save your profile information here'
			/>
			<div className='mt-10 w-full px-10 border-[1px] shadow-sm rounded-lg py-10'>
				<div>
					<span>Personal info</span>

					<EditProfleForm data={profileData} />
				</div>
			</div>
		</div>
	);
}

export default EditProfile;

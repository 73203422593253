import Modal from "../../../../components/Modal";
import Button from "../../../../components/button";
import Typography from "../../../../components/Typography";
import { success } from "../../../../assets";

interface Props {
  isOpen: () => void;
  isClose: () => void;
}
const AddTaskModal = ({ isOpen, isClose }: Props) => {
  return (
    <Modal open={isOpen} close={isClose} maxWidth="xs">
      <div className="px-8">
        <div className="flex flex-col justify-center items-center pt-5 px-9 gap-3">
          <img src={success} width={80} height={80} alt="action bad" />
          <span className="text-slate-950 font-[550] text-center text-xl">
            Action Successful
          </span>
        </div>
        <Typography textStyle="flex flex-col gap-2">
          <span className="text-center mt-2">
            New task has been successfully added to your schedule list
          </span>
        </Typography>

        <div className="py-3 px-7 mt-8 mb-7">
          <Button
            iconClassName="h-[0.9rem] w-3"
            // isLoading={loading}
            title={"close"}
            onClick={isClose}
            bgColor={"bg-other"}
            hoverColor={""}
            textColor={"text-white"}
            className={"py-4 rounded-lg bg-brand w-full"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddTaskModal;

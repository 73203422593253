/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { avatar } from "@/assets";
import moment from "moment";
import Button from "@/components/button";
import Modal from "@/components/Modal";
import TeacherManageMent from "./teacherManagement";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const TeacherContent = (props?: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const darkMode = useSelector(selectDarkmode);
  return (
    <section>
      <div className="w-full flex justify-between items-start flex-wrap mb-4">
        <div className="w-full lg:w-[60%] mb-4">
          {/* <EventList setIsOpen={setIsOpen} data={props?.data?.event} /> */}

          <div
            className={`${
              darkMode ? " bg-dark-200" : "shadow-box"
            } rounded-xl w-full h-fit flex flex-col pb-6  px-4`}
          >
            <div className="flex justify-between p-5">
              <span
                className={`text-sm font-bold ${
                  darkMode ? "text-white" : "text-black"
                }`}
              >
                Events list
              </span>

              <div className="relative">
                <a
                  onClick={() => navigate("/teacher/class-management")}
                  className="text-brand font-semibold text-sm cursor-pointer"
                >
                  See all
                </a>
              </div>
            </div>

            {props?.data?.events?.length > 0 ? (
              props?.data?.events?.map((event: any, i: number) => (
                <div
                  key={i}
                  className={`px-4 lg:px-6 w-full  flex justify-between items-center mb-4 rounded-lg  ${
                    darkMode ? "bg-dark-200" : "bg-gray-50"
                  }`}
                >
                  <div className="w-[75%] flex flex-col rounded-xl py-2">
                    <div className="flex justify-start">
                      <div>
                        <img
                          src={avatar}
                          alt="profile image"
                          className="rounded-lg w-12 h-12 "
                        />
                      </div>
                      <div className="flex flex-col ml-2 ">
                        <p
                          className={`text-xs ${
                            darkMode ? "text-gray-200" : "text-black"
                          } font-fold`}
                        >
                          {event.title}
                        </p>
                        <div className="mt-2 flex justify-start">
                          <p className=" text-[#A2A2A2]/80 text-xs ">
                            {moment(event?.start_date).format(
                              "ddd Do MMM, YYYY"
                            )}
                          </p>
                          <span className="text-[#A2A2A2]/80 text-xs ml-4">
                            |
                          </span>
                          <p className="text-[#A2A2A2]/80 text-xs ml-4">
                            {moment(event?.start_date).format("hh:mma")} -{" "}
                            {moment(event?.end_date).format("hh:mma")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className={`capitalize text-xs px-4 py-2 rounded-xl  ${
                      event.status === "PENDING"
                        ? "bg-[#FF9B251A] text-[#FF9B25] hover:bg-orange-300 hover:text-white"
                        : event.status === "ONGOING"
                        ? "text-[#16A546] bg-[#16A5461A] hover:bg-green-200 hover:text-green-700"
                        : "bg-red-200 text-red-600 hover:bg-red-200 hover:text-red-700"
                    }`}
                  >
                    {event?.status?.toLowerCase()}
                  </a>
                </div>
              ))
            ) : (
              <div
                className={` h-60 px-4 lg:px-6 w-full  flex justify-between items-center mb-4   ${
                  darkMode ? "bg-gray-800" : "bg-gray-50"
                } `}
              >
                <p className=" text-center text-sm">
                  No event available at the moment
                </p>
              </div>
            )}

            {/* active */}
          </div>
        </div>

        <div
          className={`w-full lg:w-[38%] ${
            darkMode ? "bg-dark-200" : "shadow-box"
          } mb-4`}
        >
          <div className="flex justify-between p-5">
            <span
              className={`text-sm font-bold ${
                darkMode ? "text-white" : "text-black"
              }`}
            >
              School calendar
            </span>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar />
          </LocalizationProvider>
        </div>
      </div>
      <TeacherManageMent events={props?.data?.events} refetch={props.refetch} />
      <Modal open={isOpen} close={() => setIsOpen(false)} maxWidth="xs">
        <div className="px-8">
          <div className="flex flex-col text-center mb-8">
            <span className="text-slate-950 font-bold text-2xl">
              Event Details
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event title:</p>
              <p className="font-semibold text-sm">
                {" "}
                Interhouse sport competition
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event date:</p>
              <p className="font-semibold text-sm">24th march, 2023 </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Location:</p>
              <p className="font-semibold text-sm"> School stadium</p>
            </div>
          </div>

          <div className="py-8 ">
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-brand"}
              onClick={() => {
                setIsOpen(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full"}
            />
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default TeacherContent;

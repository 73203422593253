import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { ArrowDown, WHITE_ARROW_DOWN, exports, print } from "../../../assets";
import CourseModal from "./modal/CourseModal";
import { getCourseReportAPI, getSubject } from "@/services/Teacher/dashboard";
import { TableComponent } from "@/components/table/Table";
import EmptyStudentData from "@/pages/admin/Students/contents/emptyStudentData";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import Typography from "@/components/Typography";
import Modal from "@/components/Modal";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

function CourseReport() {
  const [editing, setEditing] = useState(false);

  const [courseModal, setCourseModal] = useState(false);
  const [termModal, setTermModal] = useState(false);
  const [term, setTerm] = useState("FIRST TERM");

  const { data: subjectData } = useQuery("subjectData", getSubject);

  const [selectedCourse, setSelectedCourse] = useState<any>(
    subjectData?.data[0]
  );
  const [selected, setSelected] = useState<any>(selectedCourse?.classes[0]);
  const params = {
    class_id: selected?.id,
    subject_id: selectedCourse?.id,
    term: term,
  };
  const { data: courseData, refetch } = useQuery(["courseData", params], () =>
    getCourseReportAPI(params)
  );
  const selectedValue = (value: any) => {
    setSelectedCourse(value);
    setCourseModal(false);
  };

  useEffect(() => {
    refetch();
  }, [term]);
  useEffect(() => {
    setSelectedCourse(subjectData?.data[0]);

    (async () => {
      if (await subjectData) {
        setSelected(subjectData?.data[0]?.classes[0]);
      }
    })();
  }, [subjectData]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);

  const EVENT_COLUMNS = [
    {
      Header: "STUDENT NAME",
      accessor: "first_name",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original?.first_name} {row.original?.last_name}
          </span>
        </div>
      ),
    },
    {
      Header: "FIRST TEST",
      accessor: "first_test_score",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.first_test_score}</span>
        </div>
      ),
    },
    {
      Header: "SECOND TEST",
      accessor: "second_test_score",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.second_test_score}</span>
        </div>
      ),
    },
    {
      Header: "THIRD TEST",
      accessor: "thirdTest",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.third_test_score}</span>
        </div>
      ),
    },
    {
      Header: "EXAMINATION",
      accessor: "exam",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.exam_score}</span>
        </div>
      ),
    },
    {
      Header: "TOTAL SCORE",
      accessor: "total",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.total_score}</span>
        </div>
      ),
    },
    {
      Header: "GRADE",
      accessor: "grade",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.grade}</span>
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/teacher/course-report/edit?", {
      state: {
        term,
        class: selectedCourse?.classes[0],
        subject: subjectData?.data[0],
      },
    });
  };
  const handleSelected = (i: any) => {
    setSelected(i);
  };
  const data = ["FIRST TERM", "SECOND TERM", "THIRD TERM"];

  const darkMode = useSelector(selectDarkmode);
  return (
    <section className="p-4 pb-24 lg:pt-8 lg:pb-8 ">
      <div className="lg:flex justify-between">
        <DashboardHeader
          title="Course Report"
          subtitle="See and manage course reports here"
        />

        <div className="flex justify-end items-center">
          <button
            type="button"
            className={`flex bg-[#F7F7F7] ${
              darkMode ? "bg-black text-gray-300" : "bg-[#F7F7F7]"
            } w-fit h-fit py-3 px-8 rounded-lg gap-3`}
            onClick={() => setTermModal(true)}
          >
            <span className="font-[500]">{`${
              !term ? " Select term" : term
            }`}</span>
            {darkMode ? (
              <img
                src={WHITE_ARROW_DOWN}
                alt="arrow_down"
                className="pt-2 w-3"
              />
            ) : (
              <img src={ArrowDown} alt="arrow_down" className="pt-1" />
            )}
          </button>
          <button
            type="button"
            className={`flex ${
              darkMode ? "bg-black text-gray-300" : "bg-[#F7F7F7]"
            } w-fit h-fit py-3 px-8 rounded-lg gap-3 ml-4`}
            onClick={() => setCourseModal(true)}
          >
            <span className="font-[500]">
              {`${!selectedCourse ? " Select course 1" : selectedCourse?.name}`}
            </span>
            {darkMode ? (
              <img
                src={WHITE_ARROW_DOWN}
                alt="arrow_down"
                className="pt-2 w-3"
              />
            ) : (
              <img src={ArrowDown} alt="arrow_down" className="pt-1" />
            )}
          </button>
        </div>
      </div>
      <div className="mt-3 mb-5 justify-between lg:flex">
        <div>
          {selectedCourse?.classes?.map((item: any, i: number) => (
            <span
              key={i}
              className={`${
                item === selected
                  ? `${
                      !darkMode
                        ? "text-blue-500  border-blue-500"
                        : "text-red-500 border-red-500"
                    }  p-2 border-b-2`
                  : "text-gray-500 p-2"
              } inline-block transition-all duration-300 cursor-pointer`}
              onClick={() => handleSelected(item)}
            >
              {item?.name}
            </span>
          ))}
        </div>

        <div className="flex gap-5 lg:flex-row xs:flex-col">
          {/* <div className='flex gap-4'>
						<img src={print} alt='print' />
						<img src={exports} alt='export' />
					</div> */}
          <button
            type="button"
            className="w-fit h-fit bg-other px-5 py-3 rounded-lg text-white font-[500]"
            onClick={() => {
              setEditing((prev) => !prev);
              handleClick();
            }}
          >
            Edit report
          </button>
        </div>
      </div>

      {subjectData?.data?.length ? (
        <div
          className={`mt-10 w-full px-4 lg:px-10 border-[1px] ${
            darkMode ? "border-gray-700" : ""
          } shadow-sm rounded-lg py-6 lg:py-10 overflow-x-auto`}
        >
          {courseData?.data?.length > 0 ? (
            <TableComponent
              className="rounded-none rounded-t-0"
              DATA={courseData?.data}
              COLUMNS={EVENT_COLUMNS}
              page={page}
              setPage={setPage}
            />
          ) : courseData?.data?.length < 1 ? (
            <div className="text-center text-xs">
              <EmptyStudentData noStudentRegister />
            </div>
          ) : (
            <div className="w-full mb-3 px-8 flex justify-between items-center">
              <Skeleton
                variant="rectangular"
                height={50}
                className="w-[50px] rounded-full mb-4"
              />
              <div className="w-[80%]">
                <Skeleton
                  variant="rectangular"
                  height={20}
                  className="w-full mb-4"
                />
                <Skeleton
                  variant="rectangular"
                  height={20}
                  className="w-[80%] mb-4"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyStudentData
          textMessage="There's are no subjects available. Kindly request Admin to add subject"
          noStudentRegister
        />
      )}

      <CourseModal
        isOpen={courseModal}
        isClose={() => setCourseModal(false)}
        data={subjectData}
        onSelect={selectedValue}
      />
      <Modal open={termModal} close={() => setTermModal(false)} maxWidth="xs">
        <div className="px-7 pb-8">
          <Typography
            textStyle={`text-center font-semibold text-lg ${
              darkMode ? "text-other" : ""
            }`}
          >
            Select Term
          </Typography>

          <div className="flex flex-col gap-8 mt-7 overflow-y-scroll">
            {data?.map((item: any) => (
              <span
                key={item}
                className={`cursor-pointer ${
                  darkMode
                    ? "hover:bg-other hover:text-white text-gray-300"
                    : "hover:bg-gray-300 hover:text-white"
                } p-4 rounded-xl `}
                onClick={() => {
                  setTerm(item);
                  setTermModal(false);
                }}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default CourseReport;

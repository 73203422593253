import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useField } from "formik";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const Input = ({ ...props }: any) => {
  const [show, setShow] = useState(false);
  const [field, meta] = useField(props);

  const darkMode = useSelector(selectDarkmode);
  return (
    <>
      {props.isPassword === true ? (
        <div className=" mb-2 lg:mb-6 relative">
          <input
            className={` appearance-none border rounded-lg w-full py-3 px-3 ${
              darkMode ? "text-white" : "text-gray-700"
            } mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${
              props.className
            }`}
            {...field}
            type={show === true ? "text" : "password"}
            placeholder={props.placeholder}
            name={props.name}
          />
          {show === true ? (
            <FaEye
              className="absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl"
              onClick={() => setShow(!show)}
            />
          ) : (
            <FaEyeSlash
              className="absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl"
              onClick={() => setShow(!show)}
            />
          )}
          {meta.touched && meta.error && (
            <span className="error ml-4 text-[12px] text-red-500">
              {meta.error}
            </span>
          )}
        </div>
      ) : (
        <div className=" mb-2 lg:mb-6 relative">
          <input
            className={` appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`}
            {...field}
            type={props.type}
            placeholder={props.placeholder}
            name={props.name}
          />
          {meta.touched && meta.error && (
            <span className="error ml-4 text-[12px] text-red-500">
              {meta.error}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default Input;

import DashboardHeader from "@/components/headers/dashboard-header";
import { leftArrow } from "@/assets";

import { Link } from "react-router-dom";
import AddStudentForm from "@/components/form/authForms/AddStudent";

const AddStudents = () => {
  return (
    <main className='lg:px-8 xs:px-4 mt-7'>
      <div className='flex gap-3'>
        <Link to='/admin/students'>
          <img src={leftArrow} alt='arrow' />
        </Link>
        <DashboardHeader title='Add a students' subtitle='Add students here' />
      </div>
      <div className='mt-5'>
        <AddStudentForm />
      </div>
    </main>
  );
};

export default AddStudents;

import React, { useEffect, useState } from 'react';
import {
	getClass,
	getPromoted,
	getStudentByClassId,
	promoteStudent,
} from '@/services/Admin/dashboard';
import { Skeleton } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import EmptyStudentData from '../Students/contents/emptyStudentData';
import { TableComponent } from '@/components/table/Table';
import DashboardHeader from '@/components/headers/dashboard-header';
import { IC_CANCEL } from '@/assets';
import toast from 'react-hot-toast';
import Modal from '@/components/Modal';
import Button from '@/components/button';
import Select from '@/components/form/Select';

function ViewClass() {
	const state = useLocation().state;
	const [page, setPage] = useState(1);
	const [classValue, setClassValue] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(undefined);
	const { data: getStudents, refetch } = useQuery(['subject', state?.data?.id], () =>
		getStudentByClassId(state?.data?.id)
	);
	const promote = useMutation(promoteStudent);
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const { data: promoteData, refetch: promotedRefetch } = useQuery(
		['promote', state?.data?.id],
		() => getPromoted(state?.data?.id)
	);
	// Extracting the 'section' parameter from searchParams
	const section = searchParams.get('section');

	const { data: classData, refetch: classFetch } = useQuery(['class', section], () =>
		getClass(section)
	);

	useEffect(() => {
		refetch();
		promotedRefetch();
	}, [state.data.id]);

	useEffect(() => {
		classFetch();
	}, [section]);

	const handlePromote = async (data: any) => {
		const payload = {
			[`students[${data?.index}][student_id]`]: data?.original?.id,
			[`students[${data?.index}][new_class_id]`]: classValue,
		};
		setLoading(true);
		try {
			const res: any = await promote?.mutateAsync(payload);
			if (res) {
				refetch();
				setLoading(false);
				toast.success(res?.message);
				setClassValue('');
			}
		} catch (error: any) {
			setLoading(false);
			toast.error(error?.response?.data?.message);
			setClassValue('');
		}
	};

	const USER_COLUMNS = [
		{
			Header: 'FULL NAME',

			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>
						{row.original.first_name} {row.original.last_name}
					</span>
				</div>
			),
		},

		{
			Header: 'ROLE',
			accessor: 'role',
		},

		{
			Header: 'ADDRESS',
			accessor: 'address',
		},
		{
			Header: 'EMAIL',
			accessor: 'email',
		},
		{
			Header: 'UNIQUE NUMBER',
			accessor: 'unique_number',
		},
		{
			Header: 'ACTION',
			accessor: '',
			Cell: ({ row }: any) => {
				return (
					<div className='relative'>
						<button
							onClick={() => {
								setData(row);
								setIsOpen(true);
							}}
							type='button'
							className='w-fit h-fit px-3 py-2 bg-green-600 flex gap-3 rounded-lg hover:scale-90 transition-all'
						>
							<span className='text-white'>Promote</span>
						</button>
					</div>
				);
			},
		},
	];
	return (
		<main className='mt-6 lg:px-8 px-1'>
			<DashboardHeader title='Students' subtitle='Add and manage students here' />
			<div className='flex justify-between  mt-10 flex-wrap'>
				<span className='text-lg font-medium'>Student list</span>
				<div className='flex lg:gap-5 xs:gap-2 lg:flex-row xs:flex-col flex-wrap'></div>
			</div>

			<div className='mt-7'>
				{getStudents?.data?.length > 0 ? (
					<>
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getStudents?.data}
							COLUMNS={USER_COLUMNS}
							setPage={setPage}
							page={page}
							isNotPaginanated={true}
						/>
					</>
				) : getStudents?.data?.length < 1 ? (
					<div className='text-center text-xs'>
						<EmptyStudentData noStudentRegister />
					</div>
				) : (
					<div className='w-full mb-3 px-8 flex justify-between items-center'>
						<Skeleton variant='rectangular' height={50} className='w-[50px] rounded-full mb-4' />
						<div className='w-[80%]'>
							<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
							<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
						</div>
					</div>
				)}
			</div>

			<Modal open={isOpen} close={() => setIsOpen(false)} maxWidth='xs'>
				<div className=' flex justify-center flex-col items-center pb-8 px-8'>
					<div className='mb-8'>
						<label htmlFor='text' className='text-gray-600 text-sm'>
							Select New Class to be promoted to
						</label>
						<select
							className='appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200'
							value={classValue}
							onChange={(e: any) => setClassValue(e.target.value)}
						>
							<option value=''>Select a class</option>
							{classData?.data?.map((item: any, i: number) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</select>
					</div>

					<div className='flex justify-center  items-center'>
						<div className='w-32 ml-4'>
							<Button
								onClick={() => setIsOpen(false)}
								iconClassName='h-[0.9rem] w-3'
								title={'cancel '}
								bgColor={'bg-other'}
								hoverColor={''}
								textColor={'text-white'}
								className={' rounded-lg w-full  text-white !bg-black   '}
							/>
						</div>
						<div className='w-32 ml-4'>
							<Button
								isLoading={loading}
								onClick={() => {
									if (classValue) {
										handlePromote(data);
									} else toast.error('Select a class');
								}}
								iconClassName='h-[0.9rem] w-3'
								title={'Proceed '}
								bgColor={'bg-other'}
								hoverColor={''}
								textColor={'text-white'}
								className={' rounded-lg w-full  text-white !bg-black   '}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</main>
	);
}

export default ViewClass;

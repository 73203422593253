import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { toast } from 'react-hot-toast';

interface FormState {
	classSubject: any[];
}
const initialState: FormState = {
	classSubject: [],
};

const teacherSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		addClassSubject: (state, action: PayloadAction<any>) => {
			state.classSubject.push(action.payload);
		},
		removeClassSubject: (state, action: PayloadAction<any>) => {
			const filterDocItems = state?.classSubject?.filter(
				(item) => item?.title !== action.payload?.title
			);
			state.classSubject = filterDocItems;
			toast.error(`${action.payload?.title} is removed from the document upload!`);
		},
	},
});

export const { addClassSubject, removeClassSubject } = teacherSlice.actions;
export const getClassSubject = (state: RootState) => state.teacher.classSubject;

export const teacherReducer = teacherSlice.reducer;

import React, { useEffect, useState } from 'react';
import {
	getAllClass,
	getClass,
	getPromoted,
	getStudentByClassId,
	promoteStudent,
} from '@/services/Admin/dashboard';
import { Skeleton } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import EmptyStudentData from '../Students/contents/emptyStudentData';
import { TableComponent } from '@/components/table/Table';
import DashboardHeader from '@/components/headers/dashboard-header';
import { IC_CANCEL } from '@/assets';
import toast from 'react-hot-toast';
import Modal from '@/components/Modal';
import Button from '@/components/button';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';

const columns: GridColDef[] = [
	{ field: 'serial_no', headerName: 'serial number', width: 130 },
	{ field: 'full_name', headerName: 'Full name', width: 200 },
	{ field: 'role', headerName: 'Role', width: 90 },
	{
		field: 'unique_number',
		headerName: 'unique number',
		width: 150,
	},
	{
		field: 'email',
		headerName: 'Email',

		width: 300,
	},
];

function PromoteClass() {
	const state = useLocation().state;
	const [promoteModal, setPromoteModal] = useState(false);
	const [page, setPage] = useState(1);
	const [classValue, setClassValue] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(undefined);
	const { data: getStudents, refetch } = useQuery(['subject', state?.data?.id], () =>
		getStudentByClassId(state?.data?.id)
	);
	const [selectedRows, setSelectedRows] = useState([]);
	const promote = useMutation(promoteStudent);
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const { data: promoteData, refetch: promotedRefetch } = useQuery(
		['promote', state?.data?.id],
		() => getPromoted(state?.data?.id)
	);
	// Extracting the 'section' parameter from searchParams
	const section = searchParams.get('section');

	const { data: classData, refetch: classFetch } = useQuery('class', getAllClass);

	useEffect(() => {
		refetch();
		promotedRefetch();
	}, [state.data.id]);

	useEffect(() => {
		classFetch();
	}, [section]);

	const handleSubmit = async () => {
		const formData = new FormData();
		selectedRows?.forEach((item, i) => {
			formData.append(`students[${i}][student_id]`, item);
			formData.append(`students[${i}][new_class_id]`, classValue);
		});

		formData.append('class_id', state?.data?.id);

		setLoading(true);
		try {
			const res: any = await promote?.mutateAsync(formData);
			if (res) {
				refetch();
				setLoading(false);
				setPromoteModal(false);
				toast.success(res?.message);
				setClassValue('');
			}
		} catch (error: any) {
			setLoading(false);
			toast.error(error?.response?.data?.message);
			setClassValue('');
		}
	};

	const handleSelectionModelChange = (newSelection: any) => {
		setSelectedRows(newSelection);
	};

	const filteredStudent = getStudents?.data?.map((item: any, index: number) => ({
		full_name: `${item?.first_name} ${item?.last_name}`,
		role: item.role,
		address: item.address,
		email: item.email,
		unique_number: item.unique_number,
		serial_no: index + 1,
		id: item.id,
	}));

	return (
		<main className='mt-6 lg:px-8 px-1'>
			<DashboardHeader title='Promote Students' subtitle='Promote student  here' />
			<div className=' flex justify-start items-end font-medium text-lg  mt-10 flex-wrap'>
				<span className=' '>Student list - </span>
				{state?.data?.name && <span className='flex flex-wrap'> {state?.data.name}</span>}
			</div>
			<div className='flex justify-end mt-10 flex-wrap'>
				<button
					onClick={() => setIsOpen(true)}
					type='button'
					className='w-fit h-fit px-5 py-3 bg-other flex gap-3 rounded-lg hover:scale-90 transition-all'
				>
					<span className='text-white'>Promote Student</span>
				</button>
			</div>{' '}
			<div className='mt-7'>
				{getStudents?.data?.length > 0 ? (
					<>
						<div style={{ height: 400, width: '100%' }}>
							<DataGrid
								rows={filteredStudent}
								columns={columns}
								initialState={{
									pagination: {
										paginationModel: { page: 0, pageSize: 20 },
									},
								}}
								pageSizeOptions={[20, 50]}
								checkboxSelection
								// selectionModel={selectedRows}
								rowSelectionModel={selectedRows}
								onRowSelectionModelChange={handleSelectionModelChange}
							/>
						</div>
					</>
				) : getStudents?.data?.length < 1 ? (
					<div className='text-center text-xs'>
						<EmptyStudentData noStudentRegister />
					</div>
				) : (
					<div className='w-full mb-3 px-8 flex justify-between items-center'>
						<Skeleton variant='rectangular' height={50} className='w-[50px] rounded-full mb-4' />
						<div className='w-[80%]'>
							<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
							<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
						</div>
					</div>
				)}
			</div>
			<Modal open={isOpen} close={() => setIsOpen(false)} maxWidth='xs'>
				<div className=' flex justify-center flex-col items-center pb-8 px-8'>
					<div className='mb-8'>
						<label htmlFor='text' className='text-gray-600 text-sm'>
							Select New Class to be promoted to
						</label>
						<select
							className='appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200'
							value={classValue}
							onChange={(e: any) => setClassValue(e.target.value)}
						>
							<option value=''>Select a class</option>
							{classData?.data?.map((item: any, i: number) => (
								<option key={i} value={item.id}>
									{item.name}
								</option>
							))}
						</select>
					</div>

					<div className='flex justify-center  items-center'>
						<div className='w-32 ml-4'>
							<Button
								onClick={() => {
									setIsOpen(false);
								}}
								iconClassName='h-[0.9rem] w-3'
								title={'cancel '}
								bgColor={'bg-other'}
								hoverColor={''}
								textColor={'text-white'}
								className={' rounded-lg w-full  text-white !bg-black   '}
							/>
						</div>
						<div className='w-32 ml-4'>
							<Button
								onClick={() => {
									setIsOpen(false);
									setPromoteModal(true);
								}}
								iconClassName='h-[0.9rem] w-3'
								title={'promote '}
								bgColor={'bg-other'}
								hoverColor={''}
								textColor={'text-white'}
								className={' rounded-lg w-full  text-white !bg-brand   '}
							/>
						</div>
					</div>
				</div>
			</Modal>
			<ConfirmationModal
				loading={loading}
				handleClick={() => {
					if (classValue) {
						handleSubmit();
					} else toast.error('Select a class');
				}}
				btnTitle='Promote'
				isOpen={promoteModal}
				setIsOpen={setPromoteModal}
				title={
					'You are about to promote these/this student(s). Click cancel to cancel this action or proceed with continue'
				}
			/>
		</main>
	);
}

export default PromoteClass;

import Classes from '@/pages/students/Classes';
import MyResults from '@/pages/students/MyResults';
import Settings from '@/pages/Settings';
import Subjects from '@/pages/students/Subjects';
import Dashboard from '@/pages/students/dashboard';
import Events from '@/pages/students/events';
import Profile from '@/pages/students/profile';
import EditProfile from '@/pages/students/profile/EditProfile';

export const studentsRoutes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Dashboard,
		path: 'student/home',
	},
	{
		id: '2',
		title: 'Subjects',
		component: Subjects,
		path: 'student/subject',
	},
	{
		id: '3',
		title: 'Classes',
		component: Events,
		path: 'student/events',
	},
	{
		id: '4',
		title: 'My Results',
		component: MyResults,
		path: 'student/my-results',
	},

	{
		id: '5',
		title: 'Profile',
		component: Profile,
		path: 'student/profile',
	},
	{
		id: '6',
		title: 'Edit Profile',
		component: EditProfile,
		path: 'student/profile/edit-profile',
	},
	{
		id: '7',
		title: 'Settings',
		component: Settings,
		path: 'student/settings',
	},
];

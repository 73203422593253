// Modal.js
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { PRESENT, SICK, TIMES } from "../../../../assets";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (value: string) => void;
  selectedCell: (selectedCell: any) => void;
};
const SmallModal = ({ isOpen, onClose, onSelect, selectedCell }: Props) => {
  const [values] = useState([
    {
      label: "PRESENT",
      svg: PRESENT,
      color: "text-[#16A546]",
    },
    {
      label: "SICK",
      svg: SICK,
      color: "text-[#D8D013]",
    },
    {
      label: "ABSENT",
      svg: TIMES,
      color: "text-[#F50C0C]",
    },
  ]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderRadius: "1rem",
          boxShadow: 24,
          p: 2,
        }}
      >
        {values.map((value, i) => (
          <div className='flex flex-col items-center py-3' key={i}>
            <div
              onClick={() => {
                onSelect(value.label);
                onClose();
              }}
              className='flex hover:border-[#93002F] hover:border-2 px-7 py-2 rounded-lg gap-3 cursor-pointer'
            >
              <button type='button' className={value.color}>
                {value.label}
              </button>

              <img src={value.svg} width={20} height={20} alt='value icon' />
            </div>
          </div>
        ))}
      </Box>
    </Modal>
  );
};

export default SmallModal;

import React, { useState } from 'react';
import DashboardHeader from '../../../components/headers/dashboard-header';
import { add, edit, exports, filter, print } from '../../../assets';
import EmptyStudentData from './contents/emptyStudentData';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { TableComponent } from '../../../components/table/Table';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import DropDownV2 from '../../../components/menu-dropdown/dropDownV2';
import { useMutation, useQuery } from 'react-query';
import { deleteStudent, editStudent, getUsers } from '../../../services/Admin/dashboard';
import toast from 'react-hot-toast';
import { Skeleton } from '@mui/material';

const Students = () => {
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const deleteStudentMutation = useMutation(deleteStudent);
	const params = {
		page,
		role: 'STUDENT',
	};
	const { data: userData, refetch } = useQuery(['subject', params], () => getUsers(params));

	const navigate = useNavigate();

	const USER_COLUMNS = [
		{
			Header: 'FULL NAME',

			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>
						{row.original.first_name} {row.original.last_name}
					</span>
				</div>
			),
		},

		{
			Header: 'ROLE',
			accessor: 'role',
		},

		{
			Header: 'ADDRESS',
			accessor: 'address',
		},
		{
			Header: 'EMAIL',
			accessor: 'email',
		},
		{
			Header: 'UNIQUE NUMBER',
			accessor: 'unique_number',
		},
		{
			Header: 'ACTION',
			accessor: '',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<DropDownV2
						handleDelete={() => {
							setConfirmationModal(true);
							setData(row?.original);
						}}
						handleEdit={() => {
							navigate('/admin/students/edit-students', {
								state: { data: row?.original },
							});
							setData(row?.original);
						}}
						handleView={() => navigate('/admin/Students/view', { state: { data: row.original } })}
						editText='student'
						viewText='student'
					/>
				</div>
			),
		},
	];

	const handleDelete = async (id: any) => {
		setLoading(true);
		try {
			const res: any = await deleteStudentMutation?.mutateAsync(id);
			if (res) {
				refetch();
				setLoading(false);
				toast.success(res?.message);
				setConfirmationModal(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<main className='mt-6 lg:px-8 px-1'>
			<DashboardHeader title='Students' subtitle='Add and manage students here' />
			<div className='flex justify-between  mt-10 flex-wrap'>
				<span className='text-lg font-medium'>Student list</span>
				<div className='flex lg:gap-5 xs:gap-2 lg:flex-row xs:flex-col flex-wrap'>
					<Link to='/admin/students/add_students'>
						<button
							type='button'
							className='w-fit h-fit px-5 py-3 bg-other flex gap-3 rounded-lg hover:scale-90 transition-all'
						>
							<img src={add} alt='' width={20} height={20} />
							<span className='text-white'>Add new student</span>
						</button>
					</Link>
				</div>
			</div>

			<div className='mt-7'>
				{userData?.data?.length > 0 ? (
					<>
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={userData?.data}
							COLUMNS={USER_COLUMNS}
							setPage={setPage}
							page={page}
						/>
					</>
				) : userData?.data?.length < 1 ? (
					<div className='text-center text-xs'>
						<EmptyStudentData noStudentRegister />
					</div>
				) : (
					<div className='w-full mb-3 px-8 flex justify-between items-center'>
						<Skeleton variant='rectangular' height={50} className='w-[50px] rounded-full mb-4' />
						<div className='w-[80%]'>
							<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
							<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
						</div>
					</div>
				)}
			</div>

			<ConfirmationModal
				loading={loading}
				handleClick={() => handleDelete(data?.uuid)}
				isOpen={confirmationModal}
				setIsOpen={setConfirmationModal}
				title={
					'You are about to delete this teacher information. click cancel to cancel this action or proceed with continue'
				}
			/>
		</main>
	);
};

export default Students;

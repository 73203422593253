/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { registerParentAPI, updateParentAPI } from '../../../services/authServices';
import { getAllUsers, getUsers, showUser } from '../../../services/Admin/dashboard';
import ImageInput from '../../image-input';
import Input from '../Input';
import Select from '../Select';
import Button from '../../button';
import SuccessModal from '../../Modal/SuccessModal';
import StudentSelector from '@/components/Multiselect/selctor';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

const EditParentForm = () => {
	const [modal, setModal] = useState(false);

	const [classValue, setClassValue] = useState<any>([]);
	const [nameSelected, SetNameSelected] = useState<any>([]);
	const [file, setFile] = useState<any>([]);

	const data = useLocation()?.state?.data;
	const { data: userInfo } = useQuery(['showUser', data?.id], () => showUser(data?.id));
	const { data: studentData, refetch } = useQuery(['all students', 'STUDENT'], () =>
		getAllUsers('STUDENT')
	);
	const navigate = useNavigate();
	const darkMode = useSelector(selectDarkmode);

	// Function to filter students from data based on their names
	const filteredStudents = studentData?.data?.filter((studentData: any) => {
		const fullName = `${studentData?.first_name} ${studentData?.last_name}`;
		return nameSelected?.includes(fullName);
	});

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className=''>
				{userInfo && (
					<Formik
						initialValues={{
							role: '',
							first_name: userInfo?.data?.first_name ?? '',
							last_name: userInfo?.data?.last_name ?? '',
							other_name: userInfo?.data?.other_name ?? '',
							gender: userInfo?.data?.gender ?? '',
							phone_number: userInfo?.data?.phone_number ?? '',
							email: userInfo?.data?.email ?? '',
							residential_address: userInfo?.data?.address ?? '',
						}}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							const formData = new FormData();

							formData.append('first_name', values.first_name);
							formData.append('last_name', values.last_name);
							formData.append('other_name', values.other_name);
							formData.append('gender', values.gender);
							formData.append('phone_number', values.phone_number);
							formData.append('email', values.email);
							formData.append('address', values.residential_address);
							if (file?.length > 0) {
								formData.append('profile_image', file[0]);
							}

							if (filteredStudents?.length > 0) {
								for (let i = 0; i < filteredStudents?.length; i++) {
									formData.append(`children[${i}]`, filteredStudents[i]?.id);
								}
							}

							try {
								const res = await updateParentAPI(formData, userInfo?.data?.uuid);

								if (res?.data) {
									setModal(true);
									resetForm();
									file.length = 0;
								}
							} catch (err: any) {
								if (err?.response?.data.message) {
									toast.error(err?.response?.data.message, {
										duration: 3000,
									});
								} else {
									toast.error('Something went wrong. Try again later', {
										duration: 3000,
									});
								}
							}
						}}
					>
						{({
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form>
								<div
									className={`border mt-6 lg:px-7 px-4 rounded-lg ${
										darkMode ? 'border-gray-700' : ''
									}`}
								>
									<div className='pb-7 py-6'>
										<span className='font-medium text-lg'>Personal information</span>
										<div className='py-5 w-40'>
											<ImageInput
												file={file}
												setFile={setFile}
												data={userInfo?.data?.profile_image}
											/>
										</div>
										<div className='flex justify-between items-start flex-wrap'>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													First name
												</label>
												<Input
													name={'first_name'}
													placeholder={'Full name'}
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												/>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Last name
												</label>
												<Input
													name={'last_name'}
													placeholder={'Last name'}
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												/>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Gender
												</label>
												<Select
													name='gender'
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												>
													<option value=''>Select a gender</option>
													<option value='male'>Male</option>
													<option value='female'>Female</option>
												</Select>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Phone number
												</label>
												<Input
													name={'phone_number'}
													placeholder={'Phone number'}
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												/>
											</div>

											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Residential address
												</label>
												<Input
													name={'residential_address'}
													placeholder={'Enter residential address'}
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												/>
											</div>
											<div className=' w-full lg:w-[49%]'>
												<label htmlFor='text' className='text-gray-400 text-xs'>
													Email address
												</label>
												<Input
													name={'email'}
													placeholder={'Enter Email address'}
													className={`${
														darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
													}`}
												/>
											</div>
											<div className='w-full lg:w-1/2 overflow-hidden'>
												<label htmlFor='text' className='text-xs text-gray-400'>
													Add student
												</label>
												{studentData?.data?.length > 0 && (
													// this was created for any array of objects and keys for online the student

													<StudentSelector
														data={studentData?.data}
														studentName={nameSelected}
														setStudentName={SetNameSelected}
													/>
												)}
											</div>
										</div>
									</div>
									<hr />

									<div className='flex justify-center items-center'>
										<Button
											iconClassName='h-[0.9rem] w-3'
											isLoading={isSubmitting}
											title={'Add Parent'}
											bgColor={'bg-other'}
											hoverColor={''}
											textColor={'text-white'}
											className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  '}
										/>
									</div>

									<SuccessModal
										isOpen={modal}
										isClose={() => {
											navigate('/admin/users-and-roles');
											setModal(false);
										}}
										title={'Parent has been added successfully to the system.'}
									/>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</main>
	);
};

export default EditParentForm;

import React from "react";
import { MdClose } from "react-icons/md";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

interface ModalProps {
  children: JSX.Element;
  close: any;
  open: any;
  maxWidth?: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Modal(props: ModalProps) {
  const darkMode = useSelector(selectDarkmode);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={props.maxWidth}
      TransitionComponent={Transition}
      open={props.open}
      onClose={props.close}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={`px-8 ${darkMode ? "bg-black" : "bg-white"} `}>
        <DialogActions sx={{ alignSelf: "flex-end" }}>
          <IconButton onClick={props.close}>
            <MdClose className={darkMode ? "text-gray-300" : ""} />
          </IconButton>
        </DialogActions>
        {props.children}
      </div>
    </Dialog>
  );
}

export default Modal;

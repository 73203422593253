import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

interface DbTypes {
	handleDelete?: () => void;
	handleView?: () => void;
	handleEdit?: () => void;
	isLoading?: boolean;
	viewText: string;
	editText?: string;
}

export default function PromoteDropDown(props: DbTypes) {
	// const navigate = useNavigate();
	return (
		<div className=' text-right '>
			<Menu as='div' className=' inline-block text-left'>
				<div>
					{props.isLoading ? (
						<button
							disabled={props.isLoading}
							type='button'
							className='inline-flex w-full justify-center rounded-md bg-BRAND text-black px-2 py-1 text-xs font-medium  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
						>
							<svg
								className='w-5 h-5 mr-3 -ml-1 text-black animate-spin'
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
							>
								<circle
									className='opacity-25'
									cx={12}
									cy={12}
									r={10}
									stroke='currentColor'
									strokeWidth={4}
								/>
								<path
									className='opacity-75'
									fill='currentColor'
									d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
								></path>
							</svg>

							<span className='ml-2 text-xs'>processing...</span>
						</button>
					) : (
						<Menu.Button className='inline-flex w-full justify-center rounded-md bg-BRAND text-black px-2 py-1 text-xs font-medium  hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
							<MoreVertIcon className='ml-2 -mr-1 h-5 w-5 text-brand ' aria-hidden='true' />
						</Menu.Button>
					)}
				</div>
				<Transition
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'
				>
					<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
						<div className='px-1 py-1'>
							<Menu.Item>
								{({ active }) => (
									<button
										onClick={props.handleView}
										className={`${
											active ? 'bg-yellow-500 text-white' : 'text-gray-900'
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<EditActiveIcon className='mr-2 h-5 w-5' aria-hidden='true' />
										) : (
											<EditInactiveIcon className='mr-2 h-5 w-5' aria-hidden='true' />
										)}
										{props.viewText}
									</button>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	);
}

function EditInactiveIcon(props: any) {
	return (
		<svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M4 13V16H7L16 7L13 4L4 13Z' fill='#EDE9FE' stroke='#F50C0C' strokeWidth='2' />
		</svg>
	);
}

function EditActiveIcon(props: any) {
	return (
		<svg {...props} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M4 13V16H7L16 7L13 4L4 13Z' fill='#F50C0C' stroke='#000' strokeWidth='2' />
		</svg>
	);
}

import React, { useRef, useEffect, useState } from "react";
import {
  DARK_MODE_ARROW,
  WHITE_ARROW_DOWN,
  leftArrow,
  success,
} from "../../../../assets";
import DashboardHeader from "../../../../components/headers/dashboard-header";
import Button from "../../../../components/button";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import Input from "../../../../components/form/Input";
import { getAllSubject } from "../../../../services/Admin/subject";
import MultipleSelectCheckmarksV2 from "../../../../components/Multiselect/variant";
import {
  createClass,
  getCategories,
  getSection,
  getUsers,
} from "../../../../services/Admin/dashboard";
import Select from "../../../../components/form/Select";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const CreateClass = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [inputModal, setInputModal] = useState(false);
  const [selectStr, setSelectStr] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const createClassMutation = useMutation(createClass);
  const { data: subjectData } = useQuery(["subject", 1], () =>
    getAllSubject(1)
  );
  const [subjectValue, setSubjectValue] = useState<any>([]);

  const { data: categoriesData } = useQuery("categories", getCategories);
  const { data: sectionData } = useQuery("sections", getSection);
  const navigate = useNavigate();

  const params = {
    page,
    role: "TEACHER",
    isNotPaginated: true,
  };
  const { data: userData, refetch } = useQuery(["TEACHER", params], () =>
    getUsers(params)
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddStr = () => {
    if (inputValue.trim() !== "") {
      setSelectStr([...selectStr, inputValue]);
      setInputValue("");
    }
  };

  const darkMode = useSelector(selectDarkmode);

  return (
    <section className="mt-7 lg:px-9 px-4 w-full">
      <div className="flex gap-5">
        <Link to="/admin/classes">
          {darkMode ? (
            <div className="w-10 h-10 justify-center items-center flex rounded-full bg-black">
              <img src={DARK_MODE_ARROW} alt="" className="w-4" />
            </div>
          ) : (
            <img src={leftArrow} alt="arrow_left" />
          )}
        </Link>
        <DashboardHeader
          title="Classes"
          subtitle="Create and manage classes here"
        />
      </div>
      <div
        className={`mt-9 lg:px-10 px-4 w-full border rounded-lg  py-9 flex flex-col ${
          darkMode ? " border-gray-700" : ""
        }`}
      >
        <Formik
          initialValues={{
            name: "",
            section: "",
            category: "",
            teacher_id: "",
            subject_ids: "",
          }}
          onSubmit={async (values) => {
            const formData = new FormData();

            formData.append("name", values.name);
            formData.append("section", values.section);
            formData.append("category", values.category);
            if (values.teacher_id) {
              formData.append("teacher_id", values.teacher_id);
            }
            // Iterate through the arrays and append data to formData
            for (let i = 0; i < subjectValue.length; i++) {
              formData.append(`subject_ids[${i}]`, subjectValue[i]?.id);
            }

            try {
              const res = await createClassMutation.mutateAsync(formData);

              if (res?.data) {
                setOpen(true);
              }
            } catch (err: any) {
              if (err?.response?.data.message) {
                toast.error(err?.response?.data.message, {
                  duration: 3000,
                });
              } else {
                toast.error("Something went wrong. Try again later", {
                  duration: 3000,
                });
              }
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <div className="pb-7 py-6">
                <span className="font-medium text-lg">
                  Personal information
                </span>

                <div className="flex justify-between items-start flex-wrap">
                  <div className=" w-full lg:w-[49%]">
                    <label htmlFor="text" className="text-gray-400 text-xs">
                      Class Name
                    </label>
                    <Input
                      name={"name"}
                      placeholder={"Full name"}
                      className={`${
                        darkMode
                          ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                          : ""
                      }`}
                    />
                  </div>
                  <div className=" w-full lg:w-[49%]">
                    <label htmlFor="text" className="text-gray-400 text-xs">
                      Class section
                    </label>
                    <Select
                      name="section"
                      className={`${
                        darkMode
                          ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                          : ""
                      }`}
                    >
                      <option value="">Select a section</option>
                      {sectionData?.data?.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Select>
                  </div>
                  <div className=" w-full lg:w-[49%]">
                    <label htmlFor="text" className="text-gray-400 text-xs">
                      Class category
                    </label>

                    <Select
                      name="category"
                      className={`${
                        darkMode
                          ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                          : ""
                      }`}
                    >
                      <option value="">Select a section</option>
                      {categoriesData?.data?.map((item: string) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Select>
                  </div>

                  <div className=" w-full lg:w-[49%]">
                    <label htmlFor="text" className="text-gray-400 text-xs">
                      Subjects
                    </label>

                    {subjectData?.data?.length > 0 && (
                      <MultipleSelectCheckmarksV2
                        data={subjectData?.data}
                        personName={subjectValue}
                        setPersonName={setSubjectValue}
                      />
                    )}
                  </div>
                  <div className=" w-full lg:w-[49%]">
                    <label htmlFor="text" className="text-gray-400 text-xs">
                      Teachers
                    </label>

                    {userData?.data?.length > 0 && (
                      <Select
                        name="teacher_id"
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      >
                        <option value="">Select a teachers</option>
                        {userData?.data?.map((item: any) => (
                          <option
                            value={item.id}
                          >{`${item?.first_name} ${item?.last_name}`}</option>
                        ))}
                      </Select>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-6">
                <Button
                  iconClassName="h-[0.9rem] w-3"
                  isLoading={isSubmitting}
                  title={"Add Class"}
                  bgColor={"bg-other"}
                  hoverColor={""}
                  textColor={"text-white"}
                  className={
                    "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  "
                  }
                />
              </div>
            </Form>
          )}
        </Formik>

        {inputModal && (
          <Modal
            open={() => setInputModal(true)}
            close={() => setInputModal(false)}
            maxWidth="xs"
          >
            <div className="flex flex-col justify-center items-center gap-3 px-9 pb-9">
              <span className="text-center text-2xl">Tag a subject</span>
              <input
                type="text"
                className="border px-3 py-4 w-full rounded-lg outline-none"
                placeholder="Enter tag"
                ref={inputRef}
                value={inputValue}
                onChange={handleInputChange}
              />

              <button
                type="button"
                onClick={handleAddStr}
                className="border h-fit w-fit rounded-lg px-3 py-2 hover:scale-90 transition-all"
              >
                {!inputValue ? "" : "Add"}
              </button>
            </div>
          </Modal>
        )}
      </div>
      {open && (
        <Modal
          maxWidth="xs"
          open={() => setOpen(true)}
          close={() => setOpen(false)}
        >
          <div className="flex flex-col justify-center items-center gap-6">
            <img src={success} alt="good" width={100} height={70} />
            <Typography textStyle="text-center">
              <div>
                <span className="text-2xl font-semibold">
                  Action Successful
                </span>
                <p>Class has been successfully created</p>
              </div>
            </Typography>
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-other"}
              onClick={() => {
                setOpen(false);
                navigate("/admin/classes");
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={
                "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  "
              }
            />
          </div>
        </Modal>
      )}
    </section>
  );
};

export default CreateClass;

import { useState } from "react";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/button";

export default function EventDetailsModal() {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} close={handleClose} maxWidth="xs">
      <div className="px-8">
        <Typography textStyle="text-center pt-2">
          <div className="flex flex-col pb-6">
            <span className="text-slate-950 font-[500] text-xl">
              Event list
            </span>
            <span className="text-sm font-[500] text-gray-950">
              Friday, 11th june, 2023
            </span>
          </div>
        </Typography>
        <Typography>
          <div className="flex flex-col gap-7">
            <div className="flex justify-between bg-gray-50 rounded-lg px-3 py-3 shadow-md">
              <div className="flex flex-col gap-2">
                <span className="font-[500]">Interhouse sport competition</span>
                <span className="text-xs text-gray-400">
                  @ the school sports ground
                </span>
              </div>
              <span className="text-xs mt-6 text-gray-700">
                11:32am-12:30pm
              </span>
            </div>
            <div className="flex justify-between bg-gray-50 rounded-lg px-3 py-3 shadow-md">
              <div className="flex flex-col gap-2">
                <span className="font-[500]">Interhouse sport competition</span>
                <span className="text-xs text-gray-400">
                  @ the school sports ground
                </span>
              </div>
              <span className="text-xs mt-6 text-gray-700">
                11:32am-12:30pm
              </span>
            </div>
            <div className="flex justify-between bg-gray-50 rounded-2lg px-3 py-3 shadow-md">
              <div className="flex flex-col gap-2">
                <span className="font-[500]">Interhouse sport competition</span>
                <span className="text-xs text-gray-400">
                  @ the school sports ground
                </span>
              </div>
              <span className="text-xs mt-6 text-gray-700">
                11:32am-12:30pm
              </span>
            </div>
          </div>
        </Typography>
        <div className="py-8 ">
          <Button
            iconClassName="h-[0.9rem] w-3"
            // isLoading={loading}
            title={"close"}
            onClick={handleClose}
            bgColor={"bg-other"}
            hoverColor={""}
            textColor={"text-white"}
            className={"py-4 rounded-lg bg-brand w-full"}
          />
        </div>
      </div>
    </Modal>
  );
}

import { API } from "../../config";

// Login function
export async function createSubject(data: any) {
  const response = await API.post("admin/subjects", data);
  return response.data;
}
export async function getSubject(page: number) {
  const response = await API.get(`admin/subjects?page=${page}&per_page=15`);
  return response.data;
}
export async function getAllSubject(page: number) {
  const response = await API.get(`admin/subjects?should_paginate=0`);
  return response.data;
}
export async function getAllSubjectPaginated(page: number) {
  const response = await API.get(`admin/subjects?page=${page}&per_page=15`);
  return response.data;
}
export async function getClassSubject(param: any) {
  const response = await API.get(
    `admin/class-subjects?class_id=${param.id}&should_paginate=0`
  );
  return response.data;
}
export async function deleteSubjectAPI(ID: number | undefined) {
  const response = await API.delete(`admin/subjects/${ID}`);
  return response.data;
}
export async function editSubject(data: any) {
  const response = await API.post(`admin/subjects/${data.id}/update`, {
    name: data.name,
    description: data.description,
  });
  return response.data;
}

import React, { useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { add, Nosubject } from "../../../assets";

import { Link, useNavigate } from "react-router-dom";
import { TableComponent } from "../../../components/table/Table";
import { useMutation, useQuery } from "react-query";
import { deleteSubjectAPI, getSubject } from "../../../services/Admin/subject";
import DropDownV2 from "@/components/menu-dropdown/dropDownV2";
import ConfirmationModal from "@/components/Modal/ConfirmationModal";
import toast from "react-hot-toast";
import { Skeleton } from "@mui/material";
import TeacherEmptyData from "../Teachers/contents/teachersEmptyData";

interface DataTypes {
  id: number;
  name: string;
  slug: string;
  description: string;
  is_enabled: boolean;
}

function Subjects() {
  // const subjectData = true;
  const [page, setPage] = useState(1);
  const [data, setData] = useState<DataTypes>();

  const [loading, setLoading] = useState(false);

  const { data: subjectData, refetch } = useQuery(["subject", page], () =>
    getSubject(page)
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const deleteSubjectMutation = useMutation(deleteSubjectAPI);
  const navigate = useNavigate();

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const SUBJECT_COLUMNS = [
    {
      Header: "SUBJECT TITLE",
      accessor: "name",
    },

    {
      Header: "DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "STATUS",
      accessor: "is_enabled",
      Cell: ({ cell: { row } }: any) => {
        return (
          <button
            className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
              row.original?.is_enabled === true
                ? "bg-green-200 text-green-700"
                : "bg-red-200 text-red-700"
            }`}
          >
            {row.original?.is_enabled === true ? "Active " : "Disabled"}
          </button>
        );
      },
    },

    {
      Header: "ACTION",
      accessor: "",
      Cell: ({ row }: any) => (
        <div className="relative">
          <DropDownV2
            handleDelete={() => {
              setConfirmationModal(true);
              setData(row?.original);
            }}
            handleEdit={() => {
              navigate(`/admin/subjects/edit-subject`, {
                state: { data: row?.original },
              });
              setData(row?.original);
            }}
            editText="subject"
            viewText="subject"
          />
        </div>
      ),
    },
  ];

  const handleDelete = async () => {
    setLoading(true);
    const id = data?.id;
    try {
      const res: any = await deleteSubjectMutation?.mutateAsync(id);
      if (res) {
        refetch();
        setLoading(false);
        toast.success(res?.message);
        setConfirmationModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <main className="mt-6 lg:px-8 px-1">
      <DashboardHeader
        title="Subjects 📚"
        subtitle="Add and manage all subjects here"
      />
      <div className="justify-between flex flex-wrap">
        <span className="mb-2">Subject list</span>
        <div className="flex lg:flex-row xs:flex-col lg:gap-9 xs:gap-3 flex-wrap">
          <Link to="/admin/subjects/add_subject">
            <button
              type="button"
              className="flex gap-3 bg-other w-fit h-fit px-6 py-3 rounded-lg hover:scale-90 transition-all"
            >
              <img src={add} alt="add" width={20} height={20} />
              <span className="text-white">Add subject</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-7 ">
        {subjectData?.data?.length > 0 ? (
          <TableComponent
            className="rounded-none rounded-t-0"
            DATA={subjectData?.data}
            COLUMNS={SUBJECT_COLUMNS}
            page={page}
            setPage={setPage}
          />
        ) : subjectData?.data?.length < 1 && page > 1 ? (
          <div className="flex flex-col justify-center items-center w-full px-2 lg:px-4 py-2">
            <img
              src={Nosubject}
              alt=""
              width={400}
              height={350}
              className="mb-4"
            />
            <p className="mb-4 text-center text-xs">
              No Subject Data Available
            </p>

            <div className="flex justify-center items-center text-xs rounded-sm py-1  w-full ">
              {page > 1 && (
                <button
                  className=" cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand"
                  onClick={() => handlePrevious()}
                  // disabled={!canPreviousPage}
                >
                  Previous
                </button>
              )}

              <span className="mx-4 text-xs">
                Page <strong>{page}</strong>{" "}
              </span>
            </div>
          </div>
        ) : subjectData?.data?.length < 1 && page === 1 ? (
          <p className="text-center text-xs">
            No recent transaction at the moment
          </p>
        ) : (
          <div className="w-full mb-3 px-8 flex justify-between items-center">
            <Skeleton
              variant="rectangular"
              height={50}
              className="w-[50px] rounded-full mb-4"
            />
            <div className="w-[80%]">
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-full mb-4"
              />
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-[80%] mb-4"
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmationModal
        loading={loading}
        handleClick={() => handleDelete()}
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        title={
          "You are about to delete this teacher information. click cancel to cancel this action or proceed with continue"
        }
      />
    </main>
  );
}

export default Subjects;

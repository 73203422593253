import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { exports, leftArrow, print } from '../../../../assets';
import EventDetailsModal from '../modal/EventDetailsModal';

// import EventModal from "@/components/EventModal";

const TableData = [
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
	{
		event: 'Interhouse sport',
		time: '8:00AM',
		date: '23rd March, 2023',
		location: 'School sport ground',
	},
];
const Event = () => {
	const data = React.useMemo(() => TableData, []);
	const columns = React.useMemo(
		() => [
			{
				Header: 'EVENT TITLE',
				accessor: 'event',
			},
			{
				Header: 'TIME',
				accessor: 'time',
			},
			{
				Header: 'DATE',
				accessor: 'date',
			},
			{
				Header: 'LOCATION',
				accessor: 'location',
			},
		],
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});
	const [modal, setModal] = React.useState(false);
	return (
		<div className='mt-6 ml-7'>
			<div className='flex gap-5'>
				<Link to='/dashboard'>
					<img src={leftArrow} width={50} height={30} alt='left' />
				</Link>
				<div className='flex flex-col'>
					<span className='text-xl font-[500] text-gray-900'>Events list</span>
					<span className='text-gray-400'>See and manage upcoming task.</span>
				</div>
			</div>
			<div className='mx-9 my-9 flex flex-col gap-2'>
				<div className='flex justify-between'>
					<span className='mb-5 font-bold text-gray-800'>All event</span>
					{/* <div className="flex gap-10">
            <button type="button">
              <img src={print} width={20} height={20} alt="print icon" />
            </button>
            <button type="button">
              <img src={exports} width={40} height={30} alt="print icon" />
            </button>
          </div> */}
				</div>

				<div>
					<table {...getTableProps()} className='w-full'>
						<thead className=' bg-other'>
							{headerGroups.map((headerGroup, i) => (
								<tr
									key={i}
									{...headerGroup.getHeaderGroupProps()}
									className='px-6 border rounded-t-lg'
								>
									{headerGroup.headers.map((column, i) => (
										<th
											key={i}
											{...column.getHeaderProps()}
											className='py-4 pr-4 text-white font-[500]'
										>
											{column.render('Header')}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								prepareRow(row);
								return (
									<tr
										key={i}
										{...row.getRowProps}
										className='border pl-4 cursor-pointer'
										onClick={() => setModal((prev) => !prev)}
									>
										{row.cells.map((cell) => (
											<td key={i} {...cell.getCellProps()} className=' px-8 py-4 '>
												{cell.render('Cell')}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{modal && <EventDetailsModal />}
				<div className='flex justify-between w-full mt-2 border px-2 py-3 rounded-b-lg font-[550]'>
					<span>page 1 of 15</span>
					<div className='gap-3 flex'>
						<span className='bg-gray-200 px-2'>1</span>
						<span>2</span>
						<span>3</span>
						<span>...</span>
						<span>15</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Event;

import React from "react";
import Modal from ".";
import { success } from "../../assets";
import Typography from "../Typography";
import Button from "../button";

interface Props {
  isOpen: boolean;
  isClose: () => void;
  title: string;
}
const SuccessModal = ({ isOpen, isClose, title }: Props) => {
  return (
    <Modal maxWidth='xs' open={isOpen} close={isClose}>
      <div className='justify-center items-center flex flex-col '>
        <img src={success} alt='success' width={70} height={70} />
        <Typography textStyle='text-center'>
          <div>
            <span className='text-lg font-semibold'>Action Successful</span>
            <p className='text-xs'>{title}</p>
          </div>
        </Typography>
        <Button
          iconClassName='h-[0.9rem] w-3'
          // isLoading={loading}
          title={"Close"}
          bgColor={"bg-other"}
          onClick={isClose}
          hoverColor={""}
          textColor={"text-white"}
          className={"py-2 rounded-lg bg-brand w-[12rem] mt-4  mb-8"}
        />
      </div>
    </Modal>
  );
};

export default SuccessModal;

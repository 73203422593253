import React, { useEffect, useState } from "react";

import { add } from "../../../assets";
// import AdminTable from "./AdminTable";
import { Link, useNavigate } from "react-router-dom";
import { TableComponent } from "../../../components/table/Table";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import EmptyStudentData from "../Students/contents/emptyStudentData";
import { useMutation } from "react-query";
import { deleteEvents } from "../../../services/Admin/dashboard";
import DropDownV2 from "../../../components/menu-dropdown/dropDownV2";
import toast from "react-hot-toast";
import moment from "moment";
import { Skeleton } from "@mui/material";
// import { getWeekDates } from "../utils";

interface Props {
  text: string;
  color: string;
  isActive: boolean;
}
// const icons = [filter, print, exports];

const AdminManageMent = ({ getAllEvents }: any) => {
  // Create a new Date object
  const currentDate = new Date();

  // Get the day of the week as a number (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const dayOfWeek = currentDate.getDay();

  // Convert the number to a string representing the day
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = daysOfWeek[dayOfWeek];
  const navigate = useNavigate();
  // console.log(`Today is ${dayName}`);

  const [dateDrop, setDateDrop] = useState(false);
  const [activeDay, setActiveDay] = useState(dayName); // Initialize with an empty string
  const [page, setPage] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const deleteEventMutation = useMutation(deleteEvents);

  const EVENT_COLUMNS = [
    {
      Header: "EVENT NAME",
      accessor: "title",
    },

    {
      Header: "DESCRIPTION",
      Cell: ({ cell: { row } }: any) => {
        return (
          <div>
            <div className="overflow-ellipsis whitespace-nowrap">
              <p>
                {row.original?.description.length > 49
                  ? `${row.original?.description?.slice(0, 50)} ...`
                  : row.original?.description}
              </p>
            </div>
          </div>
        );
      },
    },

    {
      Header: "LOCATION",
      accessor: "location",
    },

    {
      Header: "EVENT DATE",
      accessor: "start_date",
      Cell: ({ cell: { row } }: any) => {
        return moment(row.original?.start_date).format("MMM Do YYYY");
      },
    },
    {
      Header: "ACTION",
      accessor: "",
      Cell: ({ row }: any) => (
        <div className="relative">
          <DropDownV2
            handleDelete={() => {
              setConfirmationModal(true);
              setData(row?.original);
            }}
            handleEdit={() => {
              navigate("/calender-events/edit-events", {
                state: { data: row?.original },
              });
            }}
            editText="event"
            viewText="event"
          />
        </div>
      ),
    },
  ];

  const [items, setItems] = useState<Props[]>([
    {
      text: "Monday",
      color: "text-blue-500",
      isActive: false,
    },
    {
      text: "Tuesday",
      color: "text-blue-500",
      isActive: false,
    },
    {
      text: "Wednesday",
      color: "text-blue-500",
      isActive: false,
    },
    {
      text: "Thursday",
      color: "text-blue-500",
      isActive: false,
    },
    {
      text: "Friday",
      color: "text-blue-500",
      isActive: false,
    },
  ]);

  const handleClick = (index: number) => {
    const clickedDay = items[index].text;
    setActiveDay(clickedDay);
    const changedItems = items.map((item, i) => ({
      ...item,
      color: i === index ? "text-blue-500" : "",
      isActive: i === index ? !item.isActive : false,
    }));
    setItems(changedItems);
  };

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) {
        setDateDrop(false);
      } else {
        setDateDrop(true);
      }
    };

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);

    return () => window.removeEventListener("resize", checkWindowSize);
  }, []);

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      const res: any = await deleteEventMutation?.mutateAsync(id);
      if (res) {
        setLoading(false);
        toast.success(res?.message);
        setConfirmationModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const event = getAllEvents?.find(
    (day: any) => Object.keys(day)[0] === activeDay
  )?.[activeDay];

  return (
    <section className="mt-6">
      <span className="font-[500] text-lg">My Schedule</span>
      <div className="flex flex-1 justify-between items-center mt-3 relative">
        <span
          className="hidden lg:hidden xs:block w-fit h-fit rounded-lg px-2 py-1 border "
          onClick={() => setDateDrop(true)}
        >
          Dates
        </span>
        {dateDrop && (
          <div className="lg:flex flex-row  justify-between item-center">
            {items.map((item, index) => (
              <div
                key={index}
                style={{ color: item.color }}
                className={`${
                  item.text === activeDay
                    ? "text-blue-500 border-b-4 border-blue-500"
                    : "text-gray-500"
                } cursor-pointer inline-block p-2 w-full lg:w-[45%]`}
                onClick={() => handleClick(index)}
              >
                <span className="text-sm">{item.text}</span>
              </div>
            ))}
          </div>
        )}

        <div className="hidden lg:flex justify-evenly item-center lg:w-[30%]"></div>

        <div>
          <Link to="/admin/home/AddTask">
            <button
              type="button"
              className="bg-[#93002F] py-3 px-5 lg:w-full xs:w-fit text-white rounded-lg flex gap-2 hover:scale-90 transition-all"
            >
              <div>
                <img
                  src={add}
                  width={20}
                  height={20}
                  alt="image"
                  className=" pt-[0.1rem]"
                />
              </div>
              Add a new event
            </button>
          </Link>
        </div>
      </div>

      <div className="mt-7">
        {event?.length > 0 ? (
          <TableComponent
            className="rounded-none rounded-t-0"
            DATA={event || []}
            COLUMNS={EVENT_COLUMNS}
            isNotPaginanated={true}
            page={page}
            setPage={setPage}
          />
        ) : event?.length < 1 ? (
          <p className="text-center text-xs">
            <EmptyStudentData
              noStudentRegister
              textMessage="No event available"
            />
          </p>
        ) : (
          <div className="w-full mb-3 px-8 flex justify-between items-center">
            <Skeleton
              variant="rectangular"
              height={50}
              className="w-[50px] rounded-full mb-4"
            />
            <div className="w-[80%]">
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-full mb-4"
              />
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-[80%] mb-4"
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        loading={loading}
        handleClick={() => handleDelete(data?.id)}
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        title={
          "You are about to delete this event. Click cancel to cancel this action or proceed with continue"
        }
      />
    </section>
  );
};

export default AdminManageMent;

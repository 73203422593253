import * as React from "react";
import { useTable } from "react-table";
import { Nosubject } from "../../../../assets";
import Button from "../../../../components/button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

interface emptyProps {
  noStudentRegister?: boolean;
  title?: string;
  onClick?: string;
  textMessage?: string;
}

const EmptyStudentData = (props: emptyProps) => {
  const darkMode = useSelector(selectDarkmode);
  return (
    <div>
      <div>
        <div
          className={`flex flex-col justify-center items-center border ${
            darkMode ? "border-gray-700" : ""
          } rounded-lg h-ful py-10`}
        >
          <img src={Nosubject} alt="" width={300} height={70} />
          <span className="font-semibold">Oops,</span>
          <p className="text-center max-w-md">
            {props.textMessage
              ? props.textMessage
              : `Don’t panic, kindly click on the button below to add new student. Students list will be
						visible here after being added.`}
          </p>
          {!props?.noStudentRegister && (
            <Link to="/admin/students/add_students">
              <Button
                iconClassName="h-[0.9rem] w-3"
                title={"Add new student"}
                bgColor={"bg-other"}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-5 rounded-lg bg-brand w-[25rem] my-9  "}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmptyStudentData;

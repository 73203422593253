import { API } from '@/config';

export async function getWard() {
	const response = await API.get(`parent/wards`);
	return response.data;
}

export async function showWard(id: any) {
	const response = await API.get(`/parent/wards/${id}`);
	return response.data;
}
export async function getClassManagagementAPI(date: any) {
	const response = await API.get(`parent/class-management?class_date=${date}`);
	return response.data;
}

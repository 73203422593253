import { Formik } from "formik";

import { useMutation } from "react-query";

import toast from "react-hot-toast";
import { ChangePassword } from "@/services/Admin/changepassword";
import Input from "../Input";
import Button from "@/components/button";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const PasswordChange = () => {
  const changepasswordMutation = useMutation(ChangePassword);
  const darkmode = useSelector(selectDarkmode);

  return (
    <div
      className={`shadow-box p-8 rounded-xl w-full lg:w-[50%] mx-auto ${
        darkmode ? "bg-black" : ""
      } h-full flex flex-col pb-6 `}
    >
      <p className={`font-bold  ${darkmode ? "text-gray-300" : "text-base"}`}>
        Change password
      </p>
      <Formik
        initialValues={{
          old_password: "",
          new_password: "",
          confirm_passsword: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const formData = {
            old_password: values.old_password,
            new_password: values.new_password,
            confirm_password: values.confirm_passsword,
          };
          try {
            const res = await changepasswordMutation.mutateAsync(formData);

            if (res) {
              resetForm();
              toast.success("password changed Successfully");
            }
          } catch (error: any) {
            toast.error(error?.response?.data?.message);
          }
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form className="flex flex-col pt-3 md:pt-8" onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className={`text-sm ${
                  darkmode ? "text-gray-300" : "text-black"
                }`}
              >
                Current Password
              </label>
              <Input
                type="password"
                placeholder="Enter current password"
                name={"old_password"}
                className={`${
                  darkmode
                    ? "bg-dark-200 border-none placeholder:text-gray-300"
                    : ""
                }`}
              />
            </div>
            <div className="flex flex-col mb-3">
              <label
                htmlFor="password"
                className={`text-sm ${
                  darkmode ? "text-gray-300" : "text-black"
                }`}
              >
                New Password
              </label>
              <Input
                type="password"
                placeholder="password"
                isPassword={true}
                name={"new_password"}
                className={`${
                  darkmode
                    ? "bg-dark-200 border-none placeholder:text-gray-300"
                    : ""
                }`}
              />
              <small
                className={` font-bold ${
                  darkmode ? "text-gray-300" : "text-black"
                }`}
              >
                Password must be at least 8 characters.
              </small>
            </div>
            <div className="flex flex-col mb-3">
              <label
                htmlFor="password"
                className={`text-sm ${
                  darkmode ? "text-gray-300" : "text-black"
                }`}
              >
                Confirm Password
              </label>
              <Input
                type="password"
                placeholder="password"
                isPassword={true}
                name={"confirm_password"}
                className={`${
                  darkmode
                    ? "bg-dark-200 border-none placeholder:text-gray-300"
                    : ""
                }`}
              />
              <small
                className={`font-bold ${
                  darkmode ? "text-gray-300" : "text-black"
                }`}
              >
                Password must be at least 8 characters.
              </small>
            </div>

            <Button
              iconClassName="h-[0.9rem] w-3"
              isLoading={isSubmitting}
              title={"Change password"}
              bgColor={"bg-brand"}
              onClick={() => {}}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full mt-4"}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordChange;

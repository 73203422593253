import { API } from '../../config';

interface AttendanceTypes {
	id: null | number | string;
	date?: any | null;
}

export async function getDashboardAPI() {
	const response = await API.get(`teacher/dashboard`);
	return response.data;
}

export async function getTeacherAttendanceAPI(data: AttendanceTypes) {
	const response = await API.get(
		`teacher/class-attendances?class_id=${data?.id}&attendance_date=${data?.date}`
	);
	return response.data;
}
export async function markAttendanceAPI(data: any) {
	const response = await API.post(`teacher/class-attendances`, data);
	return response.data;
}

export async function getClassManagagementAPI(date: any) {
	const response = await API.get(`teacher/class-management?class_date=${date}`);
	return response.data;
}

export async function getClassAPI(section: any) {
	const response = await API.get(`teacher/classes?section=${section}`);
	return response.data;
}
export async function getSubject(section: any) {
	const response = await API.get(`teacher/subjects/classes`);
	return response.data;
}
export async function sendReport(data: any) {
	const response = await API.post(`teacher/course-reports`, data);
	return response.data;
}
export async function getCourseReportAPI(data: any) {
	const response = await API.get(
		`teacher/course-reports?class_id=${data.class_id}&subject_id=${data.subject_id}&term=${data.term}`
	);
	return response.data;
}

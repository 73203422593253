import React, { useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { add, exports, filter, print } from "../../../assets";
import { Link, useNavigate } from "react-router-dom";

import { TableComponent } from "../../../components/table/Table";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { useMutation, useQuery } from "react-query";
import { deleteEvents, getEventsApi } from "../../../services/Admin/dashboard";
import DropDownV2 from "../../../components/menu-dropdown/dropDownV2";
import moment from "moment";
import toast from "react-hot-toast";
import { Skeleton } from "@mui/material";
import EmptyEvent from "./contents/EmptyEvent";
// import EmptyEvent from './contents/EmptyEvent';

function Calender() {
  const navigate = useNavigate();
  const mobile = window.innerWidth <= 768;

  const [page, setPage] = useState(1);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const deleteEventMutation = useMutation(deleteEvents);
  const params = {
    page,
  };

  const { data: getAllEvents, refetch } = useQuery(["event", params], () =>
    getEventsApi(params)
  );

  const EVENT_COLUMNS = [
    {
      Header: "EVENT NAME",
      accessor: "title",
    },
    {
      Header: "DESCRIPTION",
      Cell: ({ cell: { row } }: any) => {
        return (
          <div>
            <div className="overflow-ellipsis whitespace-nowrap">
              <p>
                {row.original?.description.length > 49
                  ? `${row.original?.description?.slice(0, 50)} ...`
                  : row.original?.description}
              </p>
            </div>
          </div>
        );
      },
    },

    {
      Header: "LOCATION",
      accessor: "location",
    },

    {
      Header: "EVENT DATE",
      accessor: "start_date",
      Cell: ({ cell: { row } }: any) => {
        return moment(row.values.start_date).format("MMM Do YYYY");
      },
    },
    {
      Header: "ACTION",
      accessor: "",
      Cell: ({ row }: any) => (
        <div className="relative">
          <DropDownV2
            handleDelete={() => {
              setConfirmationModal(true);
              setData(row?.original);
            }}
            handleEdit={() => {
              navigate("/calender-events/edit-events", {
                state: { data: row?.original },
              });
            }}
            editText="event"
            viewText="event"
          />
        </div>
      ),
    },
  ];

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      const res: any = await deleteEventMutation?.mutateAsync(id);
      if (res) {
        refetch();
        setLoading(false);
        toast.success(res?.message);
        setConfirmationModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <main className="lg:px-9 xs:px-4 mt-7">
      <div className="flex justify-between">
        <DashboardHeader
          title="Calendar events 🎈"
          subtitle="Create and manage school calendar events here"
        />
        <div className="flex lg:flex-row xs:flex-col lg:gap-7 xs:gap-0">
          <div className="pt-3">
            <Link to="/admin/calender-events/add_event">
              <button className="bg-other w-fit h-fit flex lg:px-8 xs:px-3 py-3 lg:gap-3 xs:gap-[1.5px] rounded-lg hover:scale-90 transition-all">
                {mobile ? null : (
                  <img
                    src={add}
                    alt=""
                    width={20}
                    height={20}
                    className="lg:pt-1 xs:pt-[1.5px]"
                  />
                )}
                <span className="lg:text-lg xs:text-sm text-white">
                  Create new Event
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-7">
        {getAllEvents?.data?.length > 0 ? (
          <TableComponent
            className="rounded-none rounded-t-0"
            DATA={getAllEvents?.data}
            COLUMNS={EVENT_COLUMNS}
            page={page}
            setPage={setPage}
          />
        ) : getAllEvents?.data?.length < 1 ? (
          <div className="text-center text-xs">
            <EmptyEvent />

            {page > 1 && (
              <div className="flex justify-end items-center w-full px-2 lg:px-4 py-2">
                <div className="flex justify-center items-center text-xs rounded-sm py-1  w-full ">
                  {page > 1 && (
                    <button
                      className=" cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand"
                      onClick={() => handlePrevious()}
                      // disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                  )}

                  <span className="mx-4 text-xs">
                    Page <strong>{page}</strong>{" "}
                  </span>
                  {data?.length > 14 && (
                    <button
                      className="cursor-pointer px-4 py-2 w-40 border-2 border-brand rounded-lg text-brand"
                      onClick={() => handleNext()}
                      // disabled={!canNextPage}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full mb-3 px-8 flex justify-between items-center">
            <Skeleton
              variant="rectangular"
              height={50}
              className="w-[50px] rounded-full mb-4"
            />
            <div className="w-[80%]">
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-full mb-4"
              />
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-[80%] mb-4"
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        loading={loading}
        handleClick={() => handleDelete(data?.id)}
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        title={
          "You are about to delete this event. Click cancel to cancel this action or proceed with continue"
        }
      />
    </main>
  );
}

export default Calender;

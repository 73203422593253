import React from "react";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import { success } from "../../../../assets";
import Button from "../../../../components/button";

interface Props {
  isOpen: () => void;
  isClose: () => void;
}
const DeletedSuccess = ({ isOpen, isClose }: Props) => {
  return (
    <Modal maxWidth="xs" open={isOpen} close={isClose}>
      <div className="justify-center items-center flex flex-col gap-5">
        <img src={success} alt="success" width={100} height={70} />
        <Typography textStyle="text-center lg:px-8 xs:px-4">
          <div>
            <span className="text-2xl font-semibold">Action Successful</span>
            <p>User details have been deleted successfully from the system.</p>
          </div>
        </Typography>
        <Button
          iconClassName="h-[0.9rem] w-3"
          // isLoading={loading}
          title={"Close"}
          bgColor={"bg-other"}
          onClick={isClose}
          hoverColor={""}
          textColor={"text-white"}
          className={
            "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem]   mt-4 mb-8"
          }
        />
      </div>
    </Modal>
  );
};

export default DeletedSuccess;

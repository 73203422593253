import { API } from "../../config";

export async function getDashboardAPI() {
  const response = await API.get(`admin/dashboard`);
  return response.data;
}
// event function
export async function createScheduleAPI(data: any) {
  const response = await API.post("admin/events", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}
export async function editScheduleAPI(data: any, id: any) {
  const response = await API.post(`admin/events/${id}/update`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function deleteEvents(data: any) {
  const response = await API.delete(`admin/events/${data}`);
  return response.data;
}

export async function getEventsApi(params: any) {
  const response = await API.get(`admin/events?page=${params?.page}`);
  return response.data;
}

export async function getRoles() {
  const response = await API.get("roles");
  return response.data;
}

export async function getClass(data?: any) {
  const response = await API.get(`admin/classes?section=${data}`);
  return response.data;
}
export async function getAllClass() {
  const response = await API.get(`admin/classes`);
  return response.data;
}
export async function showClassClass(id: any) {
  const response = await API.get(`admin/classes/${id}`);
  return response.data;
}
export async function getStudent(data?: any) {
  const response = await API.get(`admin/students/${data}`);
  return response.data;
}

export async function getParent(data?: any) {
  const response = await API.get(`admin/parent`);
  return response.data;
}
export async function createClass(data: any) {
  const response = await API.post("admin/classes", data);
  return response.data;
}

export async function editClass(data: any) {
  const response = await API.post(`admin/classes/${data}/update`, data);
  return response.data;
}

export async function deleteClass(data: any) {
  const response = await API.delete(`admin/classes/${data}`);
  return response.data;
}

export async function deleteTeacher(data: any) {
  const response = await API.delete(`delete-teacher/${data}`);
  return response.data;
}

export async function editStudent(data: any) {
  const response = await API.post(`admin/student/${data}/update`, data);
  return response.data;
}
export async function deleteStudent(data: any) {
  const response = await API.delete(`delete-student/${data}`);
  return response.data;
}
export async function promoteStudent(data: any) {
  const response = await API.post(`admin/promotions`, data);
  return response.data;
}

export async function getPromoted(id: any) {
  const response = await API.get(`/admin/promotions?class_id=${id}`);
  return response.data;
}
export async function getSection() {
  const response = await API.get("classes/sections");
  return response.data;
}
export async function getCategories() {
  const response = await API.get("classes/categories");
  return response.data;
}

export async function getUsers(data?: any) {
  const response =
    data?.isNotPaginated === true
      ? await API.get(`users?role=${data?.role}&should_paginate=0`)
      : await API.get(`users?role=${data?.role}&page=${data?.page}`);
  return response.data;
}
export async function getAllUsers(data?: any) {
  const response = await API.get(`users?role=${data}&per_page=10000`);
  return response.data;
}
export async function getStudentByClassId(id?: any) {
  const response = await API.get(`users?role=STUDENT&class_id=${id}`);
  return response.data;
}
export async function getPermissions(data?: any) {
  const response = await API.get(`permissions?role=${data}`);
  return response.data.data;
}

export async function sendAttendance(data?: any) {
  const response = await API.post(`admin/class-attendances`, data);
  return response.data;
}
export async function getAttendance(data?: any) {
  const response = await API.get(
    `admin/class-attendances?class_id=${data?.id}&attendance_date=${data?.date}`
  );
  return response.data;
}
export async function createTimetable(data?: any) {
  const response = await API.post(`admin/class-time-tables`, data);
  return response.data;
}
export async function getTimetable(data?: any) {
  const response = await API.get(
    `/admin/class-time-tables?class_id=${data?.class_id}&class_date=${data?.class_date}`
  );
  return response.data;
}
export async function showUser(id?: any) {
  const response = await API.get(`/users/${id}/show`);
  return response.data;
}

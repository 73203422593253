import {
  ADMIN_CLASSES,
  ADMIN_CLASSES_WHITE,
  ADMIN_HOME_BLACK,
  ADMIN_HOME_WHITE,
  ADMIN_PROFILE,
  ADMIN_PROFILE_WHITE,
  ADMIN_SETTINGS,
  ADMIN_STUDENT,
  ADMIN_SUBJECT,
  ADMIN_SUBJECT_WHITE,
  ADMIN_TEACHER,
  ADMIN_TEACHER_WHITE,
  ADMIN_TIMETABLE,
  ADMIN_TIMETABLE_WHITE,
  ADMIN_USER_ROLES,
  ADMIN_USER_ROLES_WHITE,
  ANNOUNCEMENT_BLACK,
  ANNOUNCEMENT_RED,
  BARCHART_BLACK,
  BARCHART_RED,
  BARCHART_WHITE,
  BUILDING_1,
  BUILDING_2,
  BUILDING_3,
  BUILDING_4,
  BUILDING_5,
  BUILDING_6,
  CART_BLACK,
  CART_RED,
  CHART,
  EXCHANGE_BLACK,
  EXCHANGE_RED,
  PAYMENT_LINK_BLACK,
  PAYMENT_LINK_RED,
  PIE_CHART_BLACK,
  PIE_CHART_RED,
  TRANSACTION_BLACK,
  TRANSACTION_HORIZONTAL_BLACK,
  TRANSACTION_HORIZONTAL_RED,
  TRANSACTION_RED,
  WALLET_BLACK,
  WALLET_RED,
  award,
  award_brand,
  book_2,
  book_2_brand,
  calendar3,
  calender,
  calender_brand,
  home,
  home_brand,
  layer_white,
  setting,
  setting_brand,
  user,
  user_brand,
} from "../../assets";

export const StudentBarData = [
  {
    id: "1",
    title: "Dashboard",

    path: "/student/home",
    imageURL: home,
    image_active: home_brand,
    alt: "",
  },
  {
    id: "2",
    title: "Subjects",

    path: "/student/subject",
    imageURL: book_2,
    image_active: book_2_brand,
    alt: "",
  },
  {
    id: "3",
    title: "Events",

    path: "/student/events",
    imageURL: calender,
    image_active: calender_brand,
    alt: "",
  },
  {
    id: "4",
    title: "My Results",

    path: "/student/my-results",
    imageURL: award,
    image_active: award_brand,
    alt: "",
  },

  {
    id: "5",
    title: "Profile",

    path: "/student/profile",
    imageURL: user,
    image_active: user_brand,
    alt: "",
  },
  {
    id: "6",
    title: "Settings",
    path: "/student/settings",
    imageURL: setting,
    image_active: setting_brand,
    alt: "",
  },
];
export const TeacherBarData = [
  {
    id: "1",
    title: "Dashboard",

    path: "/teacher",
    imageURL: home,
    image_active: home_brand,
    alt: "",
  },
  {
    id: "11",
    title: "Attendance",

    path: "/teacher/attendance",
    imageURL: home,
    image_active: home_brand,
    alt: "",
  },
  {
    id: "2",
    title: "Students",

    path: "/teacher/students",
    imageURL: book_2,
    image_active: book_2_brand,
    alt: "",
  },
  {
    id: "3",
    title: "Classes Management",

    path: "/teacher/class-management",
    imageURL: calender,
    image_active: calender_brand,
    alt: "",
  },
  {
    id: "4",
    title: "Course Report",

    path: "/teacher/course-report",
    imageURL: award,
    image_active: award_brand,
    alt: "",
  },

  {
    id: "5",
    title: "Profile",

    path: "/teacher/profile",
    imageURL: user,
    image_active: user_brand,
    alt: "",
  },
  {
    id: "6",
    title: "Settings",
    path: "/teacher/settings",
    imageURL: setting,
    image_active: setting_brand,
    alt: "",
  },
];
export const ParentBarData = [
  {
    id: "1",
    title: "My Ward",

    path: "/parent/myward/home",
    imageURL: home,
    image_active: home_brand,
    alt: "",
  },
  {
    id: "2",
    title: "Class Management",

    path: "/parent/class-managements",
    imageURL: calender,
    image_active: calender_brand,
    alt: "",
  },

  {
    id: "5",
    title: "Profile",

    path: "/parent/profile",
    imageURL: user,
    image_active: user_brand,
    alt: "",
  },
  {
    id: "6",
    title: "Settings",
    path: "/parent/settings",
    imageURL: setting,
    image_active: setting_brand,
    alt: "",
  },
];
export const AdminBarData = [
  {
    id: "1",
    title: "Dashboard",

    path: "/admin/dashboard",
    imageURL: ADMIN_HOME_BLACK,
    image_active: ADMIN_HOME_WHITE,
    alt: "",
  },
  {
    id: "2",
    title: "Attendance",
    dropdown: [
      {
        id: 21,
        title: "Nursery Classes",
        path: "/admin/attendance/classes/nursery",
        img: BUILDING_5,
        img_active: BUILDING_1,
      },
      {
        id: 22,
        title: "Primary Classes",
        path: "/admin/attendance/classes/primary",
        img: BUILDING_6,
        img_active: BUILDING_4,
      },
      {
        id: 23,
        title: "Secondary Classes",
        path: "/admin/attendance/classes/Secondary",
        img: BUILDING_2,
        img_active: BUILDING_3,
      },
    ],

    imageURL: BARCHART_BLACK,
    image_active: BARCHART_WHITE,
    alt: "",
  },

  {
    id: "3",
    title: "Calender Events",

    path: "/admin/calender-events",
    imageURL: calendar3,
    image_active: calender,
    alt: "",
  },
  {
    id: "4",
    title: "Timetable",
    dropdown: [
      {
        id: 21,
        title: "Nursery Classes",
        path: "/admin/time-table/sections/nursery-table",
        img: BUILDING_5,
        img_active: BUILDING_1,
      },
      {
        id: 22,
        title: "Primary Classes",
        path: "/admin/time-table/sections/primary-table",
        img: BUILDING_6,
        img_active: BUILDING_4,
      },
      {
        id: 23,
        title: "Secondary Classes",
        path: "/admin/time-table/sections/secondary-table",
        img: BUILDING_2,
        img_active: BUILDING_3,
      },
    ],
    path: "/admin/timetable",
    imageURL: ADMIN_TIMETABLE,
    image_active: layer_white,
    alt: "",
  },
  {
    id: "5",
    title: "Classes",
    path: "/admin/classes",
    imageURL: ADMIN_CLASSES,
    image_active: ADMIN_CLASSES_WHITE,
    alt: "",
  },
  {
    id: "15",
    title: "Promote",
    path: "/admin/promote",
    imageURL: ADMIN_CLASSES,
    image_active: ADMIN_CLASSES_WHITE,
    alt: "",
  },
  {
    id: "6",
    title: "Subjects",
    path: "/admin/subjects",
    imageURL: ADMIN_SUBJECT,
    image_active: ADMIN_SUBJECT_WHITE,
    alt: "",
  },
  {
    id: "7",
    title: "Students",
    path: "/admin/students",
    imageURL: ADMIN_STUDENT,
    image_active: ADMIN_SUBJECT_WHITE,
    alt: "",
  },
  {
    id: "8",
    title: "Teachers",
    path: "/admin/teachers",
    imageURL: ADMIN_TEACHER,
    image_active: ADMIN_TEACHER_WHITE,
    alt: "",
  },
  {
    id: "9",
    title: "Users and Roles",
    path: "/admin/users-and-roles",
    imageURL: ADMIN_USER_ROLES,
    image_active: ADMIN_USER_ROLES_WHITE,
    alt: "",
  },
  {
    id: "10",
    title: "Profile",

    path: "/admin/profile",
    imageURL: ADMIN_PROFILE,
    image_active: ADMIN_PROFILE_WHITE,
    alt: "",
  },
  {
    id: "11",
    title: "Settings",
    path: "/admin/settings",
    imageURL: ADMIN_SETTINGS,
    image_active: ADMIN_SETTINGS,
    alt: "",
  },
];
export const MainPages = [
  {
    id: "1",
    title: "Dashboard",
    imageURL: PIE_CHART_BLACK,
    image_active: PIE_CHART_RED,
    alt: "pie...",
    path: "/",
  },
  {
    id: "2",
    title: "Balances",
    imageURL: WALLET_BLACK,
    image_active: WALLET_RED,
    alt: "wallet...",
    path: "/balance",
  },
  {
    id: "3",
    title: "Transactions",
    imageURL: TRANSACTION_BLACK,
    image_active: TRANSACTION_RED,
    alt: "transaction...",
    path: "/transaction",
  },
  {
    id: "4",
    title: "Analytics",
    imageURL: BARCHART_BLACK,
    image_active: BARCHART_RED,
    alt: "barchart...",
    path: "/announcement",
  },
  {
    id: "5",
    title: "Marketing",
    imageURL: ANNOUNCEMENT_BLACK,
    image_active: ANNOUNCEMENT_RED,
    alt: "claim...",
    path: "/marketing",
  },
  {
    id: "6",
    title: "Exchange rates",
    imageURL: EXCHANGE_BLACK,
    image_active: EXCHANGE_RED,
    alt: "exchange...",
    path: "/exchange-rates",
  },
];
export interface SidebarDataTypes {
  id: string;
  title: string;
  imageURL?: string;
  image_active?: string;
  alt?: string;
  path?: string;
}

export const acceptPayments = [
  {
    id: "1",
    title: "Checkout",
    imageURL: CART_BLACK,
    image_active: CART_RED,
    alt: "checkout...",
    path: "/checkout",
  },
  {
    id: "2",
    title: "Payment links",
    imageURL: PAYMENT_LINK_BLACK,
    image_active: PAYMENT_LINK_RED,
    alt: "payment...",
    path: "/payment-links",
  },
];
export const sendPayments = [
  {
    id: "1",
    title: "Wire",
    imageURL: TRANSACTION_HORIZONTAL_BLACK,
    image_active: TRANSACTION_HORIZONTAL_RED,
    alt: "wire..",
    path: "/wire",
  },
];

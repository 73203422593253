import React, { useEffect, useRef, useState } from "react";
import { DARK_MODE_ARROW, leftArrow, success } from "../../../../assets";
import DashboardHeader from "../../../../components/headers/dashboard-header";
import Button from "../../../../components/button";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import Input from "../../../../components/form/Input";
import Select from "../../../../components/form/Select";
import MultipleSelectCheckmarksV2 from "../../../../components/Multiselect/variant";
import { useQuery } from "react-query";
import {
  getCategories,
  getSection,
  getUsers,
  showClassClass,
} from "../../../../services/Admin/dashboard";
import {
  getAllSubject,
  getClassSubject,
} from "../../../../services/Admin/subject";
import { updateClassAPI } from "../../../../services/profile";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";
import { MultiSelect } from "react-multi-select-component";
import { Skeleton } from "@mui/material";

const EditClass = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [inputModal, setInputModal] = useState(false);
  const [selectStr, setSelectStr] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { data: subjectData } = useQuery(["subject", page], () =>
    getAllSubject(page)
  );
  const { data: categoriesData } = useQuery("categories", getCategories);
  const { data: sectionData } = useQuery("sections", getSection);
  const navigate = useNavigate();
  const data = useLocation()?.state?.data;

  const params = {
    page,
    role: "TEACHER",
    isNotPaginated: true,
  };
  const { data: userData, refetch } = useQuery(["TEACHER", params], () =>
    getUsers(params)
  );
  const { data: classData, refetch: fetchClass } = useQuery(
    ["showclass", data?.id],
    () => showClassClass(data?.id)
  );
  const { data: subjectD } = useQuery(["sp", { id: data?.id, page }], () =>
    getClassSubject({ id: data?.id, page })
  );
  const [subjectValue, setSubjectValue] = useState<any>([]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddStr = () => {
    if (inputValue.trim() !== "") {
      setSelectStr([...selectStr, inputValue]);
      setInputValue("");
    }
  };

  const darkMode = useSelector(selectDarkmode);
  const options = subjectData?.data?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (subjectD?.data?.length > 0) {
      const previousSelectSubject = subjectD?.data
        ?.filter((item: any) => item?.subject?.id != null)
        .map((item: any) => ({
          label: item?.subject?.name,
          value: item?.subject?.id,
        }));

      setSubjectValue(previousSelectSubject);
    }
  }, [subjectD]);

  return (
    <section className="mt-7 lg:px-9 xs:px-4">
      {classData ? (
        <>
          <div className="flex gap-5">
            <Link to="/admin/classes">
              {darkMode ? (
                <div className="w-10 h-10 flex justify-center items-center rounded-full bg-black">
                  <img src={DARK_MODE_ARROW} alt="" className="w-4" />
                </div>
              ) : (
                <img src={leftArrow} alt="arrow_left" />
              )}
            </Link>
            <DashboardHeader
              title="Classes"
              subtitle="Create and manage classes here"
            />
          </div>
          <div
            className={`mt-9 lg:px-10 px-4 w-full border rounded-lg  py-9 flex flex-col ${
              darkMode ? " bg-dark-200 border-gray-700" : ""
            }`}
          >
            <Formik
              initialValues={{
                name: classData?.data?.name ?? "",
                section: classData?.data?.section ?? "",
                category: classData?.data?.category ?? "",
                teacher_id: classData?.data?.teacher?.id ?? "",
                subject_ids: "",
              }}
              // validationSchema={loginSchema}
              // admin/classes/15/update
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const formData = new FormData();

                formData.append("name", values.name);
                formData.append("section", values.section);
                formData.append("category", values.category);
                if (values.teacher_id) {
                  formData.append("teacher_id", values.teacher_id);
                }

                // Iterate through the arrays and append data to formData
                for (let i = 0; i < subjectValue.length; i++) {
                  formData.append(`subject_ids[${i}]`, subjectValue[i]?.value);
                }

                try {
                  const res = await updateClassAPI(formData, data?.id);

                  if (res?.data) {
                    setOpen(true);
                  }
                } catch (err: any) {
                  if (err?.response?.data.message) {
                    toast.error(err?.response?.data.message, {
                      duration: 3000,
                    });
                  } else {
                    toast.error("Something went wrong. Try again later", {
                      duration: 3000,
                    });
                  }
                }
              }}
            >
              {({
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form>
                  <div className="pb-7 py-6">
                    <span className="font-medium text-lg">
                      Personal information
                    </span>

                    <div className="flex justify-between items-start flex-wrap">
                      <div className=" w-full lg:w-[49%]">
                        <label htmlFor="text" className="text-gray-400 text-xs">
                          Class Name
                        </label>
                        <Input
                          name={"name"}
                          placeholder={"Full name"}
                          className={`${
                            darkMode
                              ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                              : ""
                          }`}
                        />
                      </div>
                      <div className=" w-full lg:w-[49%]">
                        <label htmlFor="text" className="text-gray-400 text-xs">
                          Class section
                        </label>
                        <Select
                          name="section"
                          className={`${
                            darkMode
                              ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                              : ""
                          }`}
                        >
                          <option value="">Select a section</option>
                          {sectionData?.data?.map((item: string) => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </div>
                      <div className=" w-full lg:w-[49%]">
                        <label htmlFor="text" className="text-gray-400 text-xs">
                          Class category
                        </label>

                        <Select
                          name="category"
                          className={`${
                            darkMode
                              ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                              : ""
                          }`}
                        >
                          <option value="">Select a section</option>
                          {categoriesData?.data?.map((item: string) => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </div>

                      <div className=" w-full lg:w-[49%]">
                        <label htmlFor="text" className="text-gray-400 text-xs">
                          Subject
                        </label>

                        {/* {subjectData?.data?.length > 0 && (
                        <MultipleSelectCheckmarksV2
                          data={subjectData?.data}
                          personName={subjectValue}
                          setPersonName={setSubjectValue}
                        />
                      )} */}

                        {subjectData !== undefined &&
                          options?.length > 0 &&
                          subjectData?.data?.length > 0 && (
                            <div className="mt-2">
                              <MultiSelect
                                options={options}
                                value={subjectValue}
                                onChange={setSubjectValue}
                                labelledBy="Select"
                              />
                            </div>
                          )}
                      </div>

                      <div className=" w-full lg:w-[49%]">
                        <label htmlFor="text" className="text-gray-400 text-xs">
                          Teachers
                        </label>

                        {userData?.data?.length > 0 && (
                          <Select
                            name="teacher_id"
                            className={`${
                              darkMode
                                ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                                : ""
                            }`}
                          >
                            <option value="">Select a teachers</option>
                            {userData?.data?.map((item: any) => (
                              <option
                                value={item.id}
                              >{`${item?.first_name} ${item?.last_name}`}</option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mt-6">
                    <Button
                      iconClassName="h-[0.9rem] w-3"
                      isLoading={isSubmitting}
                      title={"Update Class"}
                      bgColor={"bg-other"}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={
                        "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  "
                      }
                    />
                  </div>
                </Form>
              )}
            </Formik>

            {inputModal && (
              <Modal
                open={() => setInputModal(true)}
                close={() => setInputModal(false)}
                maxWidth="xs"
              >
                <div className="flex flex-col justify-center items-center gap-3 px-9 pb-9">
                  <span className="text-center text-2xl">Tag a subject</span>
                  <input
                    type="text"
                    className="border px-3 py-4 w-full rounded-lg outline-none"
                    placeholder="Enter tag"
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                  <button
                    type="button"
                    onClick={handleAddStr}
                    className="border h-fit w-fit rounded-lg px-3 py-2 hover:scale-90 transition-all"
                  >
                    {!inputValue ? "" : "Add"}
                  </button>
                </div>
              </Modal>
            )}
          </div>
          {open && (
            <Modal
              maxWidth="xs"
              open={() => setOpen(true)}
              close={() => setOpen(false)}
            >
              <div className="flex flex-col justify-center items-center gap-6">
                <img src={success} alt="good" width={100} height={70} />
                <Typography textStyle="text-center">
                  <div>
                    <span className="text-2xl font-semibold">
                      Action Successful
                    </span>
                    <p>Class has been updated successfully</p>
                  </div>
                </Typography>
                <Button
                  iconClassName="h-[0.9rem] w-3"
                  // isLoading={loading}
                  title={"Close"}
                  bgColor={"bg-other"}
                  onClick={() => {
                    setOpen(false);
                    navigate("/admin/classes");
                  }}
                  hoverColor={""}
                  textColor={"text-white"}
                  className={
                    "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  "
                  }
                />
              </div>
            </Modal>
          )}
        </>
      ) : (
        <div className="w-full mb-3 px-8 flex justify-between items-center">
          <Skeleton
            variant="rectangular"
            height={50}
            className="w-[50px] rounded-full mb-4"
          />
          <div className="w-[80%]">
            <Skeleton
              variant="rectangular"
              height={20}
              className="w-full mb-4"
            />
            <Skeleton
              variant="rectangular"
              height={20}
              className="w-[80%] mb-4"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default EditClass;

import React from 'react';
import { useTable } from 'react-table';
import { Nosubject } from '../../../../assets';
import { selectDarkmode } from '@/states/slices/authReducer';
import { useSelector } from 'react-redux';

const EmptyEvent = () => {

	const darkMode = useSelector(selectDarkmode);

	return (
		<div>
			<div>
			
				<div
					className={`flex flex-col justify-center rounded-md items-center border h-full gap-3 py-5 ${
						darkMode ? 'border-gray-700' : 'border-gray-300'
					}`}
				>
					<img src={Nosubject} alt='' width={200} height={50} />
					<span className='font-semibold'>Oops, no item here.</span>
					<p className='text-center max-w-md'>
						Don’t panic, kindly click on the button below to add new event. Events list will be
						visible here after being added.
					</p>
		
				</div>
			</div>
		</div>
	);
};

export default EmptyEvent;

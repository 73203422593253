import { useSelector } from "react-redux";
import DropDownV2 from "../../../../components/menu-dropdown/dropDownV2";
import { selectDarkmode } from "@/states/slices/authReducer";

const ClassCard = (props?: any) => {
  const { data, handleDelete, handleEdit, handleView } = props;
  const darkMode = useSelector(selectDarkmode);

  return (
    <>
      <div
        className={`w-full lg:w-[100%] relative max-h-full hover:scale-110 transform ease-in-out duration-500   ${
          darkMode ? "bg-pink bg-opacity-75 " : "bg-opacity-5 bg-peach"
        }  px-4 py-7 flex flex-col gap-20 rounded-lg shadow-md`}
      >
        <div className="justify-between flex">
          <div>
            <p
              className={`text-xl font-medium  ${
                darkMode ? "text-gray-300" : "text-[#333333]"
              }`}
            >
              {data?.name}
            </p>

            <small
              className={`text-sm font-light mt-2 text-[#333333]  ${
                darkMode ? "text-gray-300" : "text-[#333333]"
              }`}
            >
              {data?.category}
            </small>
          </div>
          <DropDownV2
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleView={handleView}
            editText="class"
            viewText="class"
          />
        </div>
        <div>
          <div className="flex items-center justify-center h-[40px] w-[40px] bg-orange-500 rounded-full border-spacing-3 border-4 border-white text-sm text-white">
            {data?.students_count ?? 0}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassCard;

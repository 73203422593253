import React, { useState } from "react";
import DashboardHeader from "../../../../components/headers/dashboard-header";
import { calendar3, calender_brand } from "../../../../assets";
import { getClass } from "@/services/Admin/dashboard";
import { useQuery } from "react-query";
import Calendar from "@/components/calender";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const Primary_Section = () => {
  const [selectedDate] = useState(new Date());
  const { data: classData, refetch } = useQuery(["class", "PRIMARY"], () =>
    getClass("PRIMARY")
  );
  const [selctedClass, setSelectedClass] = useState<number | null>(
    classData?.data[0]
  );
  const handleSelected = (index: number) => {
    setSelectedClass(index);
  };

  const darkMode = useSelector(selectDarkmode);
  return (
    <main className="mt-6 lg:px-8 px-1">
      <DashboardHeader
        title="Timetable 📄"
        subtitle="Manage the school’s schedule and timetable here"
      />
      <div className="flex justify-between items-center flex-wrap mt-5">
        <div className="flex gap-5 mb-4">
          {classData?.data.map((label: any, i: number) => (
            <span
              key={i}
              onClick={() => handleSelected(label)}
              className={`${
                selctedClass === label
                  ? `border-b-4  pb-2 ${
                      darkMode
                        ? "border-red-500 text-red-500"
                        : "border-blue-400 text-blue-400"
                    }`
                  : ""
              }
              } cursor-pointer transition-all duration-300`}
            >
              {label?.name}
            </span>
          ))}
        </div>
        <div
          className={`flex gap-3  ${
            darkMode ? "bg-black text-gray-300 border-none" : "bg-[#F7F7F7]"
          } py-3 px-4  rounded-lg border border-gray-50`}
        >
          <span>{`${selectedDate.toLocaleString("default", {
            month: "long",
          })}, ${selectedDate.getFullYear()}`}</span>
          <img
            src={darkMode ? calender_brand : calendar3}
            width={20}
            height={20}
            alt=""
          />
        </div>
      </div>
      <div>
        <Calendar data={selctedClass} />
      </div>
    </main>
  );
};

export default Primary_Section;

import React from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { left, logo } from "../../../assets";
import { Link } from "react-router-dom";
import Button from "../../../components/button";

const CreateAccount = () => {
  return (
    <div className="w-full min-h-screen bg-login bg-fixed bg-no-repeat bg-cover">
      <div className="flex justify-between items-start flex-1 z-50">
        <button type="button" className="">
          <img
            src={left}
            width={50}
            height={30}
            alt="left"
            className="mt-7 ml-7"
          />
        </button>
        <div className="w-fit h-fit bg-slate-50 right-0 mt-4 mr-14 rounded-lg">
          <div className="bg-[#DD0046] w-full h-2 rounded-t-lg" />
          <div className="mx-10 my-10 flex flex-col">
            <img
              src={logo}
              width={150}
              height={100}
              alt="just image "
              className="pt-5 pb-10"
            />
            <div className="flex flex-col gap-[5px]">
              <span className="font-semibold text-2xl">Create account</span>
              <span className="text-gray-300 text-sm">
                Enter personal details below to create account.
              </span>
            </div>
            <form action="" className="flex flex-col gap-1 mb-0 mt-8">
              <label
                htmlFor="text"
                className="text-gray-300 text-sm font-[200]"
              >
                Email address
              </label>
              <input
                type="email"
                placeholder="Email address"
                className="w-96 py-2 bg-transparent border-2 pl-2 rounded-lg  border-b-[3px] mb-5 outline-none font-normal"
              />
              <label
                htmlFor="password"
                className="text-gray-300 text-sm font-[200]"
              >
                Password
              </label>
              <input
                type="password"
                placeholder="Enter password"
                className="bg-transparent border-2 pl-2 rounded-lg border-b-[3px] w-96 py-2 outline-none font-normal"
              />
              <span className="absolute right-16 mt-[7.59rem] mr-14 cursor-pointer text-gray-400">
                <VisibilityOffOutlinedIcon />
              </span>
              <div className="flex justify-between mt-2">
                <span className="flex gap-2">
                  <input type="checkbox" className="text-3xl" />
                  <span className="font-[500] text-gray-500">Remember me</span>
                </span>
                <Link to="/forgot_password" className="font-[500]">
                  Forget password?
                </Link>
              </div>

              <Button
                iconClassName="h-[0.9rem] w-3"
                // isLoading={loading}
                title={"Register"}
                bgColor={"bg-brand"}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-4 rounded-lg bg-brand w-full mt-4"}
              />
              <span className="text-center mt-5 text-sm">
                Already have an account,{" "}
                <span className="text-[#93002F] font-[600] cursor-pointer">
                  Login here.
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

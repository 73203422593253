import React, { useEffect, useState } from "react";
import { exports, print } from "../../../../assets";
import { Link } from "react-router-dom";

import {
  getAttendance,
  getClass,
  sendAttendance,
} from "../../../../services/Admin/dashboard";
import { useMutation, useQuery } from "react-query";
import { TableComponent } from "../../../../components/table/Table";
import SmallModal from "../modal/smallmodal";
import Button from "../../../../components/button";
import moment from "moment";
import toast from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EmptyStudentData from "../../Students/contents/emptyStudentData";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const Primary = () => {
  const [selectedContent, setSelectedContent] = useState<number | null>(0);
  const handleClick = (index: number) => {
    setSelectedContent(index);
  };
  const { data: classData, refetch } = useQuery(["class", "PRIMARY"], () =>
    getClass("PRIMARY")
  );
  const [studentData, setStudentData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCell, setSelectedCell] = useState<any>(null);

  const [selectedInputField, setSelectedInputField] = useState(null);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [data, setData] = useState();

  const darkMode = useSelector(selectDarkmode);

  const [dateValue, setDateValue] = useState<Dayjs | null | any>(
    dayjs(new Date())
  );

  const attendaceDataParams = {
    id: studentData?.id,
    date: dayjs(dateValue).format("YYYY-MM-DD"),
  };
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"]; // List of weekdays excluding Sat and Sun
  const today = new Date(); // Get today's date
  const workingDays: any = [];

  const {
    data: attendaceData,
    isLoading,
    refetch: attendanceRefetch,
  } = useQuery(["attendance", studentData?.id], () =>
    getAttendance(attendaceDataParams)
  );

  useEffect(() => {
    if (selectedContent === 0) {
      setStudentData(classData?.data[0]);
    }
  }, [classData]);

  useEffect(() => {
    attendanceRefetch();
  }, [studentData, dateValue]);

  useEffect(() => {
    if (selectedContent === 0) {
      setStudentData(classData?.data[0]);
    }
  }, [classData]);

  useEffect(() => {
    attendanceRefetch();
  }, [studentData]);

  let dayCount = 0;
  while (workingDays.length < 5) {
    const date = dayjs(dateValue).add(dayCount, "day"); // Use dayjs to manipulate dates

    const dayIndex = date.day(); // Get the day index (0-6)

    if (dayIndex !== 0 && dayIndex !== 6) {
      // Check if not Sat or Sun
      const dayName = date.format("ddd"); // Get the full day name
      workingDays.push(`${dayName} ${date.format("MMM DD YYYY")}`);
    }

    dayCount++;
  }

  useEffect(() => {
    // Update each object in the data array with the working days
    const updatedData: any =
      attendaceData?.data?.length > 0 &&
      attendaceData?.data?.map((item: any) => ({
        ...item,
        day1: "",
        day2: "",
        day3: "",
        day4: "",
        day5: "",
      }));
    setSelectedData(updatedData);
  }, [attendaceData?.data]);

  const openModal = (cell: any) => {
    setSelectedCell(cell);
    setSelectedInputField(cell.column.id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInputField(null);
  };

  const handleSelect = (value: any) => {
    if (selectedCell) {
      const updatedData = [...selectedData];
      updatedData[selectedCell?.row?.index][selectedCell?.column.id] = value;
      setSelectedData(updatedData);
      closeModal();
    }
  };

  const EVENT_COLUMNS = [
    {
      Header: "STUDENT NAME",
      accessor: "",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original.first_name} {row.original.last_name}
          </span>
        </div>
      ),
    },
    {
      Header: `${workingDays[0]}`,
      accessor: "day1",
      Cell: (cell: any) => {
        const formattedDateValue = dayjs(workingDays[0])
          .startOf("day")
          .format("YYYY-MM-DD");

        const filteredStatus = selectedData[
          cell.row.index
        ]?.class_attendances?.filter((item: any) => {
          const formattedAttendanceDate = dayjs(item?.attendance_date).format(
            "YYYY-MM-DD"
          );
          return formattedAttendanceDate === formattedDateValue && item.status;
        });

        const status =
          filteredStatus?.length > 0 ? filteredStatus[0]?.status : "";

        return (
          <div className="relative">
            <input
              type="text"
              className={`border py-3 w-28 pl-6 ${
                darkMode ? "bg-dark-200 border-gray-700" : ""
              }`}
              value={
                selectedData[cell.row.index][cell.column.id]
                  ? selectedData[cell.row.index][cell.column.id]
                  : status
                  ? status
                  : ""
              }
              onClick={() => {
                openModal(cell);
                setData(cell);
              }}
              readOnly
            />
          </div>
        );
      },
    },
    {
      Header: `${workingDays[1]}`,
      accessor: "day2",
      Cell: (cell: any) => {
        const formattedDateValue = dayjs(workingDays[1])
          .startOf("day")
          .format("YYYY-MM-DD");

        const filteredStatus = selectedData[
          cell.row.index
        ]?.class_attendances?.filter((item: any) => {
          const formattedAttendanceDate = dayjs(item?.attendance_date).format(
            "YYYY-MM-DD"
          );
          return formattedAttendanceDate === formattedDateValue && item.status;
        });

        const status =
          filteredStatus?.length > 0 ? filteredStatus[0]?.status : "";

        return (
          <div className="relative">
            <input
              type="text"
              className={`border py-3 w-28 pl-6 ${
                darkMode ? "bg-dark-200 border-gray-700" : ""
              }`}
              value={
                selectedData[cell.row.index][cell.column.id]
                  ? selectedData[cell.row.index][cell.column.id]
                  : status
                  ? status
                  : ""
              }
              onClick={() => {
                openModal(cell);
                setData(cell);
              }}
              readOnly
            />
          </div>
        );
      },
    },
    {
      Header: `${workingDays[2]}`,
      accessor: "day3",
      Cell: (cell: any) => {
        const formattedDateValue = dayjs(workingDays[2])
          .startOf("day")
          .format("YYYY-MM-DD");

        const filteredStatus = selectedData[
          cell.row.index
        ]?.class_attendances?.filter((item: any) => {
          const formattedAttendanceDate = dayjs(item?.attendance_date).format(
            "YYYY-MM-DD"
          );
          return formattedAttendanceDate === formattedDateValue && item.status;
        });

        const status =
          filteredStatus?.length > 0 ? filteredStatus[0]?.status : "";

        return (
          <div className="relative">
            <input
              type="text"
              className={`border py-3 w-28 pl-6 ${
                darkMode ? "bg-dark-200 border-gray-700" : ""
              }`}
              value={
                selectedData[cell.row.index][cell.column.id]
                  ? selectedData[cell.row.index][cell.column.id]
                  : status
                  ? status
                  : ""
              }
              onClick={() => {
                openModal(cell);
                setData(cell);
              }}
              readOnly
            />
          </div>
        );
      },
    },
    {
      Header: `${workingDays[3]}`,
      accessor: "day4",
      Cell: (cell: any) => {
        const formattedDateValue = dayjs(workingDays[3])
          .startOf("day")
          .format("YYYY-MM-DD");

        const filteredStatus = selectedData[
          cell.row.index
        ]?.class_attendances?.filter((item: any) => {
          const formattedAttendanceDate = dayjs(item?.attendance_date).format(
            "YYYY-MM-DD"
          );
          return formattedAttendanceDate === formattedDateValue && item.status;
        });

        const status =
          filteredStatus?.length > 0 ? filteredStatus[0]?.status : "";

        return (
          <div className="relative">
            <input
              type="text"
              className={`border py-3 w-28 pl-6 ${
                darkMode ? "bg-dark-200 border-gray-700" : ""
              }`}
              value={
                selectedData[cell.row.index][cell.column.id]
                  ? selectedData[cell.row.index][cell.column.id]
                  : status
                  ? status
                  : ""
              }
              onClick={() => {
                openModal(cell);
                setData(cell);
              }}
              readOnly
            />
          </div>
        );
      },
    },
    {
      Header: `${workingDays[4]}`,
      accessor: "day5",
      Cell: (cell: any) => {
        const formattedDateValue = dayjs(workingDays[4])
          .startOf("day")
          .format("YYYY-MM-DD");

        const filteredStatus = selectedData[
          cell.row.index
        ]?.class_attendances?.filter((item: any) => {
          const formattedAttendanceDate = dayjs(item?.attendance_date).format(
            "YYYY-MM-DD"
          );
          return formattedAttendanceDate === formattedDateValue && item.status;
        });

        const status =
          filteredStatus?.length > 0 ? filteredStatus[0]?.status : "";

        return (
          <div className="relative">
            <input
              type="text"
              className={`border py-3 w-28 pl-6 ${
                darkMode ? "bg-dark-200 border-gray-700" : ""
              }`}
              value={
                selectedData[cell.row.index][cell.column.id]
                  ? selectedData[cell.row.index][cell.column.id]
                  : status
                  ? status
                  : ""
              }
              onClick={() => {
                openModal(cell);
                setData(cell);
              }}
              readOnly
            />
          </div>
        );
      },
    },
  ];

  const atendanceSubmit = useMutation(sendAttendance);

  const studentAttendance = selectedCell?.data?.flatMap((item: any) => {
    const attendance = [
      {
        attendance_date: moment(workingDays[0], "ddd MMM D").format(
          "YYYY-MM-DD"
        ),
        status: item.day1 || "",
      },
      {
        attendance_date: moment(workingDays[1], "ddd MMM D").format(
          "YYYY-MM-DD"
        ),
        status: item.day2 || "",
      },
      {
        attendance_date: moment(workingDays[2], "ddd MMM D").format(
          "YYYY-MM-DD"
        ),
        status: item.day3 || "",
      },
      {
        attendance_date: moment(workingDays[3], "ddd MMM D").format(
          "YYYY-MM-DD"
        ),
        status: item.day4 || "",
      },
      {
        attendance_date: moment(workingDays[4], "ddd MMM D").format(
          "YYYY-MM-DD"
        ),
        status: item.day5 || "",
      },
    ];

    const filteredAttendance = attendance.filter((att) => att.status !== "");

    // Only include students with non-empty attendance
    if (filteredAttendance.length > 0) {
      return {
        student_id: item.id,
        attendance: filteredAttendance,
      };
    }

    return []; // Return an empty array for students without attendance
  });

  const onSubmit = async () => {
    setLoading(true);
    const formData = {
      class_id: studentData?.id,
      students: studentAttendance,
    };

    try {
      const res = await atendanceSubmit.mutateAsync(formData);

      if (res.message) {
        setLoading(false);
        toast.success(res.message);
        setSelectedCell(null);
      }
    } catch (error: any) {
      setLoading(false);
      setSelectedCell(null);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="mt-6 px-8">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="text-2xl font-[550]">Attendance</span>
          <span className="text-gray-400">
            See and manage students attendance below
          </span>
        </div>
        <div className="flex justify-between mx-6 my-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]} sx={{}}>
              <DatePicker
                sx={{
                  borderRadius: 12,
                  borderBlockColor: "black",
                  paddingY: 0,
                }}
                value={dateValue}
                onChange={(newValue: any) => setDateValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mt-8 mb-6 flex gap-5 text-gray-700">
          {classData?.data.map((link: any, index: number) => {
            return (
              <Link
                to=""
                key={index}
                className={`${
                  index === selectedContent
                    ? ` p-2 ${
                        darkMode
                          ? "text-red-500 border-b-4 border-red-500"
                          : "text-blue-500 border-b-4 border-blue-500"
                      }`
                    : "text-gray-500 p-2"
                } inline-block transition-all duration-300`}
                onClick={() => {
                  setStudentData(link);
                  handleClick(index);
                }}
              >
                {link.name}
              </Link>
            );
          })}
        </div>
        {studentAttendance?.length > 0 && (
          <Button
            iconClassName="h-[0.9rem] w-3"
            isLoading={loading}
            title={"Mark Attendance"}
            bgColor={"bg-brand"}
            hoverColor={""}
            textColor={"text-white"}
            className={"py-4 rounded-lg bg-brand w-36 mt-4"}
            onClick={onSubmit}
          />
        )}

        {/* <div className='mt-8 flex gap-6'>
					<button type='button'>
						<img src={print} width={23} height={30} alt='print icon' />
					</button>
					<button type='button'>
						<img src={exports} width={48} height={30} alt='print icon' />
					</button>
				</div> */}
      </div>

      <div>
        {attendaceData?.data?.length > 0 && selectedData?.length > 0 ? (
          <TableComponent
            className="rounded-none rounded-t-0"
            DATA={selectedData}
            COLUMNS={EVENT_COLUMNS}
            page={page}
            setPage={setPage}
            isNotPaginanated
          />
        ) : attendaceData?.data?.length < 1 ? (
          <div className="text-center text-xs">
            <EmptyStudentData
              noStudentRegister
              textMessage="This Class does  not have any student"
            />
          </div>
        ) : (
          <div className="w-full mb-3 px-8 flex justify-between items-center">
            <Skeleton
              variant="rectangular"
              height={50}
              className="w-[50px] rounded-full mb-4"
            />
            <div className="w-[80%]">
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-full mb-4"
              />
              <Skeleton
                variant="rectangular"
                height={20}
                className="w-[80%] mb-4"
              />
            </div>
          </div>
        )}
      </div>

      {selectedInputField && (
        <SmallModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSelect={handleSelect}
          selectedCell={selectedCell}
        />
      )}
    </div>
  );
};

export default Primary;

import ClassManagement from '../../pages/parents/Class-management';
import MyWard from '../../pages/parents/My-ward/Index';
import Settings from '../../pages/Settings';
import Profile from '../../pages/parents/profile';
import See_Details from '../../pages/parents/My-ward/contents/see_details';
import EditParentForm from '@/pages/parents/profile/EditParent';

const id = '';
export const ParentRoutes = [
	{
		id: '1',
		title: 'My Ward',

		path: 'parent/myward/home',
		component: MyWard,
		alt: '',
	},
	{
		id: '2',
		title: 'Class Management',

		path: 'parent/class-managements',
		component: ClassManagement,
		alt: '',
	},

	{
		id: '5',
		title: 'Profile',

		path: 'parent/profile',
		component: Profile,
		alt: '',
	},
	{
		id: '6',
		title: 'Settings',
		path: 'parent/settings',
		component: Settings,
		alt: '',
	},
	{
		id: '7',
		title: 'Details',
		path: `parent/myward/details/:id`,
		component: See_Details,
		alt: '',
	},
	{
		id: '8',
		title: 'Edit parent',
		path: `parent/profile/edit-parent`,
		component: EditParentForm,
		alt: '',
	},
];

import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";

import Modal from "../../../components/Modal";
import Button from "../../../components/button";
import {
  getClassManagagementAPI,
  getCourseReportAPI,
} from "@/services/student/dashboard";
import { useQuery } from "react-query";
import {
  avatar,
  avatar_,
  download,
  school,
  stepper,
  stepper1,
  stepper2,
  stepper3,
} from "@/assets";
import moment from "moment";
import { TableComponent } from "@/components/table/Table";
import { ResultTable } from "@/components/table/filter/ResultTable";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

function MyResults() {
  const [isOpen, setIsopen] = useState<boolean>(false);
  const [isResult, setIsResult] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [term, setTerm] = useState("FIRST TERM");
  const {
    data: classMgt,
    refetch: classmgtRefetch,
    isLoading,
  } = useQuery("class", getClassManagagementAPI);
  const isClose = () => {
    setIsopen(false);
  };
  const [selected, setSelected] = useState<any>(null);

  const handleSelected = (items: any) => {
    setSelected(items);
  };

  const params = {
    term,
    classId: selected?.id,
  };

  const { data: resultData, refetch } = useQuery(["subjects", params], () =>
    getCourseReportAPI(params)
  );

  useEffect(() => {
    refetch();
  }, [term, selected]);

  const eachDetails = [
    {
      term: "1st term results",
      img: download,
      todo: "View",
      onclick: () => {
        setTerm("FIRST TERM");
        setIsResult(true);
        setIsopen(false);
      },
    },
    {
      term: "2nd term results",
      img: download,
      todo: "View",
      onclick: () => {
        setTerm("SECOND TERM");
        setIsResult(true);
        setIsopen(false);
      },
    },
    {
      term: "3rd term results",
      img: download,
      todo: "View",
      onclick: () => {
        setTerm("THIRD TERM");
        setIsResult(true);
        setIsopen(false);
      },
    },
  ];
  const SCHOOL_SUBJECT_COLUMNS = [
    {
      Header: "SUBJECT TITLE",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.subject?.name || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "FIRST TEST",

      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.first_test_score || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "SECOND TEST",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.second_test_score || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "THIRD TEST",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.third_test_score || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "EXAMINATION",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.exam_score || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "TOTAL SCORE",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.total_score || "Not available"}</span>
        </div>
      ),
    },
    {
      Header: "GRADE",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.grade || "Not available"}</span>
        </div>
      ),
    },
  ];

  const darkMode = useSelector(selectDarkmode);

  return (
    <div className="px-4 lg:px-10 pt-10">
      <DashboardHeader
        title={" My results 🧾"}
        subtitle="See and manage your results here."
      />
      <div
        className={`mt-10 w-full px-10 border-[1px] shadow-sm rounded-lg py-10  ${
          darkMode ? "border-gray-700" : ""
        }`}
      >
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-10">
          {classMgt?.data?.map((items: any) => (
            <div
              key={items.id}
              className={`  ${
                darkMode ? "bg-black" : "bg-[#FAFAFA]"
              } w-fit shadow-sm py-7 px-5 rounded-lg flex flex-col gap-5 cursor-pointer`}
              onClick={() => {
                handleSelected(items);
                setIsopen(true);
              }}
            >
              <span className="text-2xl">{items.name}</span>
              <img
                src={
                  items.has_first_term === "0" &&
                  items.has_second_term === "0" &&
                  items.has_third_term === "0"
                    ? stepper3
                    : items.has_second_term === "1"
                    ? stepper2
                    : items.has_third_term === "1"
                    ? stepper
                    : stepper1
                }
                width={400}
                height={400}
                alt="terms"
              />
            </div>
          ))}
        </div>
      </div>
      {selected && (
        <Modal open={isOpen} close={isClose} maxWidth="xs">
          <div className="px-7">
            <span
              className={`flex items-center justify-center font-semibold text-2xl ${
                darkMode ? " text-gray-300" : " text-black"
              } `}
            >
              {selected?.name}
            </span>
            {eachDetails.map((item) => (
              <div
                className={`border-[1px] ${
                  darkMode ? "border-gray-700" : ""
                }  flex justify-between items-center mb-4 mt-7 py-2 px-3 rounded-lg`}
              >
                <span
                  className={`cursor-pointer ${
                    darkMode ? " text-gray-300" : " text-black"
                  }  `}
                >
                  {item.term}
                </span>
                <div
                  className="flex gap-4 cursor-pointer"
                  onClick={item.onclick}
                >
                  <span
                    className={`underline  text-sm font-semibold cursor-pointer   ${
                      darkMode ? "text-other " : "text-brand"
                    } `}
                  >
                    {item.todo}
                  </span>
                  {/* <img
										src={item.img}
										width={20}
										height={20}
										className='cursor-pointer'
										alt='download '
									/> */}
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
      {resultData?.data && (
        <Modal open={isResult} close={() => setIsResult(false)} maxWidth="md">
          <div className="px-8 lg:px-16">
            <p
              className={`font-semibold text-xl mt-6 text-center ${
                darkMode ? " text-gray-300" : " text-black"
              } `}
            >
              BHHS SCHOOL
            </p>
            <div className="w-full flex lg:flex-row flex-col justify-center lg:justify-between items-center lg:items-start">
              <img
                src={school}
                className="rounded-lg h-28 w-28 lg:mt-0 mt-6"
                alt=""
              />
              <div>
                <p
                  className={`  text-sm mt-2 text-center ${
                    darkMode ? " text-gray-300" : " text-black"
                  } `}
                >
                  Address: 28/29 Oramma Road, Egbelu, Oyigbo L.G.A,
                </p>
                <div className="lg:flex justify-center items-center lg:mt-0 mt-6">
                  <p
                    className={`  text-sm text-center ${
                      darkMode ? " text-gray-300" : " text-black"
                    } `}
                  >
                    email: 2, Port Harcourt, River state, Nigeria
                  </p>
                  <p
                    className={` text-sm ml-0 lg:ml-3 text-center ${
                      darkMode ? " text-gray-300" : " text-black"
                    } `}
                  >
                    Phone number: 08025293124
                  </p>
                </div>
                <p
                  className={`  text-sm mt-2 text-center ${
                    darkMode ? " text-gray-300" : " text-black"
                  } `}
                >
                  website: https://www.bhss.com.ng
                </p>
              </div>
              <img
                src={resultData?.data?.student?.profile_image || avatar_}
                className="rounded-lg h-28 w-28 lg:mt-0 mt-6"
                alt=""
              />
            </div>

            <div>
              <p
                className={`text-center font-semibold text-sm ${
                  darkMode ? " text-gray-300" : " text-black"
                } `}
              >
                ACADEMIC REPORT
              </p>
              <p
                className={`text-center font-semibold text-sm ${
                  darkMode ? " text-gray-300" : " text-black"
                } `}
              >
                {selected?.name}
              </p>
              <p
                className={`text-center font-semibold text-sm uppercase ${
                  darkMode ? " text-gray-300" : " text-black"
                } `}
              >
                {term.toLowerCase()} result
              </p>
            </div>
            <div className="lg:flex justify-between items-start w-full lg:mt-0 mt-4">
              <div className="w-full lg:w-[68%]">
                <p
                  className={`text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  } `}
                >{`Name of Student: ${resultData?.data?.student?.first_name} ${resultData?.data?.student?.last_name}`}</p>
                <p
                  className={` text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  } `}
                >{`Date of Birth: ${moment(
                  resultData?.data?.student?.dob
                ).format("Do MMM, YYYY")}`}</p>
                <p
                  className={` text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  } `}
                >{`Gender: ${resultData?.data?.student?.gender}`}</p>
              </div>
              <div className="w-full lg:w-[30%]">
                <p
                  className={` text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  }`}
                >{`Email Address: ${resultData?.data?.student?.email}`}</p>
                <p
                  className={`text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  }`}
                >{`Phone Number: ${resultData?.data?.student?.phone_number}`}</p>
                <p
                  className={` text-xs ${
                    darkMode ? " text-gray-300" : " text-black"
                  }`}
                >{`Unique Number: ${resultData?.data?.student?.unique_number}`}</p>
              </div>
            </div>
            <div className="mt-5">
              {resultData?.data?.course_reports?.length > 0 ? (
                <ResultTable
                  className="rounded-none rounded-t-0"
                  DATA={resultData?.data?.course_reports}
                  COLUMNS={SCHOOL_SUBJECT_COLUMNS}
                  page={page}
                  setPage={setPage}
                  isNotPaginanated={true}
                />
              ) : (
                <p className="my-4 text-center">No exam record</p>
              )}
            </div>

            <div className="w-full overflow-scroll bg-transparent mt-8">
              <div className={`w-full overflow-scroll text-sm lg:rounded-t-xl`}>
                <table
                  className={`w-full table  px-8 border-[0.5px]  ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                >
                  <thead
                    className={`border ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    }`}
                  >
                    <tr>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        Marks Range in Percentage
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        100% - 70%
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        69% - 60%
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        59% - 50%
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        49% - 45%
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        44% - 40%
                      </th>
                      <th
                        className={`whitespace-nowrap text-left pl-6 pr-3 py-4  text-xs font-medium ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        39% - 0%
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        Grade
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        A
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        B
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        C
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        D
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        E
                      </td>
                      <td
                        className={`pl-6 pr-3 py-4 border-0  text-xs ${
                          darkMode ? "text-gray-300" : "text-black"
                        }`}
                      >
                        F
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              onClick={() => setIsResult(false)}
              title={"Close"}
              bgColor={"bg-brand"}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full mt-11 mb-11"}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default MyResults;

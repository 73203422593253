/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import AdminManageMent from "./AdminManageMent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import {
  Admin,
  Teacher,
  avatar,
  avatar_,
  parent,
  student,
} from "../../../assets";
import moment from "moment";
import Button from "../../../components/button";
import Modal from "../../../components/Modal";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

type Props = {
  title: string;
  start_date: string;
  end_date: string;
  location: string;
};
const AdminContent = (props?: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Props | null>(null);

  const handleSelected = (item: Props) => {
    setSelected(item);
  };

  // console.log(props, 'ssss');

  const darkMode = useSelector(selectDarkmode);

  return (
    <section>
      <div className="w-full flex justify-evenly items-center flex-wrap mb-6">
        <div
          className={`w-full lg:w-[32%] flex border-[0.6px] mb-4 px-5 py-4 ${
            darkMode ? "border-gray-700" : ""
          } shadow-sm rounded-lg `}
        >
          <div>
            <img src={Admin} width={75} height={75} alt="images" />
          </div>
          <div className="pt-2 flex justify-between items-center flex-1 ml-4 ">
            <span className="font-semibold  text-xl ">Admins</span>
            <span className="font-semibold  text-2xl">
              {props?.data?.admin_count}
            </span>
          </div>
        </div>
        <div
          className={`w-full lg:w-[32%] flex border-[0.6px] mb-4 px-5 py-4  shadow-sm rounded-lg ${
            darkMode ? "border-gray-700" : ""
          }`}
        >
          <div>
            <img src={Teacher} width={75} height={75} alt="images" />
          </div>
          <div className="pt-2 flex justify-between items-center flex-1  ml-4">
            <span className="font-semibold text-xl">Teachers</span>
            <span className="font-semibold  text-2xl">
              {props?.data?.teacher_count}
            </span>
          </div>
        </div>
        <div
          className={`w-full lg:w-[32%] flex border-[0.6px] mb-4 px-5 py-4  shadow-sm rounded-lg ${
            darkMode ? "border-gray-700" : ""
          }`}
        >
          <div>
            <img src={student} width={75} height={75} alt="images" />
          </div>
          <div className="pt-2 flex justify-between items-center flex-1 ml-4">
            <span className="font-[500]  text-xl">Students</span>
            <span className="font-[550]  text-2xl">
              {props?.data?.student_count}
            </span>
          </div>
        </div>
        <div
          className={`w-full lg:w-[32%] flex border-[0.6px] mb-4 px-5 py-4  shadow-sm rounded-lg ${
            darkMode ? "border-gray-700" : ""
          }`}
        >
          <div>
            <img src={parent} width={75} height={75} alt="images" />
          </div>
          <div className="pt-2 flex justify-between items-center flex-1 ml-4">
            <span className="font-[500]  text-xl">Parent</span>
            <span className="font-[550]  text-2xl">
              {props?.data?.parent_count}
            </span>
          </div>
        </div>
        <div
          className={`w-full lg:w-[32%] flex border-[0.6px] mb-4 px-5 py-4  shadow-sm rounded-lg ${
            darkMode ? "border-gray-700" : ""
          }`}
        >
          <div>
            <img src={parent} width={75} height={75} alt="images" />
          </div>
          <div className="pt-2 flex justify-between items-center flex-1 ml-4">
            <span className="font-[500]  text-xl">Non Teachers</span>
            <span className="font-[550]  text-2xl">
              {props?.data?.non_teacher_count}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start flex-wrap mb-4">
        <div className="w-full lg:w-[60%] mb-4">
          <div
            className={`shadow-box rounded-xl w-full h-fit flex flex-col pb-6 ${
              darkMode ? "bg-black" : ""
            }`}
          >
            <div className="flex justify-between p-5">
              <span
                className={`text-sm font-bold text-black ${
                  darkMode ? "text-gray-300" : "text-black"
                }`}
              >
                Events list
              </span>
            </div>

            {props?.data?.event_list?.length > 0 ? (
              props?.data?.event_list?.map((event: any, i: number) => (
                <div
                  key={i}
                  className="px-4 lg:px-6 w-full  flex justify-between items-center mb-4 bg-gray-50 cursor-pointer"
                  onClick={() => {
                    handleSelected(event);
                    setIsOpen(true);
                  }}
                >
                  <div className="w-[75%] flex flex-col rounded-xl py-2">
                    <div className="flex justify-start">
                      <div>
                        <img
                          src={event?.image_path || avatar_}
                          alt="profile image"
                          className="rounded-lg w-12 h-12 "
                        />
                      </div>
                      <div className="flex flex-col ml-2 ">
                        <p className="text-xs text-black font-fold">
                          {event?.title}
                        </p>
                        <div className="mt-2 flex justify-start">
                          <p className=" text-[#A2A2A2]/80 text-xs ">
                            {moment(event?.start_date).format(
                              "ddd Do MMM, YYYY"
                            )}
                          </p>
                          <span className="text-[#A2A2A2]/80 text-xs ml-4">
                            |
                          </span>
                          <p className="text-[#A2A2A2]/80 text-xs ml-4">
                            {moment(event?.start_date).format("hh:mma")} -{" "}
                            {moment(event?.end_date).format("hh:mma")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a
                    className={`capitalize text-xs px-4 py-2 rounded-xl  ${
                      event?.status === "PENDING"
                        ? "bg-[#FF9B251A] text-[#FF9B25] hover:bg-orange-300 hover:text-white"
                        : event.status === "ONGOING"
                        ? "text-[#16A546] bg-[#16A5461A] hover:bg-green-200 hover:text-green-700"
                        : "bg-red-200 text-red-600 hover:bg-red-200 hover:text-red-700"
                    }`}
                  >
                    {event?.status?.toLowerCase()}
                  </a>
                </div>
              ))
            ) : (
              <div
                className={`h-60 px-4 lg:px-6 w-full  flex justify-between items-center mb-4   ${
                  darkMode ? "bg-dark-200" : "bg-gray-50"
                }`}
              >
                <p className=" text-center text-sm">
                  No event available at the moment
                </p>
              </div>
            )}

            {/* active */}
          </div>
        </div>

        <div
          className={`w-full lg:w-[38%] shadow-box mb-4 ${
            darkMode ? "bg-black" : ""
          }`}
        >
          <div className="flex justify-between p-5">
            <span
              className={`text-sm font-bold  ${
                darkMode ? "text-gray-300" : "text-black"
              }`}
            >
              School calendar
            </span>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar />
          </LocalizationProvider>
        </div>
      </div>
      <AdminManageMent getAllEvents={props?.data?.events} />
      <Modal open={isOpen} close={() => setIsOpen(false)} maxWidth="xs">
        <div className="px-8">
          <div className="flex flex-col text-center mb-8">
            <span className="text-slate-950 font-bold text-2xl">
              Event Details
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event title:</p>
              <p className="font-semibold text-sm"> {selected?.title}</p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Start date:</p>
              <p className="font-semibold text-sm">{selected?.start_date} </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">End date:</p>
              <p className="font-semibold text-sm">{selected?.end_date} </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Location:</p>
              <p className="font-semibold text-sm">{selected?.location}</p>
            </div>
          </div>

          <div className="py-8 ">
            <Button
              iconClassName="h-[0.9rem] w-3"
              title={"Close"}
              bgColor={"bg-brand"}
              onClick={() => {
                setIsOpen(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full"}
            />
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default AdminContent;

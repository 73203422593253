import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import Typography from "../../../../components/Typography";
import { search } from "../../../../assets";
import Button from "../../../../components/button";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

interface Props {
  isOpen: boolean;
  isClose: () => void;
  onSelect: (selectedValue: any) => void;
  data: any;
}

const CourseModal = ({ isOpen, isClose, onSelect, data }: Props) => {
  const [filter, setFilter] = useState("");

  const filtered = data?.data?.filter((result: any) =>
    result?.name?.toLowerCase().includes(filter?.toLowerCase())
  );
  const handleFilterchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target?.value?.toLowerCase();
    setFilter(input);
  };

  const darkMode = useSelector(selectDarkmode);
  return (
    <Modal open={isOpen} close={isClose} maxWidth="xs">
      <div className="px-7">
        <Typography
          textStyle={`text-center font-semibold text-2xl ${
            darkMode ? "text-other" : ""
          }`}
        >
          Select course
        </Typography>
        <div
          className={`flex border w-full gap-3 py-3 px-3 rounded-lg mt-5 ${
            darkMode ? "border-gray-700" : ""
          }`}
        >
          <img src={search} alt="search icon" />
          <input
            type="text"
            value={filter}
            onChange={handleFilterchange}
            placeholder="Search for course..."
            className={`outline-none ${darkMode ? "bg-transparent" : ""}`}
          />
        </div>
        <div className="flex flex-col gap-8 mt-7 overflow-y-scroll">
          {data?.data?.map((item: any) => (
            <span
              key={item.id}
              className="cursor-pointer "
              onClick={() => onSelect(item)}
            >
              {item.name}
            </span>
          ))}
        </div>
        <Button
          iconClassName="h-[0.9rem] w-3"
          title={"close"}
          onClick={isClose}
          bgColor={"bg-transparent"}
          hoverColor={""}
          textColor={`${darkMode ? "text-gray-300" : "text-black"}`}
          className={"py-4 rounded-lg border w-full my-9 text-xl"}
        />
      </div>
    </Modal>
  );
};

export default CourseModal;

import Attendance from "../../pages/admin/Attendance";
import Calender from "../../pages/admin/Calender";
import Classes from "../../pages/admin/Classes";
import Dashboard from "../../pages/admin/Dashboard";
import Settings from "../../pages/Settings";
import Subjects from "../../pages/admin/Subjects";
import Students from "../../pages/admin/Students";
import Teachers from "../../pages/admin/Teachers";
import Timetable from "../../pages/admin/Timetable";
import Profile from "../../pages/admin/profile";
import UserRoles from "../../pages/admin/users-roles";
import AddTask from "../../pages/admin/Dashboard/contents/AddTask";
import EditTask from "../../pages/admin/Dashboard/contents/EditTask";
import Event from "../../pages/admin/Dashboard/contents/Event";
import Create_class from "../../pages/admin/Classes/contents/create_class";
import Edit_class from "../../pages/admin/Classes/contents/edit_class";
import Add_subject from "../../pages/admin/Subjects/contents/Add-subject";
import Edit_subject from "../../pages/admin/Subjects/contents/Edit_subject";
import Add_teacher from "../../pages/admin/Teachers/contents/Add-Teachers";
import Edit_teacher from "../../components/form/authForms/EditSection/EditTeacher";
import Add_student from "../../pages/admin/Students/contents/add_student";
import Edit_student from "../../components/form/authForms/EditSection/EditStudent";
import Users from "../../pages/admin/users-roles/content/pages/Users";
import Roles from "../../pages/admin/users-roles/content/pages/Roles";
import Calender_Event from "../../pages/admin/Calender/contents/pages/AddEvent";
import Edit_Event from "../../pages/admin/Calender/contents/pages/EditEvent";
import Help from "../../pages/Help";
import EditProfile from "../../pages/admin/profile/EditProfile";
import Nursery_Class from "../../pages/admin/Attendance/classes/nursery";
import Primary_Class from "../../pages/admin/Attendance/classes/primary";
import Secondary_Class from "../../pages/admin/Attendance/classes/secondary";
import Nursery_Table from "../../pages/admin/Timetable/sections/nursery";
import Primary_Table from "../../pages/admin/Timetable/sections/primary";
import Secondary_Table from "../../pages/admin/Timetable/sections/secondary";
import EditEvent from "../../pages/admin/Dashboard/contents/EditTask";
import ViewClass from "@/pages/admin/Classes/ViewClass";
import EditParentForm from "@/components/form/authForms/EditParent";
import Promote from "@/pages/admin/Promote";
import PromoteClass from "@/pages/admin/Promote/ViewClass";
import ViewStudents from "@/pages/teachers/Students/ViewStudents";
import ViewTeacher from "@/pages/admin/Teachers/ViewTeachers";

export const AdminRoutes = [
  {
    id: "1",
    title: "Dashboard",
    path: "admin/dashboard",
    component: Dashboard,
    alt: "",
  },
  {
    id: "2",
    title: "Attendance",
    path: "/admin/attendance/nursery-classes",
    component: Attendance,
    alt: "",
  },
  {
    id: "3",
    title: "Calender Events",

    path: "admin/calender-events",
    component: Calender,
    alt: "",
  },
  {
    id: "4",
    title: "Timetable",
    path: "admin/timetable",
    component: Timetable,
    alt: "",
  },
  {
    id: "5",
    title: "Classes",
    path: "admin/classes",
    component: Classes,
    alt: "",
  },
  {
    id: "15",
    title: "Classes",
    path: "admin/Classes/view-class",
    component: ViewClass,
    alt: "",
  },
  {
    id: "6",
    title: "Subjects",
    path: "/admin/subjects",
    component: Subjects,
    alt: "",
  },
  {
    id: "7",
    title: "Teachers",
    path: "admin/teachers",
    component: Teachers,
    alt: "",
  },
  {
    id: "7",
    title: "Teachers",
    path: "admin/teachers/view-teachers",
    component: ViewTeacher,
    alt: "",
  },
  {
    id: "8",
    title: "Students",
    path: "admin/Students",
    component: Students,
    alt: "",
  },
  {
    id: "9",
    title: "Users and Roles",
    path: "admin/users-and-roles",
    component: UserRoles,
    alt: "",
  },
  {
    id: "10",
    title: "Profile",

    path: "admin/profile",
    component: Profile,
    alt: "",
  },
  {
    id: "11",
    title: "Settings",
    path: "admin/settings",
    component: Settings,
    alt: "",
  },
  {
    id: "12",
    title: "AddTask",
    path: "admin/home/AddTask",
    component: AddTask,
    alt: "",
  },
  {
    id: "13",
    title: "EditTask",
    path: "admin/home/EditTask",
    component: EditTask,
    alt: "",
  },

  {
    id: "15",
    title: "Event",
    path: "admin/home/event",
    component: Event,
    alt: "",
  },
  {
    id: "16",
    title: "Create_class",
    path: "admin/classes/create-class",
    component: Create_class,
    alt: "",
  },
  {
    id: "17",
    title: "Create_class",
    path: "admin/home/edit-class",
    component: Edit_class,
    alt: "",
  },
  {
    id: "18",
    title: "Add_subject",
    path: "admin/subjects/add_subject",
    component: Add_subject,
    alt: "",
  },
  {
    id: "19",
    title: "Add_subject",
    path: "admin/subjects/edit-subject",
    component: Edit_subject,
    alt: "",
  },
  {
    id: "20",
    title: "Add_teacher",
    path: "admin/teachers/add_teachers",
    component: Add_teacher,
    alt: "",
  },
  {
    id: "21",
    title: "Edit_teacher",
    path: "admin/teachers/edit-teachers",
    component: Edit_teacher,
    alt: "",
  },
  {
    id: "22",
    title: "Add_student",
    path: "admin/students/add_students",
    component: Add_student,
    alt: "",
  },
  {
    id: "23",
    title: "Edit_student",
    path: "admin/students/edit-students",
    component: Edit_student,
    alt: "",
  },
  {
    id: "24",
    title: "User_student",
    path: "admin/users-and-roles/add_users",
    component: Users,
    alt: "",
  },
  {
    id: "25",
    title: "User_student",
    path: "admin/users-and-roles/add_roles",
    component: Roles,
    alt: "",
  },

  {
    id: "27",
    title: "Help ",
    path: "/help",
    component: Help,
    alt: "",
  },
  {
    id: "28",
    title: "Class_event",
    path: "/admin/calender-events/add_event",
    component: Calender_Event,
    alt: "",
  },
  {
    id: "29",
    title: "Class_event",
    path: "/calender-events/edit-events",
    component: EditEvent,
    alt: "",
  },
  {
    id: "30",
    title: "Edit Profile",
    path: "admin/profile/edit-profile",
    component: EditProfile,
    alt: "",
  },
  {
    id: "31",
    title: "Nursery-Class",
    path: "admin/attendance/classes/nursery",
    component: Nursery_Class,
    alt: "",
  },
  {
    id: "32",
    title: "Primary-Class",
    path: "admin/attendance/classes/primary",
    component: Primary_Class,
    alt: "",
  },
  {
    id: "33",
    title: "Secondary-Class",
    path: "admin/attendance/classes/secondary",
    component: Secondary_Class,
    alt: "",
  },
  {
    id: "34",
    title: "Nursery_Timetable",
    path: "admin/time-table/sections/nursery-table",
    component: Nursery_Table,
    alt: "",
  },
  {
    id: "35",
    title: "Primary_Timetable",
    path: "admin/time-table/sections/primary-table",
    component: Primary_Table,
    alt: "",
  },
  {
    id: "36",
    title: "Secondary_Timetable",
    path: "admin/time-table/sections/secondary-table",
    component: Secondary_Table,
    alt: "",
  },
  {
    id: "37",
    title: "Edit parent",
    path: "admin/users-and-roles/edit-parent",
    component: EditParentForm,
    alt: "",
  },
  {
    id: "38",
    title: "Promote",
    path: "admin/promote",
    component: Promote,
    alt: "",
  },
  {
    id: "38",
    title: "Promote",
    path: "admin/promote/view-class",
    component: PromoteClass,
    alt: "",
  },
  {
    id: "39",
    title: "Students",
    path: "admin/Students/view",
    component: ViewStudents,
    alt: "",
  },
];

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { SearchIcon, WORLD, avatar_ } from "@/assets";
import Button from "@/components/button";
import { Fade } from "react-reveal";
import Lottie from "react-lottie";
import { useQuery } from "react-query";
import { getEventAPI } from "@/services/profile";
import { Skeleton } from "@mui/material";
import moment from "moment";
import Modal from "@/components/Modal";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

function Events(this: {
  id: string;
  title: string;
  component: () => import("react/jsx-runtime").JSX.Element;
  path: string;
}) {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [event, setEvent] = useState<any>();

  const param = {
    page,
    search,
  };
  const { data, isLoading, refetch } = useQuery(["events", param], () =>
    getEventAPI(param)
  );

  const searchNotification = (event: any) => {
    setSearch(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WORLD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const darkMode = useSelector(selectDarkmode);
  return (
    <div className="lg:px-4  pt-5">
      <div className="w-full lg:flex justify-between items-start">
        <Fade left>
          <div className="hidden lg:block w-full lg:w-[35%] px-8 pb-8">
            <div className="shadow-box rounded-lg">
              <div>
                <Lottie options={defaultOptions} height={350} loop />

                <div className="mt-6 flex justify-center flex-col items-center px-6 mb-6">
                  <p className="font-bold text-xl text-center ">
                    Track your events and progress here
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <div className="w-full lg:w-[65%] px-4 ">
          <div className="  py-2 px-2 w-[100%] md:w-[30%] mb-6  flex items-center border-brand border-2 rounded-3xl">
            <img src={SearchIcon} alt="" className="h-4" />
            <input
              type="text"
              placeholder="Search in Files"
              className="bg-[transparent]  px-4 outline-0 w-full border-0"
              value={search}
              onChange={searchNotification.bind(this)}
            />
          </div>

          <div className="lg:flex justify-between items-start flex-wrap w-full">
            {data?.data?.length > 0 ? (
              data?.data?.map((event_data: any, i: number) => (
                <div
                  onClick={() => {
                    setEvent(event_data);
                    setIsOpen(true);
                  }}
                  key={i}
                  className={`px-3 w-full lg:w-[48%] ${
                    isOpen && event_data.id === event?.id
                      ? ` ${darkMode ? "bg-other" : "bg-brand/10"}`
                      : ` ${darkMode ? "bg-black" : "bg-gray-50"} `
                  }  flex justify-between items-center mb-2 cursor-pointer rounded-xl`}
                >
                  <div className="flex flex-col rounded-xl py-2">
                    <div className="flex justify-evenly items-center">
                      <div>
                        <img
                          src={event_data?.image_path ?? avatar_}
                          alt="profile image"
                          className="rounded-lg w-12 h-12 "
                        />
                      </div>
                      <div className="flex flex-col ml-2 my-4">
                        <span
                          className={`text-xs  font-fold ${
                            darkMode ? "text-gay-300" : "text-black"
                          }`}
                        >
                          {event_data?.title}
                        </span>
                        <div className="text-center text-xs mt-2">
                          <div>
                            <span className=" text-[#A2A2A2] text-xs">
                              {moment(event_data?.start_date).format(
                                "Do MMM, YYYY, hh:mma"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : data?.data?.length < 1 ? (
              <div className="text-center">{`no events available`}</div>
            ) : (
              <div className="w-full mb-3 px-8 flex justify-between items-center">
                <Skeleton
                  variant="rectangular"
                  height={50}
                  className="w-[50px] rounded-full mb-4"
                />
                <div className="w-[80%]">
                  <Skeleton
                    variant="rectangular"
                    height={20}
                    className="w-full mb-4"
                  />
                  <Skeleton
                    variant="rectangular"
                    height={20}
                    className="w-[80%] mb-4"
                  />
                </div>
              </div>
            )}
            <div className="w-full flex justify-end item mt-2">
              {page > 1 && (
                <Button
                  title="Previous"
                  bgColor={""}
                  onClick={handlePrevPage}
                  hoverColor={""}
                  textColor={"text-brand text-lg"}
                  className={" rounded-lg border-brand border-2 w-32"}
                />
              )}
              {data?.data?.length > 0 && (
                <Button
                  title="Next"
                  bgColor={""}
                  onClick={handleNextPage}
                  hoverColor={""}
                  textColor={"text-brand text-lg"}
                  className={" ml-5 rounded-lg border-brand border-2 w-32"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal open={isOpen} close={() => setIsOpen(false)} maxWidth="xs">
        <div className="px-8">
          <div className="flex flex-col text-center mb-8">
            <span
              className={` font-bold text-2xl ${
                darkMode ? "text-gray-300" : "text-slate-950"
              }`}
            >
              Event Details
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p
                className={`font-normal text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                Event title:
              </p>
              <p
                className={`font-semibold text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                {" "}
                {event?.title}
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p
                className={`font-normal text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                Event Start Date:
              </p>
              <p
                className={`font-semibold text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                {moment(event?.end_date).format("Do MMM, YYYY")}
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p
                className={`font-normal text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                Event End Date:
              </p>
              <p
                className={`font-semibold text-sm  ${
                  darkMode ? "text-gray-300" : "text-slate-950"
                }`}
              >
                {moment(event?.end_date).format("Do MMM, YYYY")}
              </p>
            </div>
          </div>
          {event?.location && (
            <div className="flex flex-col mb-8">
              <div className="flex justify-between items-center">
                <p
                  className={`font-normal text-sm  ${
                    darkMode ? "text-gray-300" : "text-slate-950"
                  }`}
                >
                  Location:
                </p>
                <p
                  className={`font-semibold text-sm  ${
                    darkMode ? "text-gray-300" : "text-slate-950"
                  }`}
                >
                  {" "}
                  {event?.location}
                </p>
              </div>
            </div>
          )}
          <div className="py-8 ">
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-brand"}
              onClick={() => {
                setIsOpen(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Events;

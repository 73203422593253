import { API } from '../config';

interface loginTypes {
	email?: string | any;
	password?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	otp?: string;
	newPassword?: string;
}

// Login function
export async function loginAPI({ email, password }: loginTypes) {
	const response = await API.post('auth/login', {
		email,
		password,
	});
	return response.data;
}
//register teacher
export async function registerTeacherAPI(data: any) {
	const response = await API.post('register-teacher', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}
export async function registerStudentAPI(data: any) {
	const response = await API.post('register-student', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}
export async function registerParentAPI(data: any) {
	const response = await API.post('register-parent', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}
export async function updateParentAPI(data: any, id: string) {
	const response = await API.post(`update-teacher/${id}`, data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function registerAdminAPI(data: any) {
	const response = await API.post('register-admin', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function registerNonTeacherAPI(data: any) {
	const response = await API.post('register-non-teacher', data, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

import React, { useEffect, useState } from "react";
import { ArrowDown } from "../../../assets";
import { useMutation, useQuery } from "react-query";
import {
  getCourseReportAPI,
  getSubject,
  sendReport,
} from "@/services/Teacher/dashboard";
import DashboardHeader from "@/components/headers/dashboard-header";
import SuccessSave from "./modal/SuccessSave";
import CourseModal from "./modal/CourseModal";
import toast from "react-hot-toast";
import Button from "@/components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@/components/Modal";
import Typography from "@/components/Typography";

function EditReport() {
  const [termModal, setTermModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [courseModal, setCourseModal] = useState(false);
  const navigate = useNavigate();
  const { data: subjectData } = useQuery("subjectData", getSubject);
  const paramState = useLocation().state;
  const [term, setTerm] = useState(paramState?.term);
  const [selectedCourse, setSelectedCourse] = useState<any>(
    paramState?.subject
  );
  const [selected, setSelected] = useState<any>(paramState?.class);
  const params = {
    class_id: selected?.id,
    subject_id: selectedCourse?.id,
    term,
  };
  const { data: courseData, refetch } = useQuery(["courseData", params], () =>
    getCourseReportAPI(params)
  );

  const selectedValue = (value: any) => {
    setSelectedCourse(value);
    setCourseModal(false);
  };
  useEffect(() => {
    refetch();
  }, [term]);

  useEffect(() => {
    setSelectedCourse(subjectData?.data[0]);

    (async () => {
      if (await subjectData) {
        setSelected(subjectData?.data[0]?.classes[0]);
      }
    })();
  }, [subjectData]);

  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState<
    { class_id: string | number; id: string; score: string[] }[]
  >([]); // Initialize with an empty array
  const submitReport = useMutation(sendReport);

  const handleInputChange = (
    class_id: string | number,
    studentId: string,
    inputValue: string[],
    studentIndex: number
  ) => {
    const updatedFormValues = [...formValues];
    updatedFormValues[studentIndex] = {
      class_id: class_id,
      id: studentId,
      score: inputValue,
    };
    setFormValues(updatedFormValues);
  };

  const renderHeaders = () => {
    const EVENT_COLUMNS = [
      "STUDENT NAME",
      "FIRST TEST",
      "SECOND TEST",
      "THIRD TEST",
      "EXAMINATION",
      "TOTAL SCORE",
      "GRADE",
    ];
    return EVENT_COLUMNS.map((item, index) => (
      <div
        key={index}
        className="bg-brand px-1 py-4 text-white text-sm overflow-auto"
      >
        {item}
      </div>
    ));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("class_id", selected?.id);
    formData.append("subject_id", selectedCourse?.id);
    formData.append("term", term);

    const filteredFormValues = updatedFormValues?.filter(
      (student: any, i: number) => {
        return (
          student.score &&
          student.score.length >= 1 && // Adjusted to at least 1 element
          typeof student.score[i] === "string"
        );
      }
    );

    if (filteredFormValues && filteredFormValues.length > 0) {
      filteredFormValues.forEach((student: any, studentIndex) => {
        formData.append(
          `students[${studentIndex}][student_class_id]`,
          student?.class_id
        );
        formData.append(`students[${studentIndex}][student_id]`, student?.id);

        student?.score?.forEach((score: any, scoreIndex: any) => {
          if (typeof score === "string") {
            switch (scoreIndex) {
              case 0:
                formData.append(
                  `students[${studentIndex}][first_test_score]`,
                  score
                );
                break;
              case 1:
                formData.append(
                  `students[${studentIndex}][second_test_score]`,
                  score
                );
                break;
              case 2:
                formData.append(
                  `students[${studentIndex}][third_test_score]`,
                  score
                );
                break;
              case 3:
                formData.append(`students[${studentIndex}][exam_score]`, score);
                break;
              case 4:
                formData.append(
                  `students[${studentIndex}][total_score]`,
                  score
                );
                break;
              default:
                break;
            }
          }
        });
      });
    }

    try {
      const response = await submitReport.mutateAsync(formData);

      if (response) {
        navigate("/teacher/course-report");
        formValues.length = 0;
        setLoading(false);
        toast.success(response?.message);
      }
    } catch (error: any) {
      if (error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
    }
  };
  const handleSelected = (i: any) => {
    setSelected(i);
  };

  // When you receive the data from the backend (assuming it's stored in a variable called `fetchedData`)
  useEffect(() => {
    if (courseData?.data?.length > 0) {
      // Extract course report information from the fetched data
      const courseReports = courseData?.data?.map((student: any) => ({
        class_id: student.student_class.class_id,
        id: student.id.toString(),
        score: [
          student?.course_report?.first_test_score,
          student?.course_report?.second_test_score,
          student?.course_report?.third_test_score,
          student?.course_report?.exam_score,
          student?.course_report?.total_score,
          student?.course_report?.grade,
        ],
      }));

      // Set the extracted course report data to the formValues state
      setFormValues(courseReports);
    }
  }, [courseData]); // Make sure to include fetchedData as a dependency if it changes

  const updatedFormValues = formValues.map((student) => {
    const scores = student?.score.slice(0, 4); // Get the first four scores

    const allValidScores = scores.every((score) => {
      const parsedScore = Number(score);
      return !isNaN(parsedScore) && parsedScore !== null && score !== "";
    }); // Check if all first four scores are valid numbers

    const sum = allValidScores
      ? scores.reduce((acc, val) => acc + Number(val || 0), 0)
      : null; // Calculate the sum if all first four scores are valid numbers

    const updatedScores = [...scores, sum?.toString() || null]; // Append the sum to the scores array or set it to null if not calculable

    return {
      ...student,
      score: [...updatedScores],
    };
  });

  const renderForm = () => (
    <>
      {courseData?.data?.map((item: any, index: number) => (
        <React.Fragment key={index}>
          {/* Other elements */}

          <div className="p-4 border text-brand text-sm h-10 text-left">
            <div className="flex justify-center flex-row items-center">
              <div>
                <p className="ml-2 text-xs">
                  {item.first_name} {item.last_name}
                </p>
              </div>
            </div>
          </div>
          {[...Array(6)].map((_, inputIndex) => (
            <div
              key={inputIndex}
              className="p-4 border text-brand text-sm h-10 text-left flex justify-center flex-row items-center"
            >
              <input
                className="border border-gray-300 w-10"
                value={
                  inputIndex === 4
                    ? formValues[index]?.score.slice(0, 4).reduce(
                        // Calculate the sum of the first four inputs
                        (acc, val) => acc + Number(val || 0),
                        0
                      )
                    : formValues[index]?.score[inputIndex] || ""
                }
                onChange={(e) => {
                  const updatedScores = [...(formValues[index]?.score || [])];
                  updatedScores[inputIndex] = e.target.value;

                  // Update the state including the sixth input
                  handleInputChange(
                    item.student_class.id,
                    item.id,
                    updatedScores,
                    index
                  );
                }}
                readOnly={inputIndex === 4} // Disable editing for the calculated sum field
              />
            </div>
          ))}
        </React.Fragment>
      ))}
    </>
  );
  const data = ["FIRST TERM", "SECOND TERM", "THIRD TERM"];
  return (
    <section className="p-4 pb-24 lg:pt-8 lg:pb-8 ">
      <div className="lg:flex justify-between">
        <DashboardHeader
          title="Course Report"
          subtitle="See and manage course reports here"
        />

        <div className="lg:flex justify-end items-center">
          <button
            type="button"
            className="flex bg-[#F7F7F7] w-fit h-fit py-3 px-8 rounded-lg gap-3 mb-4"
            onClick={() => setTermModal(true)}
          >
            <span className="font-[500]">{`${
              !term ? " Select term" : term
            }`}</span>
            <img src={ArrowDown} alt="arrow_down" className="pt-1" />
          </button>
          <button
            type="button"
            className="flex bg-[#F7F7F7] w-fit h-fit py-3 px-8 rounded-lg gap-3 ml-4"
            onClick={() => setCourseModal(true)}
          >
            <span className="font-[500]">
              {`${!selectedCourse ? " Select course 1" : selectedCourse?.name}`}
            </span>
            <img src={ArrowDown} alt="arrow_down" className="pt-1" />
          </button>
        </div>
      </div>
      <div className="mt-3 mb-5 justify-between lg:flex">
        <div>
          {selectedCourse?.classes?.map((item: any, i: number) => (
            <span
              key={i}
              className={`${
                item === selected
                  ? "text-blue-500 border-b-2 border-blue-500 p-2 mb-4"
                  : "text-gray-500 p-2"
              } inline-block transition-all duration-300 cursor-pointer`}
              onClick={() => handleSelected(item)}
            >
              {item?.name}
            </span>
          ))}
        </div>
        <div className="flex gap-5 lg:flex-row xs:flex-col">
          <Button
            isLoading={loading}
            title=" Submit report"
            className="bg-brand  w-40 py-3"
            textColor="text-white"
            onClick={handleSubmit}
          />
        </div>
      </div>

      <div className=" lg:grid grid-cols-7 text-center overflow-auto">
        {renderHeaders()}
        {renderForm()}
      </div>

      {modal && (
        <SuccessSave
          isOpen={() => setModal(true)}
          isClose={() => setModal(false)}
        />
      )}

      <CourseModal
        isOpen={courseModal}
        isClose={() => setCourseModal(false)}
        data={subjectData}
        onSelect={selectedValue}
      />
      <Modal open={termModal} close={() => setTermModal(false)} maxWidth="xs">
        <div className="px-7 pb-8">
          <Typography textStyle="text-center font-semibold text-lg">
            Select Term
          </Typography>

          <div className="flex flex-col gap-8 mt-7 overflow-y-scroll">
            {data?.map((item: any) => (
              <span
                key={item}
                className="cursor-pointer "
                onClick={() => {
                  setTerm(item);
                  setTermModal(false);
                }}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default EditReport;

import { useQuery } from "react-query";
import DashboardHeader from "../../../components/headers/dashboard-header";
import TeacherContent from "./components/teacherContent";
import { getDashboardAPI } from "../../../services/Teacher/dashboard";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const Dashboard = () => {
  const { data: dashboardData, refetch } = useQuery(
    "dashboard",
    getDashboardAPI
  );
  const darkMode = useSelector(selectDarkmode);
  return (
    <div
      className={`p-4 pb-24 lg:pt-8 lg:pb-8 ${
        darkMode ? "bg-dark" : "bg-white"
      }`}
    >
      <div className=" flex flex-col gap-1">
        <DashboardHeader
          title="DashBoard 😊"
          subtitle="Manage and track your activities and progress here"
        />
      </div>

      <div className="">
        <TeacherContent data={dashboardData?.data} refetch={refetch} />
      </div>
    </div>
  );
};

export default Dashboard;

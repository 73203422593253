import { Transition } from "@headlessui/react";
import { useState } from "react";
import { FaChevronRight, FaChevronUp } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LOGO, logout, school } from "../../assets";
import { useAppDispatch, useAppSelector, useMediaQuery } from "../../hooks";
import {
  reset,
  selectDarkmode,
  selectRole,
} from "../../states/slices/authReducer";
import Typography from "../Typography";
import Button from "../button";
import {
  AdminBarData,
  ParentBarData,
  SidebarDataTypes,
  StudentBarData,
  TeacherBarData,
} from "./SidebarData";
import { useSelector } from "react-redux";

interface sidebarProps {
  open: boolean;
  onClose: () => void;
}

export const Sidebar = (props: sidebarProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ROLE = useAppSelector(selectRole);
  const navigate = useNavigate();
  const darkMode = useSelector(selectDarkmode);
  return (
    <Transition
      className={`flex-none h-full w-60 lg:w-[16vw]  fixed lg:static z-30 lg:z-0`}
      as={"div"}
      show={props.open}
      enter="transition-all ease-in duration-500"
      enterFrom="transform -translate-x-full"
      enterTo="transform -translate-x-0"
      leave="transition-all ease-out duration-500"
      leaveFrom="transform -translate-x-0"
      leaveTo="transform -translate-x-full"
    >
      <div
        className={`h-full lg:bg-deep-surface 
					${!darkMode ? " text-gray-600" : "text-white bg-dark"}  relative`}
      >
        <div
          className={`h-[10vh]  					${
            !darkMode ? "bg-white text-gray-600" : "text-white bg-dark"
          }  flex justify-start items-center`}
        >
          <div className=" w-24 h-16 ml-10">
            <Link to="/student/home">
              <img src={school} className="h-full w-full" alt="...logo" />
            </Link>
          </div>
        </div>

        <div
          className={`  flex flex-col  justify-between h-[90vh] pb-10 pt-8  rounded-tr-lg ${
            ROLE === "ADMIN"
              ? "bg-white lg:bg-adminbg bg-contain no-repeat"
              : "bg-brand"
          }`}
        >
          <div className={`${ROLE === "ADMIN" ? "overflow-y-auto" : ""}`}>
            <ul>
              <Tabs
                {...{
                  data:
                    ROLE === "STUDENT"
                      ? StudentBarData
                      : ROLE === "TEACHER"
                      ? TeacherBarData
                      : ROLE === "ADMIN"
                      ? AdminBarData
                      : ROLE === "PARENT"
                      ? ParentBarData
                      : undefined,
                  className: "hover:font-medium",
                  onClose: props.onClose,
                  active: (item: SidebarDataTypes) =>
                    location.pathname === item.path,
                }}
              />
            </ul>
          </div>

          <div
            className={
              ROLE === "ADMIN"
                ? "hidden"
                : "flex flex-col  justify-start items-start pl-5 "
            }
          >
            <Button
              icons={logout}
              title="Logout"
              bgColor={"bg-transparent"}
              onClick={() => {
                navigate("/");
                dispatch(reset());
              }}
              hoverColor={""}
              textColor={"text-white "}
              className={"border border-transparent"}
            />
          </div>
        </div>
      </div>
    </Transition>
  );
};

interface tabsProps {
  data: SidebarDataTypes[] | undefined;
  active: any;
  className?: string;
  containerClassName?: string;
  onClose: () => void;
}
const Tabs = ({
  data,
  active,
  className,
  containerClassName,
  onClose,
}: tabsProps) => {
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const ROLE = useAppSelector(selectRole);
  const location = useLocation();
  const darkMode = useSelector(selectDarkmode);
  // Use state to manage the dropdown visibility
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [id, setId] = useState();
  return (
    <>
      {data !== undefined &&
        data?.map((item: any) => (
          <li
            onClick={isMobileView || isTabletView ? onClose : () => {}}
            key={item.id}
            className={`${containerClassName}`}
          >
            {/* Check if the item has dropdown options */}
            {item.dropdown ? (
              <div className="relative rounded-l-xl">
                {/* Dropdown trigger */}
                <div
                  onClick={() => {
                    setDropdownVisible(!dropdownVisible);
                    setId(item.id);
                  }}
                  className={`w-full items-center ${
                    !darkMode ? "bg-white text-gray-600" : "text-white bg-dark"
                  }  ${className} ${
                    id === item.id
                      ? ` ${
                          ROLE === "ADMIN"
                            ? "border-brand border-tertiary  bg-brand rounded-tl-xl !text-white font-medium cursor-pointer"
                            : ` border-white border-tertiary  ${
                                !darkMode
                                  ? "bg-white text-gray-600"
                                  : "text-white bg-dark"
                              } bg-gradient-to-r from-brand to-white rounded-l-xl !text-[#EF2C5A] font-medium cursor-pointer`
                        }`
                      : ""
                  }`}
                >
                  <div
                    className={` w-full items-center pb-2  ${className} ${
                      id === item.id
                        ? ` ${
                            ROLE === "ADMIN"
                              ? "bg-white rounded-br-xl "
                              : "  bg-brand rounded-br-xl "
                          }`
                        : ""
                    }`}
                  ></div>
                  <Link to={item.dropdown[0]} className="hover:text-black">
                    <div
                      className={`  flex w-full items-center py-2 pl-5 transition-all ease-in-out  ${
                        ROLE === "ADMIN" && item.id === id
                          ? "text-brand"
                          : "text-brand"
                      } ${className} ${
                        active(item)
                          ? ` ${
                              ROLE === "ADMIN"
                                ? "border-tertiary bg-brand rounded-l-xl !text-white font-medium"
                                : `border-tertiary ${
                                    !darkMode
                                      ? "bg-white !text-[#EF2C5A]"
                                      : "text-white bg-dark"
                                  } rounded-l-xl  font-medium`
                            }`
                          : ""
                      }`}
                    >
                      {/* {dropdownVisible && id === item.id ? (
												<img src={item.imageURL} alt='' className='h-5  mr-4' />
											) : ( */}
                      <img src={item.imageURL} alt="" className="h-5  mr-4" />

                      <Typography variant="label">{item.title}</Typography>

                      {dropdownVisible && id === item.id ? (
                        <FaChevronUp className="h-3 ml-auto mr-4" />
                      ) : (
                        <FaChevronRight className="h-3 ml-auto mr-4" />
                      )}
                    </div>
                  </Link>
                  {/* Dropdown options */}
                  {dropdownVisible && id === item.id && (
                    <ul className="absolute top-full  left-0 z-10 rounded-bl-xl bg-brand text-white text-xs w-full pl-5 pb-2">
                      {item.dropdown.map((option: any) => (
                        <li key={option.id}>
                          <Link
                            to={option.path}
                            className={`${
                              location.pathname === option.path
                                ? "bg-white text-brand rounded-l-xl mt-2"
                                : ""
                            } flex items-center gap-4 py-2 px-4`}
                          >
                            {/* <img src={option.img} alt="" /> */}
                            {location.pathname === option.path ? (
                              <img src={option.img_active} alt="" />
                            ) : (
                              <img src={option.img} alt="" />
                            )}
                            {option.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              // Render regular link if there are no dropdown options
              <Link to={item.path} className="hover:text-black bg-green-600">
                <div
                  onClick={() => {
                    setDropdownVisible(false);
                    setId(undefined);
                  }}
                  className={`   w-full items-center   text-white ${className} ${
                    active(item)
                      ? ` ${
                          ROLE === "ADMIN"
                            ? "border-brand border-tertiary  bg-brand bg-gradient-to-r from-white to-brand rounded-l-xl !text-white font-medium"
                            : ` border-white border-tertiary  ${
                                !darkMode
                                  ? "bg-white !text-[#EF2C5A]"
                                  : "text-white bg-dark"
                              }  bg-gradient-to-r from-brand to-white  rounded-l-xl !text-[#EF2C5A] font-medium`
                        }`
                      : ""
                  }`}
                >
                  <div
                    className={`   w-full items-center pb-2  ${className} ${
                      active(item)
                        ? ` ${
                            ROLE === "ADMIN"
                              ? "bg-white rounded-br-xl "
                              : "  bg-brand rounded-br-xl "
                          }`
                        : ""
                    }`}
                  ></div>

                  <div
                    className={`  flex w-full items-center py-2 pl-5  ${
                      ROLE === "ADMIN" ? "text-brand" : "text-white"
                    } ${className} ${
                      active(item)
                        ? ` ${
                            ROLE === "ADMIN"
                              ? "border-tertiary bg-brand rounded-l-xl !text-white font-medium"
                              : `border-tertiary   rounded-l-xl ${
                                  !darkMode
                                    ? "bg-white !text-[#EF2C5A]"
                                    : "text-white bg-dark"
                                }  font-medium`
                          }`
                        : ""
                    }`}
                  >
                    <img
                      src={active(item) ? item.image_active : item.imageURL}
                      className="h-5  mr-4"
                      alt={item.alt}
                    />
                    <Typography variant="label">{item.title}</Typography>
                  </div>
                  <div
                    className={`   w-full items-center pt-2  ${className} ${
                      active(item)
                        ? `${
                            ROLE === "ADMIN"
                              ? "bg-white rounded-tr-xl"
                              : "bg-brand rounded-tr-xl"
                          }`
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
            )}
          </li>
        ))}
    </>
  );
};

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { LOGO, left, success } from '../../../assets';
import Button from '../../../components/button';

const Successful = () => {
	return (
		<div className='w-full min-h-screen bg-login'>
			<div className='flex justify-between'>
				<button type='button' className=''>
					<img src={left} width={50} height={30} alt='left' className='absolute mt-7 ml-7' />
				</button>
				<div className='absolute w-[500px] h-[650px] bg-slate-50 right-0 mt-4 mr-14 rounded-lg'>
					<div className='bg-[#93002F] w-full h-2 rounded-t-lg' />
					<div className='mx-10 my-10 flex flex-col'>
						<img src={LOGO} width={150} height={100} alt='just image ' className='pt-5 pb-10' />
						<div className='flex flex-col items-center justify-center gap-5'>
							<img src={success} width={90} height={90} alt='good' />
							<span className='text-2xl font-[550]'>Password reset successful!</span>
							<div className='justify-center items-end flex'>
								<span className='text-[#1D2433] text-center'>
									Your password as been changed successfully click button below to log in with your
									new pasword.
								</span>
							</div>
						</div>
						{/* <Button
              text="Login"
              textStyles="text-white"
              btnStyles="bg-[#93002F] py-3 mt-8 rounded-lg"
            /> */}
						<Button
							iconClassName='h-[0.9rem] w-3'
							// isLoading={loading}
							title={'Login'}
							bgColor={'bg-brand'}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-4 rounded-lg bg-other w-full mt-4'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Successful;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import DashboardHeader from '../../../components/headers/dashboard-header';
import EventList from '../../../components/boxes/EventList';
import Modal from '../../../components/Modal';
import Button from '../../../components/button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { TableComponent } from '../../../components/table/Table';
import Typography from '../../../components/Typography';

import { useQuery } from 'react-query';
import { getStudentDashboard } from '@/services/student/dashboard';
import EmptyStudentData from '@/pages/admin/Students/contents/emptyStudentData';
import { Skeleton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { ArrowDown, BOOK, WHITE_ARROW_DOWN } from '@/assets';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

function Dashboard() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenDate, setIsOpenDate] = useState<boolean>(false);
	const [page, setPage] = useState(1);
	const [termModal, setTermModal] = useState(false);
	const [term, setTerm] = useState('FIRST TERM');
	const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
	const [dateState, setDateState] = useState<string>('today');
	const [size, setSize] = useState(3);

	const filterParam = {
		date: dateState,
		term,
	};
	const { data: getDashboardData, refetch } = useQuery(['studentdashboard', filterParam], () =>
		getStudentDashboard(filterParam)
	);

	const SCHOOL_SUBJECT_COLUMNS = [
		{
			Header: 'SUBJECT TITLE',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.subject?.name || 'Not available'}</span>
				</div>
			),
		},
		{
			Header: 'FIRST TEST',

			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.first_test_score || 'Not available'}</span>
				</div>
			),
		},
		{
			Header: 'SECOND TEST',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.second_test_score || 'Not available'}</span>
				</div>
			),
		},
		{
			Header: 'EXAMINATION',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.exam_score || 'Not available'}</span>
				</div>
			),
		},
		{
			Header: 'TOTAL SCORE',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.total_score || 'Not available'}</span>
				</div>
			),
		},
		{
			Header: 'GRADE',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>{row.original?.grade || 'Not available'}</span>
				</div>
			),
		},
	];

	useEffect(() => {
		refetch();
	}, [dateState, term]);

	const data = ['FIRST TERM', 'SECOND TERM', 'THIRD TERM'];
	const dateArray = ['today', 'this_week', 'this_month'];
	const darkMode = useSelector(selectDarkmode);
	return (
		<div className='px-4  pt-10'>
			<DashboardHeader
				title={'Dashboard 😊'}
				subtitle='Manage and track your activities and progress here'
			/>
			<div>
				<div className='flex justify-between items-start flex-col lg:flex-row'>
					<div className='w-full lg:w-[39%] mb-4 mr-4  '>
						<div className='flex justify-between p-5'>
							<span
								className={`text-sm font-bold text-black ${
									darkMode ? 'text-gray-300' : 'text-black'
								}`}
							>
								Class Schedule
							</span>

							<button
								type='button'
								className={`flex  ${
									darkMode ? 'bg-black' : 'bg-[#F7F7F7]'
								}  w-fit h-fit py-3 px-8 rounded-lg gap-3`}
								onClick={() => setIsOpenDate(true)}
							>
								<span className='font-[500] capitalize'>{`${
									!dateState
										? ' Select term'
										: `${
												dateState === 'this_week'
													? 'This Week'
													: dateState === 'this_month'
													? 'This month'
													: 'Today'
										  }`
								}`}</span>
								{darkMode ? (
									<img src={WHITE_ARROW_DOWN} alt='arrow_down' className='pt-[0.3rem] w-4' />
								) : (
									<img src={ArrowDown} alt='arrow_down' className='pt-1' />
								)}
							</button>
						</div>
						{getDashboardData?.data?.class_time_tables?.length > 0 ? (
							getDashboardData?.data?.class_time_tables?.map((event: any, i: number) => (
								<div
									key={i}
									className='px-4 lg:px-6 w-full flex justify-start items-center mb-4 bg-gray-50'
								>
									<div className='mt-2  flex flex-col justify-between border-r-4 border-r-green-500 pr-4'>
										<p className='text-[#A2A2A2]/80 text-xs mb-4'>{event?.start_time}</p>
										<p className='text-[#A2A2A2]/80 text-xs'>{event?.end_time}</p>
									</div>
									<div className=' flex flex-col rounded-xl py-2 px-4'>
										<div className='flex justify-start'>
											<div className='flex flex-col'>
												<p className='text-xs text-black font-fold'>
													<span className='font-bold'>{event?.subject?.name}</span>{' '}
													<span className=''>
														( {event?.start_time} - {event?.end_time} )
													</span>
												</p>
												<small className='text-gray-500 text-[10px] pt-1'>
													{event?.class?.name}
												</small>
											</div>
										</div>
									</div>
								</div>
							))
						) : getDashboardData?.data?.class_time_tables?.length < 1 ? (
							<div
								className={` h-60 px-4 lg:px-6 w-full flex flex-col justify-center items-center mb-4 ${
									darkMode ? 'bg-black' : 'bg-white'
								} rounded-xl `}
							>
								<p className='text-center text-sm mt-20'>You do not have an event for this date!</p>

								<div>
									<img src={BOOK} className='w-60 h-60 rounded-lg' />
								</div>
							</div>
						) : (
							<div className='w-full mb-3 px-8 flex justify-between items-center'>
								<Skeleton
									variant='rectangular'
									height={50}
									className='w-[50px] rounded-full mb-4'
								/>
								<div className='w-[80%]'>
									<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
									<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
								</div>
							</div>
						)}
					</div>
					<div className='w-full lg:w-[59%]  mb-4 '>
						<div className='flex justify-between items-start flex-wrap w-full px-4 pt-8'>
							<div className='w-full lg:w-[49%]'>
								<EventList setIsOpen={setIsOpen} data={getDashboardData?.data} />
							</div>
							<div
								className={`w-full lg:w-[49%] shadow-box rounded-lg ${darkMode ? 'bg-black' : ''}`}
							>
								<div className='p-5'>
									<span
										className={`text-sm font-bold  ${darkMode ? 'text-gray-300' : 'text-black'}`}
									>
										School calendar
									</span>
								</div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateCalendar value={date} onChange={(newValue) => setDate(newValue)} />
								</LocalizationProvider>
							</div>
						</div>
						<div className='w-full '>
							<div className='w-full pt-6 pb-20 lg:pb-8'>
								<div className='bg-white'>
									<div className='flex justify-between items-start w-full mb-2'>
										<div className='bg-transparent  flex  justify-between items-center p-3'>
											<Typography textStyle=' text-base font-medium'>Subject list</Typography>
										</div>
										<button
											type='button'
											className='flex bg-[#F7F7F7] w-fit h-fit py-3 px-8 rounded-lg gap-3'
											onClick={() => setTermModal(true)}
										>
											<span className='font-[500]'>{`${!term ? ' Select term' : term}`}</span>
											<img src={ArrowDown} alt='arrow_down' className='pt-1' />
										</button>
									</div>
									<div className='w-full justify-center items-center px-4'>
										{getDashboardData && getDashboardData?.data?.course_reports?.length > 0 ? (
											<TableComponent
												className='rounded-none rounded-t-0'
												DATA={getDashboardData?.data?.course_reports}
												COLUMNS={SCHOOL_SUBJECT_COLUMNS}
												page={page}
												setPage={setPage}
												isNotPaginanated={true}
											/>
										) : getDashboardData?.data?.course_reports?.length < 1 ? (
											<div className='text-center text-xs'>
												<EmptyStudentData
													noStudentRegister
													textMessage='No course report available.'
												/>
											</div>
										) : (
											<div className='w-full mb-3 px-8 flex justify-between items-center'>
												<Skeleton
													variant='rectangular'
													height={50}
													className='w-[50px] rounded-full mb-4'
												/>
												<div className='w-[80%]'>
													<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
													<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal open={termModal} close={() => setTermModal(false)} maxWidth='xs'>
				<div className='px-7 pb-8'>
					<Typography textStyle='text-center font-semibold text-lg'>Select Term</Typography>

					<div className='flex flex-col gap-8 mt-7 overflow-y-scroll'>
						{data?.map((item: any) => (
							<span
								key={item}
								className='cursor-pointer '
								onClick={() => {
									setTerm(item);
									setTermModal(false);
								}}
							>
								{item}
							</span>
						))}
					</div>
				</div>
			</Modal>
			<Modal open={isOpenDate} close={() => setIsOpenDate(false)} maxWidth='xs'>
				<div className='px-7 pb-8'>
					<Typography
						textStyle={`text-center font-semibold text-lg ${darkMode ? 'text-other' : ''}`}
					>
						Select Term
					</Typography>

					<div className='flex flex-col gap-8 mt-7 overflow-y-scroll'>
						{dateArray?.map((item: any) => (
							<span
								key={item}
								className={`cursor-pointer ${
									darkMode ? 'hover:bg-other text-gray-300' : 'hover:bg-gray-300 text-black'
								}  p-3 rounded-xl `}
								onClick={() => {
									setDateState(item);
									setIsOpenDate(false);
								}}
							>
								{item === 'this_week'
									? 'This Week'
									: item === 'this_month'
									? 'This month'
									: 'Today'}
							</span>
						))}
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default Dashboard;

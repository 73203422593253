/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { avatar, avatar_ } from "../../assets";
import Modal from "../Modal";
import Button from "../button";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

type Props = {
  title: string;
  start_date: string;
  end_date: string;
  location: string;
};
function EventList(props?: any) {
  const [selected, setSelected] = useState<Props | null>(null);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const handleSelected = (item: Props) => {
    setSelected(item);
  };

  const darkMode = useSelector(selectDarkmode);
  return (
    <div
      className={`shadow-box rounded-xl w-full h-fit flex flex-col pb-6 ${
        darkMode ? "bg-black" : ""
      }`}
    >
      <div className="flex justify-between p-5">
        <p
          className={`text-sm font-bold  ${
            darkMode ? "text-gray-300" : "text-black"
          }`}
        >
          Events list
        </p>
        <p
          className="text-sm font-bold text-brand cursor-pointer"
          onClick={() => navigate("/student/events")}
        >
          See All
        </p>
      </div>

      {props?.data?.events?.length > 0 ? (
        props?.data?.events?.map((event_data: any, i: number) => (
          <div
            key={i}
            className="px-3 w-full bg-gray-50  flex justify-between items-center mb-4 "
          >
            <div className="flex flex-col rounded-xl py-2">
              <div className="flex justify-evenly">
                <div>
                  <img
                    src={event_data?.image_path ?? avatar_}
                    alt="profile image"
                    className="rounded-lg w-12 h-12 "
                  />
                </div>
                <div className="flex flex-col ml-2">
                  <span className="text-xs text-black font-fold">
                    {event_data?.title}
                  </span>
                  <div className="text-center text-xs mt-2">
                    <div>
                      <span className=" text-[#A2A2A2] text-xs">
                        {moment(event_data?.start_date).format(
                          "Do MMM, YYYY, hh:mma"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : props?.data?.events.length < 1 ? (
        <div className="text-center">{`no events created`}</div>
      ) : (
        <div className="w-full mb-3 px-8 flex justify-between items-center">
          <Skeleton
            variant="rectangular"
            height={50}
            className="w-[50px] rounded-full mb-4"
          />
          <div className="w-[80%]">
            <Skeleton
              variant="rectangular"
              height={20}
              className="w-full mb-4"
            />
            <Skeleton
              variant="rectangular"
              height={20}
              className="w-[80%] mb-4"
            />
          </div>
        </div>
      )}

      {/* active */}

      <Modal open={modal} close={() => setModal(false)} maxWidth="xs">
        <div className="px-8">
          <div className="flex flex-col text-center mb-8">
            <span className="text-slate-950 font-bold text-2xl">
              Event Details
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event title:</p>
              <p className="font-semibold text-sm"> {selected?.title}</p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Start date:</p>
              <p className="font-semibold text-sm">{selected?.start_date} </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">End date:</p>
              <p className="font-semibold text-sm">{selected?.end_date} </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Location:</p>
              <p className="font-semibold text-sm">{selected?.location}</p>
            </div>
          </div>

          <div className="py-8 ">
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-brand"}
              onClick={() => {
                setModal(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EventList;

import { useState } from "react";

import ThemeBox from "./boxes/ThemeBox";
import PasswordChange from "@/components/form/others/PasswordChange";
import DashboardHeader from "@/components/headers/dashboard-header";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

interface Props {
  label: string;
  content: JSX.Element;
}
const headingLinks: Props[] = [
  {
    label: "Password setting",
    content: (
      <div>
        <PasswordChange />
      </div>
    ),
  },
  {
    label: " Display theme",
    content: (
      <div>
        <ThemeBox />
      </div>
    ),
  },
];
function Settings() {
  const [selected, setSelected] = useState<number | null>(0);
  const darkMode = useSelector(selectDarkmode);
  const handleSelected = (index: number) => {
    setSelected(index);
  };
  return (
    <div
      className={`p-4 pb-24 lg:pt-8 lg:pb-8 ${
        !darkMode ? "bg-white text-dark-200" : "text-white bg-dark-200"
      } `}
    >
      <DashboardHeader
        title={"Setting ⚙️"}
        subtitle="Manage your account settings here"
      />
      <div>
        {headingLinks.map((links, index) => (
          <span
            key={index}
            onClick={() => handleSelected(index)}
            className={`${
              index === selected
                ? `${
                    !darkMode
                      ? " text-blue-500 border-b-2 border-blue-500"
                      : "text-red-500 border-b-2 border-red-500"
                  } p-2 `
                : `${
                    !darkMode
                      ? "bg-white text-gray-600"
                      : "text-white bg-dark-200"
                  }`
            } inline-block transition-all duration-300 cursor-pointer`}
          >
            {links.label}
          </span>
        ))}
      </div>
      <div
        className={`mt-10 w-full px-10 ${
          darkMode ? "border-gray-700" : ""
        } border-[1px] shadow-sm rounded-lg py-10`}
      >
        {selected !== null ? headingLinks[selected].content : null}
      </div>
    </div>
  );
}

export default Settings;

import { useState } from "react";
import DashboardHeader from "../../components/headers/dashboard-header";
import { ArrowDown } from "../../assets";
import { FaArrowUp } from "react-icons/fa";
import { Fade } from "react-reveal";

const faqs = [
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
  {
    label: "Headline",
    details:
      "Heading nothing is here for now sha working on some upgrade Heading nothing is here for now sha working on some upgrade",
  },
];
const Help = () => {
  const [selected, setSelected] = useState<number | null>(null);

  const handleClicked = (index: number) => {
    setSelected(index);
  };
  return (
    <main className="px-8 mt-7">
      <DashboardHeader
        title="Help 🆘"
        subtitle="Get help from us here and see frequently asked questins."
      />

      <div className="mt-7 border w-full h-[822px] rounded-lg bg-white shadow-box lg:px-10 xs:px-4 pt-5">
        <span className="text-lg">Frequently asked questions</span>
        <div className="border-2 w-full mt-12 " />
        <Fade left>
          <div className="flex flex-col gap-5 pt-6 px-10">
            {faqs.map((item, i) => (
              <div key={i}>
                <div className="flex justify-between pb-2">
                  <span>{item.label}</span>
                  {selected !== i ? (
                    <img
                      src={ArrowDown}
                      alt=""
                      onClick={() => handleClicked(i)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <span
                      onClick={() => setSelected(null)}
                      className="cursor-pointer text-gray-500 "
                    >
                      <FaArrowUp />
                    </span>
                  )}
                </div>

                <div
                  className={`max-h-fit transition ${
                    selected === i ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  {selected === i ? (
                    <span className="text-gray-500">{item.details}</span>
                  ) : (
                    ""
                  )}
                  <div className="border-2 w-full mt-9 " />
                </div>
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </main>
  );
};

export default Help;

import { API } from '../config';

export async function getProfileAPI() {
	const response = await API.get(`profile`);
	return response.data?.data;
}

export async function updateAdminAPI(formData: any, id: any) {
	const response = await API.post(`update-admin/${id}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function updateClassAPI(formData: any, id: any) {
	const response = await API.post(`admin/classes/${id}/update`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function updateStudentAPI(formData: any, id: string | number) {
	const response = await API.post(`update-student/${id}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}

export async function updateTeacherAPI(formData: any, id: any) {
	const response = await API.post(`update-teacher/${id}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}
export async function updateParentAPI(formData: any, id: any) {
	const response = await API.post(`update-parent/${id}`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data;
}
export async function getEventAPI(param: any) {
	const response = await API.get(`events?page=${param?.page}&title=${param?.search}`);
	return response.data;
}

import React from 'react';
import { checkboxPropTypes } from '../../types';

function Checkbox(props: checkboxPropTypes) {
	return (
		<>
			<input className='w-5 h-5 text-gray-500 bg-gray-100 border-gray-300 rounded focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' type="checkbox" name="" />
		</>
	);
}

export default Checkbox;

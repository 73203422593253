import { API } from "@/config";

type passwordchangeProps = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

export async function ChangePassword({
  old_password,
  new_password,
  confirm_password,
}: passwordchangeProps) {
  const response = await API.post("/profile/change-password", {
    old_password,
    new_password,
    confirm_password,
  });
  return response.data;
}

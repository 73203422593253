import { babe } from '@/assets';
import DashboardHeader from '@/components/headers/dashboard-header';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';

function ViewStudents() {
	const selectedStudent = useLocation().state?.data;

	return (
		<div className='mt-4 w-full lg:px-12 px-0'>
			<DashboardHeader title='View Student 😊' subtitle=" View student's information here" />
			<div className='border-[0.5px] rounded-lg w-full px-10 py-7 h-fit shadow-sm mb-9'>
				<span className='font-[500]'>Student Information</span>
				<div className='lg:flex mt-5 gap-4 pb-11'>
					<div>
						<img src={selectedStudent?.profile_image ?? babe} width={100} height={100} alt='babe' />
					</div>
					<div className='flex justify-between flex-wrap w-full text-sm '>
						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Full name</span>
							<span className='font-[500] text-gray-800'>
								{`${selectedStudent?.first_name}  ${selectedStudent?.last_name}`}
							</span>
						</div>

						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Date of birth</span>
							<span className='font-[500] text-gray-800'>
								{moment(selectedStudent?.dob).format('do MMM, YYYY')}
							</span>
						</div>

						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Gender</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.gender}</span>
						</div>
						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Admission number</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.unique_number}</span>
						</div>
						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Address</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.address}</span>
						</div>
						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Current class</span>
							<span className='font-[500] text-gray-800'>
								{selectedStudent?.student_class?.class?.name}
							</span>
						</div>
						<div className='flex flex-col lg:pl-16 w-[48%] lg:w-1/3 mt-4 lg:mt-0 mb-4'>
							<span className='text-gray-500'>Date joined</span>
							<span className='font-[500] text-gray-800'>
								{moment(selectedStudent?.date_joined).format('do MMM, YYYY')}
							</span>
						</div>
					</div>
				</div>
				<hr className='px-6 pt-6' />

				<div className='mt-5 pb-10 text-sm'>
					<span className='font-[500] pt-3'>Medical information</span>

					<div className='flex justify-between'>
						<div className='flex flex-col mt-3'>
							<span className='text-gray-500'> Blood group</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.blood_group}</span>
						</div>
						<div className='flex flex-col mt-3'>
							<span className='text-gray-500'> Genotype</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.genotype}</span>
						</div>
						<div className='flex flex-col mt-3'>
							<span className='text-gray-500'>Disability</span>
							<span className='font-[500] text-gray-800'>{selectedStudent?.disability}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewStudents;

import { avatar_, upload } from '@/assets';
import Button from '@/components/button';
import DashboardHeader from '@/components/headers/dashboard-header';
import { useAppSelector } from '@/hooks';
import { selectUser } from '@/states/slices/authReducer';
import { UserData } from '@/types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function Profile() {
	const data: UserData = useAppSelector(selectUser);
	const navigate = useNavigate();
	return (
		<div className='mt-6 lg:px-8 px-1'>
			<DashboardHeader
				title={'Profile 🧑'}
				subtitle='Manage your personal information details here'
			/>
			<div className='mt-10 w-full border shadow-sm rounded-lg p-4 lg:p-16'>
				<div className='border p-3 lg:p-10 rounded-lg relative'>
					<div className='flex justify-between'>
						<p className='font-bold text-sm'>Personal information</p>

						{/* <div className='w-32'>
							<Button
								iconClassName='h-[0.9rem] w-3'
								onClick={() => navigate(`edit-parent?id=${data?.id}`)}
								title={'Edit information'}
								bgColor={'bg-other'}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 rounded-lg bg-brand    '}
							/>
						</div> */}
					</div>

					<div className='lg:flex justify-between pt-6'>
						<div className=' relative w-1/2 lg:w-1/4 pr-16'>
							<img
								src={data?.profile_image ?? avatar_}
								alt='profile'
								className='rounded-lg w-full h-full'
							/>
							<img
								src={upload}
								alt='profile'
								width={50}
								height={50}
								className='absolute -bottom-5 left-16'
							/>
						</div>
						<div className=' relative w-full lg:w-3/4 flex justify-between items-start flex-wrap'>
							<div className='w-1/2 lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Full Name</p>
								<p className='text-sm font-semibold'>{`	${data?.first_name} ${data?.last_name}`}</p>
							</div>
							<div className='w-1/2 lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Phone number</p>
								<p className='text-sm font-semibold'>{data?.phone_number}</p>
							</div>
							<div className='w-1/2 lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Residential address</p>
								<p className='text-sm font-semibold'>{data?.address}</p>
							</div>
							<div className='w-1/2 lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Gender</p>
								<p className='text-sm font-semibold'>{data?.gender}</p>
							</div>

							<div className='w-full lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Email address</p>
								<p className='text-sm font-semibold'>{data?.email}</p>
							</div>
							<div className='w-1/2 lg:w-1/3 mb-4'>
								<p className='text-gray-600 text-sm'>Date of birth</p>
								<p className='text-sm font-semibold'>{moment(data?.dob).format('Do MMM, YYYY')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Profile;

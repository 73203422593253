import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { add, exports, filter, print } from "../../../assets";
import { Link, useNavigate } from "react-router-dom";
import ClassCard from "./components/Cards";
import { useMutation, useQuery } from "react-query";
import {
  deleteClass,
  getClass,
  getSection,
} from "../../../services/Admin/dashboard";
import NoClass from "../molecules/NoClass";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const icons = [filter, print, exports];
function Classes() {
  const [classDrop, setclassDrop] = useState(false);
  const { data: sectionData } = useQuery("section", getSection);
  const [popUp, setPopUp] = useState(false);
  const deleteClassMutation = useMutation(deleteClass);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>("");
  const { data: classData, refetch } = useQuery(["class", selected], () =>
    getClass(selected)
  );

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) {
        setclassDrop(false);
      } else {
        setclassDrop(true);
      }
    };

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);

    return () => window.removeEventListener("resize", checkWindowSize);
  }, []);

  useEffect(() => {
    setSelected(sectionData?.data[0]);
  }, [sectionData]);

  const handleSelected = (item: string) => {
    setSelected(item);
  };

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      const res: any = await deleteClassMutation?.mutateAsync(id);
      if (res) {
        refetch();
        setLoading(false);
        toast.success(res?.message);
        setConfirmationModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const darkMode = useSelector(selectDarkmode);

  return (
    <section className="mt-6 lg:px-8 px-1">
      <DashboardHeader
        title="Classes"
        subtitle="Create and manage classes here"
      />

      <div className="flex justify-between flex-wrap">
        <span
          className={`hidden xs:block lg:hidden w-fit h-fit border rounded-lg px-3 py-1  `}
          onClick={() => setclassDrop(true)}
        >
          Select class
        </span>
        {sectionData && (
          <div className="relative lg:relative xs:absolute bg-transparent lg:bg-transparent xs:bg-white shadow-none xs:shadow-md lg:shadow-none rounded-none xs:rounded-lg lg:rounded-none">
            {sectionData?.data?.map((item: string, i: number) => (
              <span
                className={`${
                  item === selected
                    ? ` border-b-2  p-2 ${
                        darkMode
                          ? "text-red-500 border-red-500"
                          : "text-blue-500  border-blue-500"
                      }`
                    : "text-gray-500 p-2"
                } inline-block lg:inline-block xs:block transition-all duration-300 capitalize cursor-pointer text-sm`}
                onClick={() => handleSelected(item)}
              >
                {item?.toLowerCase()} classes
              </span>
            ))}
          </div>
        )}
        <div className="flex lg:gap-7 xs:gap-4 lg:flex-row xs:flex-col">
          <Link to="/admin/classes/create-class" className="">
            <button
              type="button"
              className="bg-[#93002F] py-3 px-5 w-full text-white text-sm rounded-lg flex gap-3 hover:scale-90 transition-all"
            >
              <img src={add} alt="" width={20} height={20} />
              <span>Create new class</span>
            </button>
          </Link>
        </div>
      </div>
      <div
        className={`mt-5 w-full h-full lg:px-8 px-4 border shadow-sm rounded-lg lg:py-10 py-4  grid grid-cols-1 lg:grid-cols-3 gap-3 ${
          darkMode ? "border-gray-700" : ""
        } `}
      >
        {classData?.data?.length > 0 ? (
          classData?.data?.map((item: any) => (
            <ClassCard
              data={item}
              popUp={popUp}
              setPopUp={setPopUp}
              handleView={() =>
                navigate(`/admin/Classes/view-class?section=${selected}`, {
                  state: { data: item },
                })
              }
              handleEdit={() =>
                navigate("/admin/home/edit-class", { state: { data: item } })
              }
              handleDelete={() => {
                setConfirmationModal(true);
                setData(item?.id);
              }}
            />
          ))
        ) : (
          <NoClass />
        )}
      </div>

      <ConfirmationModal
        loading={loading}
        handleClick={() => handleDelete(data)}
        isOpen={confirmationModal}
        setIsOpen={setConfirmationModal}
        title={
          "You are about to delete this class information. click cancel to cancel this action or proceed with continue"
        }
      />
    </section>
  );
}

export default Classes;

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  data,
  setSelectValue,
  selectValue,
}: any) {
  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;
    setSelectValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const darkMode = useSelector(selectDarkmode);

  return (
    <div>
      <FormControl
        size="small"
        className={`w-full mb-2 lg:mb-6 ${darkMode ? "bg-dark-200" : ""}`}
      >
        <Select
          className={`appearance-none border  ${
            darkMode
              ? "border-gray-700 text-gray-300"
              : "border-gray-300 text-gray-700"
          }  rounded-lg w-full py-[0.125rem]  leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          size="small"
          color="success"
          value={selectValue}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={`outline-none ${darkMode ? "text-gray-300" : ""}`}
            />
          }
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {data?.map((item: any) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectValue.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

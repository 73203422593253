import React, { useEffect, useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";

import { Skeleton } from "@mui/material";
import Modal from "../../../components/Modal";
import Button from "../../../components/button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { avatar } from "@/assets";
import { useMutation, useQuery } from "react-query";
import { createTimetable } from "@/services/Admin/dashboard";

import DropDownV2 from "@/components/menu-dropdown/dropDownV2";
import { Formik } from "formik";
import toast from "react-hot-toast";
import TimePickerComp from "@/components/form/TimePicker";
import DatePicker from "@/components/form/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { getClassManagagementAPI } from "@/services/parent/dashboard";

interface Subject {
  subject_id: string;
  start_time: string;
  end_time: string;
}

interface ValuesTypes {
  first?: string;
  second?: string;
  firstData?: string;
  secondDate?: string;

  // Add additional fields as needed
}

interface Data {
  id?: string;
  // Add additional fields as needed
}
function ClassManagement() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [value, setValue] = useState<ValuesTypes>({
    secondDate: "",
    firstData: "",
    first: "",
    second: "",
  });
  const [timeTableModal, setTimeTableModal] = useState<boolean>(false);
  const createTimetableMuation = useMutation(createTimetable);
  const { data: classMgt, refetch: classmgtRefetch } = useQuery(
    ["dashboard", date],
    () => getClassManagagementAPI(dayjs(date).format("YYYY-MM-DD"))
  );

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const dayOfWeek = today.getDay();
  const dayName = daysOfWeek[dayOfWeek];

  useEffect(() => {
    classmgtRefetch();
  }, []);

  return (
    <div className="p-4 pb-24 lg:pt-8 lg:pb-8 ">
      <DashboardHeader
        title={"Class management 📖"}
        subtitle="See and manage your classes here"
      />
      <div className="w-full lg:flex flex-row justify-between items-start flex-wrap">
        <div className="w-full flex flex-col lg:w-[39%] ">
          <div className="w-full  shadow-box mb-6 ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={date}
                onChange={(newValue) => setDate(newValue)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="w-full lg:w-[59%]">
          <div className="w-full  mb-4">
            <div className="shadow-box rounded-xl w-full h-fit flex flex-col pb-6 ">
              <div className="flex justify-between p-5">
                <span className="text-sm font-bold text-black">
                  Class Schedule
                </span>

                <div className="relative">
                  <a
                    onClick={() => setIsOpen(true)}
                    className="text-brand font-normal text-sm cursor-pointer"
                  >
                    {dayjs(date).format("dddd D MMM, YYYY")}
                  </a>
                </div>
              </div>

              {classMgt?.data?.class_time_tables?.length > 0 ? (
                classMgt?.data?.class_time_tables?.map(
                  (event: any, i: number) => (
                    <div
                      key={i}
                      className="px-4 lg:px-6 w-full flex justify-between items-center mb-4 bg-gray-50"
                    >
                      <div className="mt-2 flex flex-col justify-between border-r-4 border-r-green-500 pr-4">
                        <p className="text-[#A2A2A2]/80 text-xs mb-4">
                          {event?.start_time}
                        </p>
                        <p className="text-[#A2A2A2]/80 text-xs">
                          {event?.end_time}
                        </p>
                      </div>
                      <div className="w-[75%] flex flex-col rounded-xl py-2 px-2">
                        <div className="flex justify-start">
                          <div>
                            <img
                              src={avatar}
                              alt="profile image"
                              className="rounded-lg w-12 h-12"
                            />
                          </div>
                          <div className="flex flex-col ml-2">
                            <p className="text-xs text-black font-fold">
                              <span className="font-bold">
                                {event?.subject?.name}
                              </span>{" "}
                              <span className="">
                                ( {event?.start_time} - {event?.end_time} )
                              </span>
                            </p>
                            <small className="text-gray-500 text-[10px] pt-1">
                              {event?.class?.name}
                            </small>
                          </div>
                        </div>
                      </div>
                      <DropDownV2
                        handleEdit={() => {}}
                        handleDelete={() => {}}
                        editText=""
                        viewText=""
                      />
                    </div>
                  )
                )
              ) : classMgt?.data?.class_time_tables?.length < 1 ? (
                <div className=" h-60 px-4 lg:px-6 w-full flex justify-between items-center mb-4 bg-gray-50">
                  <p className="text-center text-sm">
                    No event available at the moment
                  </p>
                </div>
              ) : (
                <div className="w-full mb-3 px-8 flex justify-between items-center">
                  <Skeleton
                    variant="rectangular"
                    height={50}
                    className="w-[50px] rounded-full mb-4"
                  />
                  <div className="w-[80%]">
                    <Skeleton
                      variant="rectangular"
                      height={20}
                      className="w-full mb-4"
                    />
                    <Skeleton
                      variant="rectangular"
                      height={20}
                      className="w-[80%] mb-4"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={timeTableModal}
        close={() => setTimeTableModal(false)}
        maxWidth="sm"
      >
        <>
          <p className="text-center text-lg font-semibold">Class Schedule</p>
          <Formik
            initialValues={{
              first: "",
              second: "",
              third: "",
              fourth: "",
              fifth: "",
              // sixth: "",
              // seventh: "",
              // eighth: "",
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const payload: {
                class_date: string;
                class_id: string | undefined;
                subjects: Subject[];
              } = {
                class_date: "",
                class_id: "",
                subjects: [],
              };

              const subjectFields: {
                id?: string;
                start?: string;
                end?: string;
              }[] = [
                { id: values.first, start: value.first, end: value.first },
                {
                  id: "",
                  start: value.firstData,
                  end: value.firstData,
                },
                { id: values.second, start: value.second, end: value.second },
                {
                  id: "",
                  start: value.secondDate,
                  end: value.secondDate,
                },
              ];

              subjectFields.forEach((subject) => {
                if (subject.id && subject.start && subject.end) {
                  payload.subjects.push({
                    subject_id: subject.id,
                    start_time: moment(subject.start).format("hh:mm"),
                    end_time: moment(subject.end).format("hh:mm"),
                  });
                }
              });
              try {
                const result = await createTimetableMuation.mutateAsync(
                  payload
                );

                if (result.message) {
                  toast.success(result.message, {
                    duration: 3000,
                  });
                  setTimeTableModal(false);
                }
              } catch (err: any) {
                toast.error(err?.response?.data?.message, {
                  duration: 3000,
                });
              }
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                className="flex flex-col pt-3 md:pt-8 px-4 lg:px-8"
                onSubmit={handleSubmit}
              >
                <div className="w-full flex justify-between items-start flex-wrap">
                  <div className=" w-full lg:w-[32%]">
                    <label htmlFor="text" className="text-gray-700 text-xs">
                      Add subjects (first)
                    </label>

                    <DatePicker
                      name={"datejoined"}
                      type={"date"}
                      placeholder={"Date joined"}
                      value={value?.firstData}
                      setValue={(newValue: any) =>
                        setValue({ ...value, firstData: newValue })
                      }
                    />
                  </div>

                  <div className=" w-full lg:w-[32%] h-4">
                    <TimePickerComp
                      label={"Start Time"}
                      value={value.first}
                      onChange={(newValue: any) =>
                        setValue({ ...value, first: newValue })
                      }
                    />
                  </div>
                  <div className=" w-full lg:w-[32%] h-4">
                    <DatePicker
                      name={"datejoined"}
                      type={"date"}
                      placeholder={"Date joined"}
                      value={value?.secondDate}
                      setValue={(newValue: any) =>
                        setValue({ ...value, secondDate: newValue })
                      }
                    />
                  </div>
                  <div className=" w-full lg:w-[32%]">
                    <TimePickerComp
                      label={"Start Time"}
                      value={value.first}
                      onChange={(newValue: any) =>
                        setValue({ ...value, first: newValue })
                      }
                    />
                  </div>
                </div>
                <div className="self-center  lg:flex justify-center items-center">
                  <Button
                    title="Close"
                    className="bg-transparent border  w-44 py-4 mt-5 mb-6"
                    textColor="text-black"
                    onClick={() => setTimeTableModal(false)}
                  />
                  <Button
                    isLoading={isSubmitting}
                    title="Save Subjects"
                    className="bg-other  w-44 py-4 ml-3"
                    textColor="text-white"
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
      <Modal open={isOpen} close={() => setIsOpen(false)} maxWidth="xs">
        <div className="px-8">
          <div className="flex flex-col text-center mb-8">
            <span className="text-slate-950 font-bold text-2xl">
              Event Details
            </span>
          </div>

          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event title:</p>
              <p className="font-semibold text-sm">
                {" "}
                Interhouse sport competition
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Event date:</p>
              <p className="font-semibold text-sm">24th march, 2023 </p>
            </div>
          </div>
          <div className="flex flex-col mb-8">
            <div className="flex justify-between items-center">
              <p className="font-normal text-sm">Location:</p>
              <p className="font-semibold text-sm"> School stadium</p>
            </div>
          </div>

          <div className="py-8 ">
            <Button
              iconClassName="h-[0.9rem] w-3"
              // isLoading={loading}
              title={"Close"}
              bgColor={"bg-brand"}
              onClick={() => {
                setIsOpen(false);
              }}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-4 rounded-lg bg-brand w-full"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ClassManagement;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import DashboardHeader from "../../../../components/headers/dashboard-header";
import { leftArrow } from "../../../../assets";
import { Link } from "react-router-dom";
import AddTeachersForm from "../../../../components/form/authForms/AddTeacher";

const AddTeachers = () => {
  return (
    <main className='lg:px-8 xs:px-4 mt-7'>
      <div className='flex gap-3'>
        <Link to='/admin/teachers'>
          <img src={leftArrow} alt='arrow' />
        </Link>
        <DashboardHeader title='Teachers' subtitle='Add teachers here' />
      </div>

      <AddTeachersForm />
    </main>
  );
};

export default AddTeachers;

import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiTrash2 } from 'react-icons/fi';
import { DARK_MODE_PROFILE, profile } from '../../assets';
import { object } from 'yup';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

interface imageType {
	setFileError?: any;
	file: any;
	setFile: any;
	data?: any;
}
function ImageInput({ file, setFile, data }: imageType) {
	const [fileSizeError, setFileSizeError] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const darkMode = useSelector(selectDarkmode);

	const handlefileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files && event.target.files[0];

		if (selectedFile) {
			// VALIDATING ABOVE 1MB

			if (selectedFile.size > 1000000) {
				setFileSizeError(true);
				setFile([]);
			} else {
				setFileSizeError(false);
				setFile([
					Object.assign(selectedFile, {
						preview: URL.createObjectURL(selectedFile),
					}),
				]);
			}
		}
	};

	const openFileDialog = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.click();
		}
	};

	const removeAll = () => {
		openFileDialog();
		setFile([]);
	};

	const productFiles = file.map((item: any, index: number) => (
		<div className=''>
			<div className='flex justify-start items-end'>
				<img src={item?.preview} className='h-28 w-28 mx-auto rounded-md' alt='' />
				<div className=''>
					<FiTrash2 size={'1em'} color='red' onClick={removeAll} className='' />
				</div>
			</div>
		</div>
	));

	return (
		<>
			<div className='   rounded-md  mb-4'>
				<div className='w-full'>
					{file.length > 0 ? (
						productFiles
					) : (
						<div>
							<label className='flex flex-row justify-start items-center h-20 w-full  transition delay-500  hover:scale-105 ease-in-out  rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
								<img
									src={data ? data : `${darkMode ? DARK_MODE_PROFILE : profile}`}
									alt=''
									className={` h-20 w-20 object-fill ${darkMode ? 'rounded-lg' : ''}`}
								/>
								<span className='ml-4 text-blue-500 text-sm'>Upload an image</span>

								<input
									ref={openFileDialog}
									type='file'
									name='file_upload'
									className='hidden'
									// {...getInputProps()}
									onChange={handlefileChange}
								/>
							</label>
						</div>
					)}
				</div>
			</div>
			{fileSizeError && <p className='text-red-600 text-xs'>File size exceeds the limit (1MB).</p>}
		</>
	);
}

export default ImageInput;

import { Fade } from "react-reveal";

import { Link } from "react-router-dom";

function LandingPage() {
  return (
    <div className="h-screen w-screen lg:flex justify-start items-start flex-wrap">
      <Fade left>
        <div className="h-1/2 w-full lg:w-1/2 bg-admin bg-cover bg-no-repeat hover:scale-110 ease-in-out duration-500 transform  delay-400 hover:z-0 z-20">
          <div className="w-full h-full flex justify-center items-center backdrop-brightness-50">
            <Link to="/admin-login">
              <span className="text-white text-lg font-medium w-1/2 text-center">
                {" "}
                Admin Login
              </span>
            </Link>
          </div>
        </div>
      </Fade>
      <Fade right>
        <div className="h-1/2 w-full lg:w-1/2 bg-parent bg-cover bg-no-repeat hover:scale-110 ease-in-out duration-500 transform  delay-400 hover:z-0 z-20">
          <div className="w-full h-full flex justify-center items-center backdrop-brightness-50">
            <Link to="/parent-login">
              <span className="text-white text-lg font-medium w-1/2 text-center">
                Parent Login
              </span>
            </Link>
          </div>
        </div>
      </Fade>
      <Fade left>
        <div className="h-1/2 w-full lg:w-1/2 bg-login bg-cover bg-no-repeat hover:scale-110 ease-in-out duration-500 transform  delay-400 hover:z-0 z-20">
          <div className="w-full h-full flex justify-center items-center backdrop-brightness-50 ">
            <Link to="/student-login">
              <span className="text-white text-lg font-medium w-1/2 text-center">
                {" "}
                Student Login
              </span>
            </Link>
          </div>
        </div>
      </Fade>
      <Fade right>
        <div className="h-1/2 w-full lg:w-1/2 bg-teacher bg-cover bg-no-repeat hover:scale-110 ease-in-out duration-500 transform  delay-400 hover:z-0 z-20">
          <div className="w-full h-full flex justify-center items-center backdrop-brightness-50">
            <Link to="/teacher-login">
              <span className="text-white text-lg font-medium w-1/2 text-center">
                {" "}
                Teacher Login
              </span>
            </Link>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default LandingPage;

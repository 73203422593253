import { useEffect } from "react";
import { useQuery } from "react-query";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { getDashboardAPI } from "../../../services/Admin/dashboard";
import AdminContent from "../molecules/AdminContent";

const Dashboard = () => {
  const { data: dashboardAData, refetch } = useQuery(
    "dashboardAdmin",
    getDashboardAPI
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className='mt-6 lg:px-8 px-1'>
        <DashboardHeader
          title='DashBoard 😊'
          subtitle='  Manage and track your activities and progress here'
        />
        <AdminContent data={dashboardAData?.data} />
    </div>
  );
};

export default Dashboard;

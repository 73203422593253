import DashboardHeader from "@/components/headers/dashboard-header";
import { DARK_MODE_ARROW, WHITE_ARROW_DOWN, leftArrow } from "@/assets";
import { Link } from "react-router-dom";
import AddStudentForm from "@/components/form/authForms/AddStudent";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const AddStudents = () => {
  const darkMode = useSelector(selectDarkmode);
  return (
    <main className="lg:px-8 xs:px-4 mt-7">
      <div className="flex gap-3">
        <Link to="/admin/students">
          {darkMode ? (
            <div className="flex justify-center items-center bg-black h-12 w-12 rounded-full ">
              <img src={DARK_MODE_ARROW} alt="arrow" className=" w-4" />
            </div>
          ) : (
            <img src={leftArrow} alt="arrow" />
          )}
        </Link>
        <DashboardHeader title="Add a students" subtitle="Add students here" />
      </div>
      <div className="mt-5">
        <AddStudentForm />
      </div>
    </main>
  );
};

export default AddStudents;

import React from "react";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { left, logo } from "../../../assets";
import { Link } from "react-router-dom";
import Button from "../../../components/button";

const ResetPassword = () => {
  return (
    <div className="w-full min-h-screen bg-login bg-fixed relative">
      <div className="flex justify-between items-start">
        <button type="button" className="">
          <img
            src={left}
            width={50}
            height={30}
            alt="left"
            className=" mt-7 ml-7"
          />
        </button>
        <div className=" w-fit h-fit bg-slate-50 right-0 mt-4 mr-14 rounded-lg">
          <div className="bg-[#93002F] w-full h-2 rounded-t-lg" />
          <div className="mx-10 mt-10 flex flex-col">
            <img
              src={logo}
              width={150}
              height={100}
              alt="just image "
              className="pt-5 pb-10"
            />
            <div className="flex flex-col gap-[5px]">
              <span className="font-semibold text-2xl">Reset Password</span>
              <span className="text-gray-300 text-sm">
                Enter yor new password below to proceed.
              </span>
            </div>
            <form action="" className="flex flex-col gap-1 mb-16 mt-8">
              <label
                htmlFor="text"
                className="text-gray-300 text-sm font-[200]"
              >
                New password
              </label>
              <input
                type="password"
                placeholder="Enter new password"
                className="w-96 py-2 bg-transparent border-2 pl-2 rounded-lg  border-b-[3px] mb-1 outline-none font-normal"
              />
              <div className="mb-5">
                <span className="font-[500] text-gray-500 text-sm">
                  Password must be different from previous one
                </span>
              </div>
              <label
                htmlFor="password"
                className="text-gray-300 text-sm font-[200]"
              >
                Confirm new password
              </label>
              <input
                type="password"
                placeholder="Enter new password again"
                className="bg-transparent border-2 pl-2 rounded-lg border-b-[3px] w-96 py-2 outline-none font-normal"
              />

              <div className="">
                <span className="font-[500] text-gray-500 text-sm">
                  Password must be different from previous one
                </span>
              </div>
              <Link to="/successful">
                {/* <Button
                  text="Reset password"
                  textStyles="text-white"
                  btnStyles="bg-[#93002F] py-3 mt-8 rounded-lg w-full"
                /> */}
                <Button
                  iconClassName="h-[0.9rem] w-3"
                  // isLoading={loading}
                  title={"Reset password"}
                  bgColor={"bg-brand"}
                  hoverColor={""}
                  textColor={"text-white"}
                  className={"py-4 rounded-lg bg-other w-full mt-4"}
                />
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

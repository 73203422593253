import React from 'react';
import DashboardHeader from '../../../components/headers/dashboard-header';
import { PencilBg, babe } from '../../../assets';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getWard } from '@/services/parent/dashboard';
import moment from 'moment';

function MyWard() {
	const { data: getWardsData } = useQuery('get-ward', getWard);

	return (
		<div className='p-4 pb-24 lg:pt-8 lg:pb-8 px-6 lg:px-20 '>
			<DashboardHeader
				title='My Ward(s)'
				subtitle='Below is a profile card of your Child(ren), see and monitor the school activities here.'
			/>
			{getWardsData?.data.map((item: any, i: number) => (
				<div key={i} className='mt-8 xs:mx-0 border pb-9 rounded-lg'>
					<img src={PencilBg} alt='background' className='object-contain w-full rounded-t-lg' />
					<div className='px-4 lg:px-7 relative'>
						<img
							src={babe}
							alt='profile'
							className='rounded-full w-20 h-20 lg:w-48 lg:h-48 absolute lg:-top-[5rem] xs:-top-[3rem]'
						/>

						<Link to={`/parent/myward/details/${item?.id}`}>
							<button
								type='button'
								className='text-white bg-[#93002F] py-2 px-7 rounded-lg border-gray-200 float-right mt-4 hover:scale-90 transition-all'
							>
								See details
							</button>
						</Link>
					</div>
					<div className='grid lg:grid-cols-3 xs:grid-cols-2 gap-7 ml-9 my-8 pb-9 px-0 lg:px-9 flex-col mt-[8rem]'>
						<div className='flex flex-col'>
							<span className='text-gray-400'>Full name</span>
							<span className='font-[550] text-[#1D2433]'>
								{`${item?.first_name}  ${item?.last_name}`}
							</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-gray-400'>Date of birth</span>
							<span className='font-[550] text-[#1D2433]'>
								{moment(item?.dob).format('do MMM, YYYY')}
							</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-gray-400'>Gender</span>
							<span className='font-[550] text-[#1D2433]'>{item?.gender}</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-gray-400'>Date joined</span>
							<span className='font-[550] text-[#1D2433]'>
								{moment(item?.date_joined).format('do MMM, YYYY')}
							</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-gray-400'>Admission number</span>
							<span className='font-[550] text-[#1D2433]'>{item?.unique_number}</span>
						</div>
						{item?.class && (
							<div className='flex flex-col'>
								<span className='text-gray-400'>Current class</span>
								<span className='font-[550] text-[#1D2433]'>{item?.class}</span>
							</div>
						)}

						<div className='flex flex-col'>
							<span className='text-gray-400'>Address</span>
							<span className='font-[550] text-[#1D2433]'>{item?.address}</span>
						</div>
					</div>
					<div className='px-9 font-bold h-3'>
						<hr />
					</div>
					<div className='px-2 lg:px-10  ml-6 mt-7'>
						<span className='font-[550]'>Medical Information</span>
						<div className='grid lg:grid-cols-3 xs:grid-cols-2 pt-6'>
							<div className='flex flex-col'>
								<span className='text-gray-400'>Blood group</span>
								<span className='font-[550] text-[#1D2433]'>{item?.blood_group}</span>
							</div>
							<div className='flex flex-col'>
								<span className='text-gray-400'>Genotype</span>
								<span className='font-[550] text-[#1D2433]'>{item?.genotype}</span>
							</div>
							<div className='flex flex-col'>
								<span className='text-gray-400'>Disability</span>
								<span className='font-[550] text-[#1D2433]'>{item?.disability}</span>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default MyWard;

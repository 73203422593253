import React, { useState } from "react";
import DashboardHeader from "../../../components/headers/dashboard-header";
import { subjectData } from "../../../mock/data";
import { TableComponent } from "../../../components/table/Table";

import Typography from "../../../components/Typography";
import { useQuery } from "react-query";
import { getStudentSubjects } from "@/services/student/dashboard";
import Modal from "@/components/Modal";
import { ArrowDown, WHITE_ARROW_DOWN } from "@/assets";
import EmptyStudentData from "@/pages/admin/Students/contents/emptyStudentData";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";
// import { getSubject } from "@/services/student/subject";

function Subjects() {
  const data = ["FIRST TERM", "SECOND TERM", "THIRD TERM"];
  const [termModal, setTermModal] = useState(false);
  const [term, setTerm] = useState("FIRST TERM");

  const { data: Student_Subject_Data } = useQuery(["subjects", term], () =>
    getStudentSubjects(term)
  );
  // student/subjects?term=FIRST TERM
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);

  const SCHOOL_SUBJECT_COLUMNS = [
    {
      Header: "SUBJECT TITLE",
      accessor: "name",
    },
    {
      Header: "FIRST TEST",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original?.course_report?.first_test_score || "Not available"}
          </span>
        </div>
      ),
    },
    {
      Header: "SECOND TEST",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original?.course_report?.second_test_score || "Not available"}
          </span>
        </div>
      ),
    },
    {
      Header: "EXAMINATION",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original?.course_report?.exam_score || "Not available"}
          </span>
        </div>
      ),
    },
    {
      Header: "TOTAL SCORE",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>
            {row.original?.course_report?.total_score || "Not available"}
          </span>
        </div>
      ),
    },
    {
      Header: "GRADE",
      Cell: ({ row }: any) => (
        <div className="relative">
          <span>{row.original?.course_report?.grade || "Not available"}</span>
        </div>
      ),
    },
  ];

  const darkMode = useSelector(selectDarkmode);
  return (
    <div className="px-4 lg:px-10 pt-10">
      <div className="flex justify-between items-start">
        <DashboardHeader
          title={"Subjects 📚"}
          subtitle="Manage all courses here"
        />
        <button
          type="button"
          className={`flex  w-fit h-fit py-3 px-8 rounded-lg gap-3 ${
            darkMode ? "bg-black" : "bg-[#F7F7F7]"
          }`}
          onClick={() => setTermModal(true)}
        >
          <span className="font-[500]">{`${
            !term ? " Select term" : term
          }`}</span>
          {darkMode ? (
            <img src={WHITE_ARROW_DOWN} alt="arrow_down" className="pt-1 w-4" />
          ) : (
            <img src={ArrowDown} alt="arrow_down" className="pt-1" />
          )}
        </button>
      </div>
      <div className="w-full">
        <div className="w-full pt-6 pb-20 lg:pb-8">
          <div
            className={`  ${
              darkMode ? "border border-gray-700 px-7 rounded-lg" : "bg-white"
            }`}
          >
            <div className="bg-transparent  flex  justify-between items-center p-3">
              <Typography textStyle=" text-base font-medium">
                <span>My subject list </span>
              </Typography>
            </div>

            <div className="">
              {subjectData && Student_Subject_Data?.data.length > 0 ? (
                <TableComponent
                  className="rounded-none rounded-t-0"
                  DATA={Student_Subject_Data?.data}
                  COLUMNS={SCHOOL_SUBJECT_COLUMNS}
                  page={page}
                  setPage={setPage}
                  isNotPaginanated={true}
                />
              ) : Student_Subject_Data?.data?.length < 1 ? (
                <div className="text-center text-xs">
                  <EmptyStudentData noStudentRegister />
                </div>
              ) : (
                <div className="w-full mb-3 px-8 flex justify-between items-center">
                  <Skeleton
                    variant="rectangular"
                    height={50}
                    className="w-[50px] rounded-full mb-4"
                  />
                  <div className="w-[80%]">
                    <Skeleton
                      variant="rectangular"
                      height={20}
                      className="w-full mb-4"
                    />
                    <Skeleton
                      variant="rectangular"
                      height={20}
                      className="w-[80%] mb-4"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal open={termModal} close={() => setTermModal(false)} maxWidth="xs">
        <div className="px-7 pb-8">
          <Typography
            textStyle={`text-center font-semibold text-lg ${
              darkMode ? "text-other" : ""
            }`}
          >
            Select Term
          </Typography>

          <div className="flex flex-col gap-8 mt-7 overflow-y-scroll">
            {data?.map((item: any) => (
              <span
                key={item}
                className={`cursor-pointer ${
                  darkMode
                    ? "hover:bg-other text-gray-300"
                    : "hover:bg-gray-300 text-black"
                }  p-3 rounded-xl `}
                onClick={() => {
                  setTerm(item);
                  setTermModal(false);
                }}
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Subjects;

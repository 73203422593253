import { selectDarkmode } from "@/states/slices/authReducer";
import { useField } from "formik";
import { useSelector } from "react-redux";

export default function DatePicker({ ...props }: any) {
  const [field, meta] = useField(props);
  const darkMode = useSelector(selectDarkmode);

  return (
    <div className=" mb-2 lg:mb-6 relative">
      <label htmlFor={props.name} className="text-xs text-[#6B7280]">
        {props.title ? props.title : props.placeholder}
      </label>
      <input
        {...field}
        {...props}
        value={props.value}
        onChange={(e: any) => props.setValue(e.target.value)}
        type={"date"}
        name={props.name}
        className={
          meta.touched && meta.error
            ? "block w-full border text-xs bg-white ring:border-red-500 border-red-500 rounded py-4 placeholder:text-[#6B7280] outline-0  px-4"
            : `appearance-none border  ${
                darkMode
                  ? "border-gray-700 bg-dark-200 text-gray-300"
                  : "border-gray-300 text-gray-700"
              } rounded-lg w-full py-3 px-3  mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`
        }
      />
      {meta.touched && meta.error && (
        <span className="error py-0 text-[10px] text-red-500">
          {meta.error}
        </span>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DARK_MODE_ARROW, left } from "@/assets";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@/components/button";
import { Form, Formik } from "formik";
import Input from "@/components/form/Input";
import { useMutation } from "react-query";
import { createScheduleAPI } from "@/services/Admin/dashboard";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import MultipleSelectCheckmarks from "@/components/Multiselect";
import ImageInput from "@/components/image-input";
import AddTaskModal from "@/pages/admin/Dashboard/modal/AddTaskModal";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const AddEvent = () => {
  const [modal, setModal] = useState(false);
  const [value, setValue] = React.useState<any>(dayjs(new Date()));
  const [endValue, setEndValue] = React.useState<any>(dayjs(new Date()));
  const [inputText, setInputText] = useState<any>("");
  const [items, setItems] = useState<any>([]);
  const [file, setFile] = useState<any>([]);
  const handleInputChange = (e: any) => {
    setInputText(e.target.value);
  };

  const handleAddItem = () => {
    if (inputText.trim() !== "") {
      setItems([...items, inputText]);
      setInputText("");
    }
  };
  const handleRemoveItem = (indexToRemove: any) => {
    const updatedItems = items.filter(
      (_: any, index: number) => index !== indexToRemove
    );
    setItems(updatedItems);
  };
  const [selectValue, setSelectValue] = useState([]);

  const createMutation = useMutation(createScheduleAPI);

  const roleData = ["STUDENT", "PARENT", "ADMIN", "TEACHER"];

  const darkMode = useSelector(selectDarkmode);

  return (
    <div className="mt-8 lg:ml-7 xs:ml-0 lg:px-0 xs:px-4 overflow-y-hidden">
      <div className="flex gap-5 ">
        <Link to="/admin/dashboard">
          {darkMode ? (
            <div className="w-10 h-10 rounded-full bg-black flex justify-center items-center">
              <img src={DARK_MODE_ARROW} alt="" className="w-4" />
            </div>
          ) : (
            <img src={left} width={50} height={50} alt="left image" />
          )}
        </Link>
        <span
          className={`mt-3 text-xl font-[550]  ${
            darkMode ? "text-gray-200" : "text-gray-900"
          }`}
        >
          Events
        </span>
      </div>
      <div className="flex flex-col mt-7 gap-5">
        <Formik
          initialValues={{ title: "", description: "", location: "" }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const formData = new FormData() as any;
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("location", values.location);
            formData.append("start_date", value);
            formData.append("end_date", endValue);
            selectValue.map((i) => {
              formData.append("roles[]", i);
            });

            try {
              const res = await createMutation.mutateAsync(formData);

              if (res?.data) {
                setModal(true);
                toast.success("Event Created Successfully", {
                  duration: 5000,
                });
                setItems([]);
                setSelectValue([]);
                resetForm();
              }
            } catch (err: any) {
              setItems([]);
              if (err?.response?.data.message) {
                toast.error(err?.response?.data.message, {
                  duration: 3000,
                });
              } else {
                toast.error("Something went wrong. Try again later", {
                  duration: 3000,
                });
              }
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            handleChange,
            values,
            /* and other goodies */
          }) => (
            <Form>
              <div className="lg:px-28 xs:px-0 overflow-y-scroll">
                <div
                  className={` ${
                    darkMode ? "bg-black border-gray-700" : "bg-white"
                  } border rounded-lg w-fit shadow-sm px-10 h-full xs:w-full pt-7`}
                >
                  <div className="w-full flex flex-wrap justify-between items-start">
                    <div className="w-full lg:w-[48%]">
                      <label htmlFor="title" className="text-sm text-gray-400">
                        Task title
                      </label>

                      <Input
                        placeholder="Enter title"
                        name={"title"}
                        className={`${
                          darkMode ? "bg-dark-200 border-gray-700 " : ""
                        }`}
                      />
                    </div>
                    <div className="w-full lg:w-[48%]">
                      <label
                        htmlFor="description"
                        className="text-sm text-gray-400"
                      >
                        Description
                      </label>
                      <br />
                      <textarea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Enter description"
                        rows={3}
                        className={`border rounded-lg outline-none px-3 pt-1 w-full ${
                          darkMode ? "bg-dark-200 border-gray-700 " : ""
                        }`}
                      />
                    </div>
                    <div className="w-full lg:w-[48%]">
                      <label
                        htmlFor="location"
                        className="text-sm text-gray-400"
                      >
                        Location
                      </label>

                      <Input
                        placeholder="Enter Location"
                        name={"location"}
                        className={`${
                          darkMode ? "bg-dark-200 border-gray-700 " : ""
                        }`}
                      />
                    </div>
                    <div className="w-full lg:w-[48%]">
                      <label
                        htmlFor="location"
                        className="text-sm text-gray-400"
                      >
                        Role
                      </label>

                      <MultipleSelectCheckmarks
                        data={roleData}
                        selectValue={selectValue}
                        setSelectValue={setSelectValue}
                      />
                    </div>

                    <div className="w-full lg:w-[48%]">
                      <label htmlFor="title" className="text-sm text-gray-400">
                        Start Date
                      </label>

                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          // label='Start date'
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                          className={`${
                            darkMode ? "bg-dark-200 border-gray-700 " : ""
                          }`}
                        />
                      </DemoContainer>
                    </div>
                    <div className="w-full lg:w-[48%]">
                      <label htmlFor="title" className="text-sm text-gray-400">
                        End Date
                      </label>

                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          value={endValue}
                          onChange={(newValue) => setEndValue(newValue)}
                          className={`${
                            darkMode ? "bg-dark-200 border-gray-700 " : ""
                          }`}
                        />
                      </DemoContainer>
                    </div>
                    <div className="w-full lg:w-[48%] my-4">
                      <label htmlFor="title" className="text-sm text-gray-400">
                        Event banner
                      </label>
                      <ImageInput file={file} setFile={setFile} />
                    </div>

                    <div className="w-full lg:w-[48%] my-4">
                      <label
                        htmlFor="location"
                        className="text-sm text-gray-400"
                      >
                        Guests {"    "}{" "}
                        <span className="text-xs"> (Names of Guests)</span>
                      </label>

                      <div className="relative flex items-start w-full my-4">
                        <input
                          type="text"
                          className="appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200"
                          value={inputText}
                          onChange={handleInputChange}
                        />
                        <button
                          className="absolute right-2 top-4 rounded-md bg-gray-500 hover:bg-gray-600 text-white text-xs font-semibold py-1 px-2"
                          onClick={handleAddItem}
                          type="button"
                        >
                          Add more
                        </button>
                      </div>
                      <div className="w-full ">
                        <div className="mt-8 lg:px-0 xs:px-4 overflow-y-hidden flex justify-start items-center flex-wrap">
                          {items?.map((item: any, i: number) => (
                            <div
                              key={i}
                              className=" w-full flex  justify-between items-center bg-brand/5 py-2 px-4 mb-4"
                            >
                              <p className="text-xs px-4 overflow-hidden overflow-ellipsis rounded-xl ml-2">
                                {item}
                              </p>
                              <button
                                className="text-red-500 font-semibold ml-2 text-xs "
                                onClick={() => handleRemoveItem(i)}
                                type="button"
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center py-12">
                    <Button
                      onClick={handleSubmit}
                      iconClassName="h-[0.9rem] w-3"
                      isLoading={isSubmitting}
                      title={"Add Events"}
                      bgColor={"bg-other"}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={
                        "py-4 rounded-lg  lg:w-[25rem] xs:w-[20rem] my-9 text-xl"
                      }
                    />
                  </div>
                  {modal && (
                    <AddTaskModal
                      isOpen={() => setModal(true)}
                      isClose={() => setModal(false)}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEvent;

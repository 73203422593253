import React, { useEffect, useState, useRef } from 'react';
import { calendar3, exports, print } from '@/assets';
import { Link } from 'react-router-dom';

import { useMutation, useQuery } from 'react-query';
import { TableComponent } from '@/components/table/Table';
import Button from '@/components/button';
import moment from 'moment';
import toast from 'react-hot-toast';
import SmallModal from '@/pages/admin/Attendance/modal/smallmodal';
import {
	getClassAPI,
	getTeacherAttendanceAPI,
	markAttendanceAPI,
} from '@/services/Teacher/dashboard';
// import { createTheme } from '@mui/material/styles'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

const MarkAttendance = () => {
	const currentDate = new Date();
	const [selectedContent, setSelectedContent] = useState<number | null>(0);
	const handleClick = (index: number) => {
		setSelectedContent(index);
	};

	const { data: classData, refetch } = useQuery('class', getClassAPI);
	const [studentData, setStudentData] = useState<any>([]);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedCell, setSelectedCell] = useState<any>(null);
	const [dateValue, setDateValue] = useState<Dayjs | null | any>(dayjs(new Date()));

	const [selectedInputField, setSelectedInputField] = useState(null);
	const [selectedData, setSelectedData] = useState<any>([]);
	const [data, setData] = useState();
	const [open, setOpen] = useState<boolean>(false);
	const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']; // List of weekdays excluding Sat and Sun
	const today = new Date(); // Get today's date
	const workingDays: any = [];

	const attendaceDataParams = {
		id: studentData?.id,
		date: dayjs(dateValue).format('YYYY-MM-DD'),
	};

	const { data: attendaceData, refetch: attendanceRefetch } = useQuery(
		['attendance', attendaceDataParams],
		() => getTeacherAttendanceAPI(attendaceDataParams)
	);

	useEffect(() => {
		if (selectedContent === 0) {
			setStudentData(classData?.data[0]);
		}
	}, [classData]);

	useEffect(() => {
		attendanceRefetch();
	}, [studentData, dateValue]);

	let dayCount = 0;
	while (workingDays.length < 5) {
		const date = dayjs(dateValue).add(dayCount, 'day'); // Use dayjs to manipulate dates

		const dayIndex = date.day(); // Get the day index (0-6)

		if (dayIndex !== 0 && dayIndex !== 6) {
			// Check if not Sat or Sun
			const dayName = date.format('ddd'); // Get the full day name
			workingDays.push(`${dayName} ${date.format('MMM DD')}`);
		}

		dayCount++;
	}

	useEffect(() => {
		// Update each object in the data array with the working days
		const updatedData: any =
			attendaceData?.data?.length > 0 &&
			attendaceData?.data?.map((item: any) => ({
				...item,
				day1: '',
				day2: '',
				day3: '',
				day4: '',
				day5: '',
			}));
		setSelectedData(updatedData);
	}, [attendaceData?.data]);

	const openModal = (cell: any) => {
		setSelectedCell(cell);
		setSelectedInputField(cell.column.id);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedInputField(null);
	};

	const handleSelect = (value: any) => {
		if (selectedCell) {
			const updatedData = [...selectedData];
			updatedData[selectedCell?.row?.index][selectedCell?.column.id] = value;
			setSelectedData(updatedData);
			closeModal();
		}
	};

	const EVENT_COLUMNS = [
		{
			Header: 'STUDENT NAME',
			accessor: '',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>
						{row.original.first_name} {row.original.last_name}
					</span>
				</div>
			),
		},
		{
			Header: `${workingDays[0]}`,
			accessor: 'day1',
			Cell: (cell: any) => {
				const formattedDateValue = dayjs(dateValue).format('YYYY-MM-DD');

				const filteredStatus = selectedData[cell.row.index].class_attendances.filter(
					(item: any) => {
						const formattedAttendanceDate = dayjs(item?.attendance_date).format('YYYY-MM-DD');
						return formattedAttendanceDate === formattedDateValue && item.status;
					}
				);

				const status = filteredStatus.length > 0 ? filteredStatus[0].status : '';

				return (
					<div className='relative'>
						<input
							type='text'
							className='border py-3 w-28 pl-6'
							value={
								dayjs(dateValue).format('YYYY-MM-DD') ===
									dayjs(workingDays[0]).format('YYYY-MM-DD') &&
								selectedData[cell.row.index][cell.column.id]
									? selectedData[cell.row.index][cell.column.id]
									: status
							}
							onClick={() => {
								openModal(cell);
								setData(cell);
							}}
							readOnly
						/>
					</div>
				);
			},
		},
		{
			Header: `${workingDays[1]}`,
			accessor: 'day2',
			Cell: (cell: any) => {
				const formattedDateValue = dayjs(dateValue).format('YYYY-MM-DD');

				const filteredStatus = selectedData[cell.row.index].class_attendances.filter(
					(item: any) => {
						const formattedAttendanceDate = dayjs(item?.attendance_date).format('YYYY-MM-DD');
						return formattedAttendanceDate === formattedDateValue && item.status;
					}
				);

				const status = filteredStatus.length > 0 ? filteredStatus[0].status : '';

				return (
					<div className='relative'>
						<input
							type='text'
							className='border py-3 w-28 pl-6'
							value={
								dayjs(dateValue).format('YYYY-MM-DD') ===
									dayjs(workingDays[1]).format('YYYY-MM-DD') &&
								selectedData[cell.row.index][cell.column.id]
									? selectedData[cell.row.index][cell.column.id]
									: status
							}
							onClick={() => {
								openModal(cell);
								setData(cell);
							}}
							readOnly
						/>
					</div>
				);
			},
		},
		{
			Header: `${workingDays[2]}`,
			accessor: 'day3',
			Cell: (cell: any) => {
				const formattedDateValue = dayjs(dateValue).format('YYYY-MM-DD');

				const filteredStatus = selectedData[cell.row.index].class_attendances.filter(
					(item: any) => {
						const formattedAttendanceDate = dayjs(item?.attendance_date).format('YYYY-MM-DD');
						return formattedAttendanceDate === formattedDateValue && item.status;
					}
				);

				const status = filteredStatus.length > 0 ? filteredStatus[0].status : '';

				return (
					<div className='relative'>
						<input
							type='text'
							className='border py-3 w-28 pl-6'
							value={
								dayjs(dateValue).format('YYYY-MM-DD') ===
									dayjs(workingDays[2]).format('YYYY-MM-DD') &&
								selectedData[cell.row.index][cell.column.id]
									? selectedData[cell.row.index][cell.column.id]
									: status
							}
							onClick={() => {
								openModal(cell);
								setData(cell);
							}}
							readOnly
						/>
					</div>
				);
			},
		},
		{
			Header: `${workingDays[3]}`,
			accessor: 'day4',
			Cell: (cell: any) => {
				const formattedDateValue = dayjs(dateValue).format('YYYY-MM-DD');

				const filteredStatus = selectedData[cell.row.index].class_attendances.filter(
					(item: any) => {
						const formattedAttendanceDate = dayjs(item?.attendance_date).format('YYYY-MM-DD');
						return formattedAttendanceDate === formattedDateValue && item.status;
					}
				);

				const status = filteredStatus.length > 0 ? filteredStatus[0].status : '';

				return (
					<div className='relative'>
						<input
							type='text'
							className='border py-3 w-28 pl-6'
							value={
								dayjs(dateValue).format('YYYY-MM-DD') ===
									dayjs(workingDays[3]).format('YYYY-MM-DD') &&
								selectedData[cell.row.index][cell.column.id]
									? selectedData[cell.row.index][cell.column.id]
									: status
							}
							onClick={() => {
								openModal(cell);
								setData(cell);
							}}
							readOnly
						/>
					</div>
				);
			},
		},
		{
			Header: `${workingDays[4]}`,
			accessor: 'day5',
			Cell: (cell: any) => {
				const formattedDateValue = dayjs(dateValue).format('YYYY-MM-DD');

				const filteredStatus = selectedData[cell.row.index].class_attendances.filter(
					(item: any) => {
						const formattedAttendanceDate = dayjs(item?.attendance_date).format('YYYY-MM-DD');
						return formattedAttendanceDate === formattedDateValue && item.status;
					}
				);

				const status = filteredStatus.length > 0 ? filteredStatus[0].status : '';

				return (
					<div className='relative'>
						<input
							type='text'
							className='border py-3 w-28 pl-6'
							value={
								dayjs(dateValue).format('YYYY-MM-DD') ===
									dayjs(workingDays[4]).format('YYYY-MM-DD') &&
								selectedData[cell.row.index][cell.column.id]
									? selectedData[cell.row.index][cell.column.id]
									: status
							}
							onClick={() => {
								openModal(cell);
								setData(cell);
							}}
							readOnly
						/>
					</div>
				);
			},
		},
	];

	const atendanceSubmit = useMutation(markAttendanceAPI);

	const onSubmit = async () => {
		setLoading(true);
		const formData = {
			class_id: studentData?.id,
			students: selectedCell?.data?.map((item: any) => {
				const attendance = [
					{
						attendance_date: moment(workingDays[0], 'ddd MMM D').format('YYYY-MM-DD'),
						status: item.day1 || '', // If day1 is filled, use its status; otherwise, use an empty string
					},
					{
						attendance_date: moment(workingDays[1], 'ddd MMM D').format('YYYY-MM-DD'),
						status: item.day2 || '', // If day2 is filled, use its status; otherwise, use an empty string
					},
					{
						attendance_date: moment(workingDays[2], 'ddd MMM D').format('YYYY-MM-DD'),
						status: item.day3 || '', // If day3 is filled, use its status; otherwise, use an empty string
					},
					{
						attendance_date: moment(workingDays[3], 'ddd MMM D').format('YYYY-MM-DD'),
						status: item.day4 || '', // If day4 is filled, use its status; otherwise, use an empty string
					},
					{
						attendance_date: moment(workingDays[4], 'ddd MMM D').format('YYYY-MM-DD'),
						status: item.day5 || '', // If day5 is filled, use its status; otherwise, use an empty string
					},
				];

				// Filter out the attendance objects where status is an empty string (i.e., the day was omitted)
				const filteredAttendance = attendance.filter((att) => att.status !== '');

				return {
					student_id: item.id,
					attendance: filteredAttendance,
				};
			}),
		};
		try {
			const res = await atendanceSubmit.mutateAsync(formData);

			if (res.message) {
				setLoading(false);
				toast.success(res.message);
				setSelectedCell(null);
			}
		} catch (error: any) {
			setLoading(false);
			setSelectedCell(null);
			if (error.response.data) {
				toast.error(error.response.data.message);
			} else {
				toast.error('Something went wrong');
			}
		}
	};

	return (
		<div className='mt-6 px-8'>
			<div className='flex justify-between'>
				<div className='flex flex-col'>
					<span className='text-2xl font-[550]'>Attendance</span>
					<span className='text-gray-400'>See and manage students attendance below</span>
				</div>

				<div className='flex justify-between mx-6 my-4'>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer components={['DatePicker']} sx={{}}>
							<DatePicker
								sx={{
									borderRadius: 12,
									borderBlockColor: 'black',
									paddingY: 0,
								}}
								value={dateValue}
								onChange={(newValue) => setDateValue(newValue)}
							/>
						</DemoContainer>
					</LocalizationProvider>
				</div>
			</div>

			<div className='flex justify-between'>
				<div className='mt-8 mb-6 flex gap-5 text-gray-700'>
					{classData?.data.map((link: any, index: number) => {
						return (
							<Link
								to=''
								key={index}
								className={`${
									index === selectedContent
										? 'text-blue-500 border-b-4 border-blue-500 p-2'
										: 'text-gray-500 p-2'
								} inline-block transition-all duration-300`}
								onClick={() => {
									setStudentData(link);
									handleClick(index);
								}}
							>
								{link.name}
							</Link>
						);
					})}
				</div>

				{selectedCell && (
					<Button
						iconClassName='h-[0.9rem] w-3'
						isLoading={loading}
						title={'Mark Attendance'}
						bgColor={'bg-brand'}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-4 rounded-lg bg-brand w-36 mt-4'}
						onClick={onSubmit}
					/>
				)}
			</div>

			<div>
				{selectedData?.length > 0 && (
					<TableComponent
						className='rounded-none rounded-t-0'
						DATA={selectedData}
						COLUMNS={EVENT_COLUMNS}
						page={page}
						setPage={setPage}
					/>
				)}
			</div>

			{selectedInputField && (
				<SmallModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onSelect={handleSelect}
					selectedCell={selectedCell}
				/>
			)}
		</div>
	);
};

export default MarkAttendance;

import * as React from 'react';
import { useTable } from 'react-table';
import { Nosubject, bag } from '../../../../assets';
import Button from '../../../../components/button';
import { Link } from 'react-router-dom';

const TableData = [
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
	{
		name: '',
		id: '',
		gender: '',
		date: '',
	},
];

const TeacherEmptyData = () => {
	const data = React.useMemo(() => TableData, []);
	const columns = React.useMemo(
		() => [
			{
				Header: 'TEACHER NAME',
				accessor: 'name',
			},
			{
				Header: 'STAFF ID',
				accessor: 'id',
			},
			{
				Header: 'GENDER',
				accessor: 'gender',
			},
			{
				Header: 'DATE JOINED',
				accessor: 'date',
			},
		],
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});

	return (
		<div>
			<div>
				<table
					{...getTableProps()}
					className=' w-full table-auto rounded border-collapse border border-gray-300 relative'
				>
					<thead className=' bg-[#93002F] '>
						{headerGroups.map((headerGroup, i) => (
							<tr
								key={i}
								{...headerGroup.getHeaderGroupProps()}
								className='px-6 border rounded-t-lg text-center'
							>
								{headerGroup.headers.map((column, i) => (
									<th
										key={i}
										{...column.getHeaderProps()}
										className='py-4 pr-4 text-white font-[500]'
									>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
				</table>
				<div className='flex flex-col justify-center items-center border h-full gap-3 py-10'>
					<img src={Nosubject} alt='' width={200} height={50} />
					<span className='font-semibold'>Oops, no item here.</span>
					<p className='text-center max-w-md'>
						Don’t panic, kindly click on the button below to add new teachers. Teachers list will be
						visible here after being added.
					</p>
					{/* <Link to='/admin/teachers/add_teachers'>
						<Button
							iconClassName='h-[0.9rem] w-3'
							// isLoading={loading}
							title={'Add new teacher'}
							bgColor={'bg-other'}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-5 rounded-lg bg-brand w-[25rem] my-9  '}
						/>
					</Link> */}
				</div>
			</div>
		</div>
	);
};

export default TeacherEmptyData;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
import { registerNonTeacherAPI } from "../../../services/authServices";
import { getClass, getRoles } from "../../../services/Admin/dashboard";
import { getAllSubject } from "../../../services/Admin/subject";
import ImageInput from "../../image-input";
import Input from "../Input";
import Select from "../Select";
import DatePicker from "../DatePicker";
import MultipleSelectCheckmarksV2 from "../../Multiselect/variant";
import Button from "../../button";
import SuccessModal from "../../Modal/SuccessModal";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

const AddNonTeachersForm = () => {
  const [modal, setModal] = useState(false);
  const registerNonTeacherMutation = useMutation(registerNonTeacherAPI);
  const [file, setFile] = useState<any>([]);
  const { data: getAllRoles } = useQuery("roles", getRoles);
  const { data: classData, refetch } = useQuery(["class", ""], () =>
    getClass("")
  );
  const [subjectValue, setSubjectValue] = useState<any>([]);
  const [classValue, setClassValue] = useState<any>([]);
  const { data: subjectData } = useQuery(["subject", 1], () =>
    getAllSubject(1)
  );
  const [date_joined, setDate_joined] = useState<any>();
  const [dob, setDob] = useState<any>();
  const navigate = useNavigate();

  const darkMode = useSelector(selectDarkmode);

  return (
    <main className="lg:px-8 xs:px-4 mt-7">
      <div className="">
        <Formik
          initialValues={{
            role: "",
            first_name: "",
            last_name: "",
            other_name: "",
            gender: "",
            phone_number: "",
            email: "",
            residential_address: "",
            blood_group: "",
            genotype: "",
            disability: "",
            bank_name: "",
            account_number: "",
            account_name: "",
            next_of_kin_name: "",
            next_of_kin_phone_number: "",
            next_of_kin_email: "",
            next_of_kin_address: "",
            next_of_kin_relationship: "",
            dob: "",
            class: "",
            subject: "",
            password: "",
            date_joined: "",
          }}
          // validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();

            formData.append("role", values.role);
            formData.append("first_name", values.first_name);
            formData.append("last_name", values.last_name);
            formData.append("other_name", values.other_name);
            formData.append("gender", values.gender);
            formData.append("phone_number", values.phone_number);
            formData.append("email", values.email);
            formData.append("address", values.residential_address);
            formData.append("blood_group", values.blood_group);
            formData.append("genotype", values.genotype);
            formData.append("disability", values.disability);
            formData.append("bank_name", values.bank_name);
            formData.append("account_number", values.account_number);
            formData.append("account_name", values.account_name);
            formData.append("next_of_kin_name", values.next_of_kin_name);
            formData.append(
              "next_of_kin_phone_number",
              values.next_of_kin_phone_number
            );
            formData.append("next_of_kin_address", values.next_of_kin_address);
            formData.append("next_of_kin_email", values.next_of_kin_email);
            formData.append(
              "next_of_kin_relationship",
              values.next_of_kin_relationship
            );
            formData.append("password", values.password);
            formData.append("dob", dob);
            formData.append("date_joined", date_joined);
            formData.append("profile_image", file[0]);

            for (let i = 0; i < subjectValue.length; i++) {
              formData.append(
                `teacher_class_subjects[${i}][subject_id]`,
                subjectValue[i]?.id
              );
            }

            for (let i = 0; i < classValue.length; i++) {
              formData.append(
                `teacher_class_subjects[${i}][class_id]`,
                classValue[i]?.id
              );
            }

            try {
              const res = await registerNonTeacherMutation.mutateAsync(
                formData
              );

              if (res?.data) {
                setModal(true);
                resetForm();
                file.length = 0;
              }
            } catch (err: any) {
              if (err?.response?.data.message) {
                toast.error(err?.response?.data.message, {
                  duration: 3000,
                });
              } else {
                toast.error("Something went wrong. Try again later", {
                  duration: 3000,
                });
              }
            }
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <div
                className={`border mt-6 lg:px-7 px-4 rounded-lg ${
                  darkMode ? " border-gray-700" : ""
                }`}
              >
                <div className="pb-7 py-6">
                  <span className="font-medium text-lg">
                    Personal information
                  </span>
                  <div className="py-5 w-40">
                    <ImageInput file={file} setFile={setFile} />
                  </div>
                  <div className="flex justify-between items-start flex-wrap">
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        First name
                      </label>
                      <Input
                        name={"first_name"}
                        placeholder={"Full name"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Last name
                      </label>
                      <Input
                        name={"last_name"}
                        placeholder={"Last name"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Gender
                      </label>
                      <Select
                        name="gender"
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      >
                        <option value="">Select a gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Select>
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Phone number
                      </label>
                      <Input
                        name={"phone_number"}
                        placeholder={"Phone number"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>

                    <div className=" w-full lg:w-[49%]">
                      <DatePicker
                        name={"datejoined"}
                        type={"date"}
                        placeholder={"Date joined"}
                        value={date_joined}
                        setValue={setDate_joined}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <DatePicker
                        name={"dob"}
                        type={"date"}
                        placeholder={"Date of Birth"}
                        value={dob}
                        setValue={setDob}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Residential address
                      </label>
                      <Input
                        name={"residential_address"}
                        placeholder={"Enter residential address"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Email address
                      </label>
                      <Input
                        name={"email"}
                        placeholder={"Enter Email address"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Bank
                      </label>
                      <Input
                        name={"bank_name"}
                        placeholder={"Enter Bank Name"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Account number
                      </label>
                      <Input
                        name={"account_number"}
                        placeholder={"Enter Account Number"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Account Name
                      </label>
                      <Input
                        name={"account_name"}
                        placeholder={"Enter Account name"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Role
                      </label>
                      <Select
                        name="role"
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      >
                        <option></option>
                        {getAllRoles?.data?.map((item: any) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Select>
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Class
                      </label>
                      {classData?.data?.length > 0 && (
                        <MultipleSelectCheckmarksV2
                          data={classData?.data}
                          personName={classValue}
                          setPersonName={setClassValue}
                        />
                      )}
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400 text-xs">
                        Subject
                      </label>
                      {subjectData?.data?.length > 0 && (
                        <MultipleSelectCheckmarksV2
                          data={subjectData?.data}
                          personName={subjectValue}
                          setPersonName={setSubjectValue}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <hr className={`${darkMode ? " border-gray-700" : ""}`} />
                <div className="pb-7 py-6">
                  <span className="font-medium text-lg">
                    Medical information
                  </span>
                  <div className="flex justify-between items-start flex-wrap">
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Blood Group
                      </label>
                      <Input
                        name={"blood_group"}
                        placeholder={"Blood group"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Genotype
                      </label>
                      <Input
                        name={"genotype"}
                        placeholder={"genotype"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Disability
                      </label>
                      <Select
                        name="disability"
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      >
                        <option value="">select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="pb-7 py-6">
                  <span className="font-medium text-lg">
                    Next of kin information
                  </span>

                  <div className="flex justify-between items-start flex-wrap mt-">
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Next of kin name
                      </label>
                      <Input
                        name={"next_of_kin_name"}
                        placeholder={"Enter next of kin name"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Next of kin email
                      </label>
                      <Input
                        name={"next_of_kin_email"}
                        placeholder={"Enter next of kin email"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Next of kin phone number
                      </label>
                      <Input
                        name={"next_of_kin_phone_number"}
                        placeholder={"Enter next of kin phone number"}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Next of kin address
                      </label>
                      <Input
                        name={"next_of_kin_address"}
                        placeholder={"Enter next of kin address"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full lg:w-[49%]">
                      <label htmlFor="text" className="text-gray-400">
                        Next of kin relationship
                      </label>
                      <Input
                        name={"next_of_kin_relationship"}
                        placeholder={"Enter next of kin relationship"}
                        className={`${
                          darkMode
                            ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="pb-7 py-6">
                  <span className="font-medium text-lg">Login information</span>

                  <div className=" w-full lg:w-[49%] mt-6">
                    <label htmlFor="text" className="text-gray-400">
                      Password
                    </label>
                    <Input
                      name={"password"}
                      placeholder={"Enter password"}
                      password
                      className={`${
                        darkMode
                          ? "placeholder:text-gray-300 bg-dark-200 border-gray-700"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <Button
                    iconClassName="h-[0.9rem] w-3"
                    isLoading={isSubmitting}
                    title={"Add Non-Teacher"}
                    bgColor={"bg-other"}
                    hoverColor={""}
                    textColor={"text-white"}
                    className={
                      "py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  "
                    }
                  />
                </div>

                <SuccessModal
                  isOpen={modal}
                  isClose={() => {
                    // navigate("/admin/teachers");
                    setModal(false);
                  }}
                  title={
                    "Non-Teacher has been added successfully to the system."
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
};

export default AddNonTeachersForm;

import React from 'react';
import Modal from '../../../../components/Modal';

import { arrowRight } from '../../../../assets';
import Button from '../../../../components/button';
import { selectRoleModal } from '../content/pages/Users';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

interface Props {
	isOpen: () => void;
	isClose: () => void;
	onSelect: (selectedValue: any) => void;
}
const SelectModal = ({ isOpen, isClose, onSelect }: Props) => {
	const darkMode = useSelector(selectDarkmode);
	return (
		<Modal maxWidth='xs' open={isOpen} close={isClose}>
			<div className='px-7'>
				<span className={`text-sm font-medium ${darkMode ? 'text-gray-300' : ''}`}>
					Select User type
				</span>
				<div className='flex flex-col gap-5 py-2 '>
					{selectRoleModal.map((item, index) => (
						<div
							onClick={() => onSelect(item.label)}
							className='flex justify-between cursor-pointer bg-[#F7F7F7] lg:px-5 xs:px-2 py-2 rounded-lg shadow-md'
						>
							<div key={index} className='flex gap-3 cursor-pointer'>
								<div>
									<img src={item.icon} alt='' width={50} height={30} />
								</div>
								<span className='pt-3 text-xs'>{`Add ${item.label}`}</span>
							</div>
							<img src={arrowRight} alt='' className='w-8' />
						</div>
					))}
				</div>
				<Button
					iconClassName='h-[0.9rem] w-3'
					// isLoading={loading}
					title={'close'}
					onClick={isClose}
					bgColor={'bg-transparent'}
					hoverColor={''}
					textColor={` ${darkMode ? 'text-gray-300' : 'text-black'}`}
					className={'py-4 rounded-lg border w-full my-9 text-xl'}
				/>
			</div>
		</Modal>
	);
};

export default SelectModal;

import { useField } from "formik";

const Select = ({ ...props }: any) => {
  const [field, meta] = useField(props);

  return (
    <div className=' mb-2 lg:mb-6 relative'>
      {props.label ? (
        <>
          <label htmlFor={props.name} className='text-xs text-gray-900'>
            {props.label}
          </label>
          <select
            {...field}
            {...props}
            className={
              meta.touched && meta.error
                ? `block w-full border text-xs bg-white ring:border-red-500 border-red-500 rounded pb-2 pt-3 placeholder:text-[#6B7280] outline-0  px-4 text-gray-900 ${props.className}`
                : `appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`
            }
          />
        </>
      ) : (
        <select
          {...field}
          {...props}
          className={
            meta.touched && meta.error
              ? `block w-full border text-xs bg-white ring:border-red-500 border-red-500 rounded py-4 placeholder:text-[#6B7280] outline-0  px-4 text-gray-900 ${props.className}`
              : `appearance-none border border-gray-300  rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`
          }
        />
      )}

      {meta.touched && meta.error && (
        <span className='error py-0 text-[10px] text-red-500'>
          {meta.error}
        </span>
      )}
    </div>
  );
};
export default Select;

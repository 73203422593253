import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface Selecttypes {
	data: any;
	personName: any;
	setPersonName: any;
	setSubjectModal?: any;
}
export default function MultipleSelectCheckmarksV2({
	data,
	personName,
	setPersonName,
	setSubjectModal,
}: Selecttypes) {
	const [renderValue, setRenderValue] = React.useState([]);
	const handleChange = (event: SelectChangeEvent<typeof personName>) => {
		const {
			target: { value },
		} = event;
		setPersonName(typeof value === 'string' ? value?.split(',') : value);
		setRenderValue(value);
	};

	return (
		<>
			<FormControl size='small' className='w-full mb-2 lg:mb-6'>
				{/* <InputLabel id='demo-multiple-checkbox-label'>Select options</InputLabel> */}
				<Select
					className='appearance-none border border-gray-300  rounded-lg w-full py-[0.125rem] text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200'
					labelId='demo-multiple-checkbox-label'
					id='demo-multiple-checkbox'
					multiple
					value={personName}
					onChange={handleChange}
					input={<OutlinedInput />}
					renderValue={(selected) => (
						<>
							{personName?.map((item: any, i: number) => (
								<small key={i}>
									{item?.first_name ? `${item?.first_name} ${item?.last_name}` : item?.name},{' '}
								</small>
							))}
						</>
					)}
					MenuProps={MenuProps}
				>
					{data?.map((item: any) => (
						<MenuItem key={item?.id} value={item}>
							<Checkbox checked={personName?.indexOf(item) > -1} />
							<ListItemText
								primary={item?.first_name ? `${item?.first_name} ${item?.last_name}` : item?.name}
								secondary={''}
							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
}

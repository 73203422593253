import { avatar_, upload } from "@/assets";
import DashboardHeader from "@/components/headers/dashboard-header";
import ProfileDotBtn from "@/components/menu-dropdown/ProfileDropdown";
import { useAppSelector } from "@/hooks";
import { selectDarkmode, selectUser } from "@/states/slices/authReducer";
import { UserData } from "@/types";
import moment from "moment";
import { useSelector } from "react-redux";

function Profile() {
  const data: UserData = useAppSelector(selectUser);
  const darkMode = useSelector(selectDarkmode);

  return (
    <div className="mt-6 lg:px-8 px-1">
      <DashboardHeader
        title={"Profile 🧑"}
        subtitle="Manage your personal information details here"
      />
      <div
        className={`mt-10 w-full border ${
          darkMode ? "border-gray-700" : ""
        } shadow-sm rounded-lg p-4 lg:p-16`}
      >
        <div
          className={`border ${
            darkMode ? "border-gray-700" : ""
          } p-3 lg:p-10 rounded-lg relative`}
        >
          <p className="font-bold text-sm">Personal information</p>

          <ProfileDotBtn
            handleDelete={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <div className="lg:flex justify-between pt-6">
            <div className=" relative w-1/2 lg:w-1/4 pr-16">
              <img
                src={data?.profile_image ?? avatar_}
                alt="profile"
                className="rounded-lg w-full h-full"
              />
              <img
                src={upload}
                alt="profile"
                width={50}
                height={50}
                className="absolute -bottom-5 left-16"
              />
            </div>
            <div className=" relative w-full lg:w-3/4 flex justify-between items-start flex-wrap">
              <div className="w-1/2 lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Full Name</p>
                <p className="text-sm font-semibold">{`${data?.first_name} ${data?.last_name}`}</p>
              </div>
              <div className="w-1/2 lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Phone number</p>
                <p className="text-sm font-semibold">{data?.phone_number}</p>
              </div>
              <div className="w-1/2 lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Residential address</p>
                <p className="text-sm font-semibold">{data?.address}</p>
              </div>
              <div className="w-1/2 lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Gender</p>
                <p className="text-sm font-semibold">{data?.gender}</p>
              </div>

              <div className="w-full lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Email address</p>
                <p className="text-sm font-semibold">{data?.email}</p>
              </div>
              <div className="w-1/2 lg:w-1/3 mb-4">
                <p className="text-gray-600 text-sm">Date of birth</p>
                <p className="text-sm font-semibold">
                  {moment(data?.dob).format("Do MMM, YYYY")}
                </p>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-500 w-full mt-8"></div>

          <p className="font-bold text-sm my-6">Medical information </p>

          <div className=" relative w-3/4 flex justify-between items-start flex-wrap">
            <div className="w-1/3 mb-4">
              <p className="text-gray-600 text-sm">Blood group</p>
              <p className="text-sm font-semibold">{data?.blood_group}</p>
            </div>
            <div className="w-1/3 mb-4">
              <p className="text-gray-600 text-sm">Genotype</p>
              <p className="text-sm font-semibold">{data?.genotype}</p>
            </div>
            <div className="w-1/3 mb-4">
              <p className="text-gray-600 text-sm">Disability</p>
              <p className="text-sm font-semibold">{data?.disability}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import { Navigate, Route, Routes } from "react-router-dom";
import MainRoutes from "./Routes";
import { PrivateRoutes } from "./Routes/private";
import { Toaster } from "react-hot-toast";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import CreateAccount from "./pages/auth/CreateAccount";
import OtpAuth from "./pages/auth/otp_authentication";
import ForgotPassword from "./pages/auth/forgot_password";
import ResetPassword from "./pages/auth/Reset_password";
import Successful from "./pages/auth/Reset_success";
import StudentLogin from "./pages/auth/Login/StudentLogin";
import AdminLogin from "./pages/auth/Login/AdminLogin";
import TeacherLogin from "./pages/auth/Login/TeacherLogin";
import NotFound from "./pages/NotFound";
import LandingPage from "./pages/auth/LandingPage";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Store references to the original console methods
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    console.log = function () {};

    // Override other console methods if needed
    console.warn = function () {};
    console.error = function () {};

    // Cleanup function to restore original console methods
    return () => {
      console.log = originalLog;
      console.warn = originalWarn;
      console.error = originalError;
    };
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRoutes>
              <MainRoutes />
            </PrivateRoutes>
          }
        />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/student-login" element={<StudentLogin />} />
        <Route path="/parent-login" element={<Login />} />
        <Route path="/teacher-login" element={<TeacherLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/create_account" element={<CreateAccount />} />
        <Route path="/otp_authentication" element={<OtpAuth />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/successful" element={<Successful />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
}

export default App;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { leftArrow } from '../../../../assets';
import DashboardHeader from '../../../../components/headers/dashboard-header';
import Warddetails from './pages/Ward_details';
import { useQuery } from 'react-query';
import { showWard } from '@/services/parent/dashboard';
import { SolarPower } from '@mui/icons-material';

const Seeetails = () => {
	const location = useLocation();
	const studentId = location.pathname.split('/').pop();

	const { data } = useQuery(['showWard', studentId], () => showWard(studentId));

	return (
		<div className='mt-5 px-5'>
			<div className='flex gap-5'>
				<Link to='/parent/myward/home'>
					<img src={leftArrow} width={50} height={30} alt='left' />
				</Link>

				<DashboardHeader title='Students Details' subtitle='See student information.' />
			</div>

			<div>{data && <Warddetails selectedStudent={data?.data} />}</div>
		</div>
	);
};

export default Seeetails;

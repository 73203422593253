import React from "react";
// import Modal from '../../teachers/Students/modals/SearchParentModal';
import Modal from "../../../components/Modal";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";

function Timetable() {
  return (
    <>
      <Modal open={false} close={true} maxWidth="md">
        <div className="px-7 mb-6">
          <h1 className="text-2xl mb-4">Add Subjects</h1>

          <div className="flex justify-between items-start w-full flex-wrap mb-10">
            <div className="flex justify-between border-2 border-gray-200 rounded-[8px] p-3 text-gray-500 w-full lg:w-[49%] mb-5">
              <div>Mathematics</div>
              <div>
                <Checkbox />
              </div>
            </div>
            <div className="flex justify-between border-2 border-gray-200 rounded-[8px] p-3 text-gray-500 w-full lg:w-[49%]">
              <div>Mathematics</div>
              <div>
                <Checkbox />
              </div>
            </div>

            <div className="flex justify-between border-2 border-gray-200 rounded-[8px] p-3 text-gray-500 w-full lg:w-[49%]">
              <div>Mathematics</div>
              <div>
                <Checkbox />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-start w-full flex-wrap">
            <div className="w-full lg:w-[49%]">
              <Button
                iconClassName="h-[0.9rem] w-3"
                title={"Login"}
                bgColor={"bg-brand"}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-4 rounded-lg bg-brand w-full mt-4"}
              />
            </div>
            <div className="w-full lg:w-[49%]">
              <Button
                iconClassName="h-[0.9rem] w-3"
                title={"Cancel"}
                bgColor={"bg-brand"}
                hoverColor={""}
                textColor={"text-gray-500"}
                className={"py-4 rounded-lg bg-white border w-full mt-4"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Timetable;

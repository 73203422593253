"use client";
import React, { useState, useEffect } from "react";

import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { left, logo } from "../../../assets";
import Button from "../../../components/button";

const OtpAuth = () => {
  const [seconds, setSeconds] = useState(0);
  function formatTime(seconds: any) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `0${minutes}:${remainingSeconds}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const inputs = ["text", "text", "text", "text", "text"];
  return (
    <div className="w-full min-h-screen bg-login">
      <div className="flex justify-between">
        <button type="button" className="">
          <img
            src={left}
            width={50}
            height={30}
            alt="left"
            className="absolute mt-7 ml-7"
          />
        </button>
        <div className="absolute w-fit h-[90%] bg-slate-50 right-0 mt-12 mr-14 rounded-lg">
          <div className="bg-[#93002F] w-full h-2 rounded-t-lg" />
          <div className="mx-10 my-10 flex flex-col">
            <img
              src={logo}
              width={150}
              height={100}
              alt="just image "
              className="pt-5 pb-10"
            />
            <div className="flex flex-col gap-[5px]">
              <span className="font-semibold text-2xl text-gray-800">
                Email adress verification
              </span>
              <span className="text-gray-300 text-sm">
                Enter 5 digits OTP that was sent to your email <br />
                adress sh************@gmail.com
              </span>
            </div>
            <form action="" className="flex flex-col gap-1 mb-[13rem] mt-8">
              <div className="flex gap-5 justify-center px-6">
                {inputs.map((tag, i) => (
                  <div key={i}>
                    <input
                      type={tag}
                      className="h-10 w-10 rounded-lg bg-transparent border-[3px] border-gray-400 outline-none text-center font-bold"
                    />
                  </div>
                ))}
              </div>

              <div className="text-center mt-3 text-xs text-gray-300">
                <span>
                  <TimerOutlinedIcon />
                </span>
                <span>
                  OTP code expires in:{" "}
                  <span className="font-bold text-gray-950">
                    {formatTime(seconds)}
                  </span>
                </span>
              </div>
              <div>
                <div className="text-center mt-3 text-xs ">
                  <span className="text-gray-300 font-[500]">
                    Didn't get the code,{" "}
                    <span className="text-[#93002F] font-bold cursor-pointer">
                      Send again
                    </span>
                  </span>
                  <span></span>
                </div>
              </div>
              {/* <Button
                text="Continue"
                textStyles="text-white"
                btnStyles="bg-[#93002F] py-3 mt-7 rounded-lg"
              /> */}
              <Button
                iconClassName="h-[0.9rem] w-3"
                // isLoading={loading}
                title={"Continue"}
                bgColor={"bg-brand"}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-4 rounded-lg bg-other w-full mt-4"}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpAuth;

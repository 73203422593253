/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { registerTeacherAPI } from '../../../services/authServices';
import { getClass, getPermissions, getRoles } from '../../../services/Admin/dashboard';
import { getAllSubject, getClassSubject } from '../../../services/Admin/subject';
import ImageInput from '../../image-input';
import Input from '../Input';
import Select from '../Select';
import DatePicker from '../DatePicker';
import Button from '../../button';
import SuccessModal from '../../Modal/SuccessModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MultipleSelectCheckmarksV2 from '@/components/Multiselect/variant';
import { IconButton } from '@mui/material';
import { useAppDispatch } from '@/hooks';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';
import { MultiSelect } from 'react-multi-select-component';

export interface SubjectProps {
	id: string;
	name: string;
}

const AddTeachersForm = () => {
	const [modal, setModal] = useState(false);
	const registerTeacherMutation = useMutation(registerTeacherAPI);
	const [file, setFile] = useState<any>([]);
	const [classId, setClassId] = useState<any>();
	const [allSubjects, setAllSubjects] = useState<any>();
	const { data: classData, refetch } = useQuery(['class', ''], () => getClass(''));

	const [classValue, setClassValue] = useState<any>([]);
	const [date_joined, setDate_joined] = useState<any>();
	const [dob, setDob] = useState<any>();
	const navigate = useNavigate();
	const param = {
		page: 1,
		id: classId,
	};
	const [selected, setSelected] = useState([]);
	const { data: subjectData, refetch: subjectRefetch } = useQuery(['subject', param], () =>
		getClassSubject(param)
	);

	const { data: permissionData } = useQuery(['permission', 'TEACHER'], () =>
		getPermissions('TEACHER')
	);

	const [classInputs, setClassInputs] = useState<any>([{ class: '', subjects: '' }]);

	useEffect(() => {
		subjectRefetch();
	}, [classId]);
	const options = permissionData?.map((item: any) => ({
		label: item.name,
		value: item.name,
	}));
	useEffect(() => {
		if (subjectData?.data?.length > 0) {
			const subjects = subjectData?.data?.map((item: any) => ({
				id: item?.subject?.id,
				name: item?.subject?.name,
			}));
			setAllSubjects(subjects);
		}
	}, [subjectData]);
	const handleAddClass = (event: any) => {
		event.preventDefault();
		setClassInputs([...classInputs, { class: '', subjects: [] }]);
	};

	const handleRemoveClass = (index: any) => {
		const updatedInputs = [...classInputs];
		updatedInputs.splice(index, 1);
		setClassInputs(updatedInputs);
	};

	const handleClassChange = (index: any, value: any) => {
		const updatedInputs = [...classInputs];
		setClassId(value);
		updatedInputs[index].class = value;
		setClassInputs(updatedInputs);
	};

	const handleSubjectsChange = (index: any, event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		const updatedInputs = [...classInputs];
		updatedInputs[index].subjects = value as string; // Explicitly specify the type
		setClassInputs(updatedInputs);
	};

	const darkMode = useSelector(selectDarkmode);

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className=''>
				<Formik
					initialValues={{
						role: '',
						first_name: '',
						last_name: '',
						other_name: '',
						gender: '',
						phone_number: '',
						email: '',
						residential_address: '',
						blood_group: '',
						genotype: '',
						disability: '',
						bank_name: '',
						account_number: '',
						account_name: '',
						next_of_kin_name: '',
						next_of_kin_phone_number: '',
						next_of_kin_email: '',
						next_of_kin_address: '',
						next_of_kin_relationship: '',
						dob: '',
						class: '',
						subject: '',
						password: '',
						date_joined: '',
					}}
					// validationSchema={loginSchema}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const formData = new FormData();

						formData.append('role', 'TEACHER');
						formData.append('first_name', values.first_name);
						formData.append('last_name', values.last_name);
						if (values.other_name) {
							formData.append('other_name', values.other_name);
						}
						formData.append('gender', values.gender);
						formData.append('phone_number', values.phone_number);
						formData.append('email', values.email);
						formData.append('address', values.residential_address);
						formData.append('blood_group', values.blood_group);
						formData.append('genotype', values.genotype);
						formData.append('disability', values.disability);
						formData.append('bank_name', values.bank_name);
						formData.append('account_number', values.account_number);
						formData.append('account_name', values.account_name);
						formData.append('next_of_kin_name', values.next_of_kin_name);
						formData.append('next_of_kin_phone_number', values.next_of_kin_phone_number);
						formData.append('next_of_kin_address', values.next_of_kin_address);
						formData.append('next_of_kin_email', values.next_of_kin_email);
						formData.append('next_of_kin_relationship', values.next_of_kin_relationship);
						if (values.password) {
							formData.append('password', values.password);
						}
						formData.append('dob', dob);
						formData.append('date_joined', date_joined);
						formData.append('profile_image', file[0]);

						if (classValue?.length > 0) {
							for (let i = 0; i < classValue.length; i++) {
								formData.append(`permissions[]`, classValue[i]?.value);
							}
						}
						if (classInputs?.length > 0) {
							classInputs?.forEach((item: any, i: number) => {
								if (item?.class) {
									formData.append(`teacher_class_subjects[${i}][class_id]`, item.class);
								}

								// item.subjects?.forEach((subject: any) => {
								if (item?.subjects) {
									formData.append(`teacher_class_subjects[${i}][subject_id]`, item.subjects);
								}
								// });
							});
						}

						try {
							const res = await registerTeacherMutation.mutateAsync(formData);

							if (res?.data) {
								setModal(true);
								resetForm();
							}
						} catch (err: any) {
							if (err?.response?.data.message) {
								toast.error(err?.response?.data.message, {
									duration: 3000,
								});
							} else {
								toast.error('Something went wrong. Try again later', {
									duration: 3000,
								});
							}
						}
					}}
				>
					{({
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form>
							<div
								className={`border mt-6 lg:px-7 px-4 rounded-lg ${
									darkMode ? 'border-gray-700' : ''
								}`}
							>
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Personal information</span>
									<div className='py-5 w-40'>
										<ImageInput file={file} setFile={setFile} />
									</div>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs '>
												First name
											</label>
											<Input
												name={'first_name'}
												placeholder={'Full name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Last name
											</label>
											<Input
												name={'last_name'}
												placeholder={'Last name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Gender
											</label>
											<Select
												name='gender'
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											>
												<option value=''>Select a gender</option>
												<option value='male'>Male</option>
												<option value='female'>Female</option>
											</Select>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Phone number
											</label>
											<Input
												name={'phone_number'}
												placeholder={'Phone number'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'datejoined'}
												type={'date'}
												placeholder={'Date joined'}
												value={date_joined}
												setValue={setDate_joined}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'dob'}
												type={'date'}
												placeholder={'Date of Birth'}
												value={dob}
												setValue={setDob}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Residential address
											</label>
											<Input
												name={'residential_address'}
												placeholder={'Enter residential address'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Email address
											</label>
											<Input
												name={'email'}
												placeholder={'Enter Email address'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Bank
											</label>
											<Input
												name={'bank_name'}
												placeholder={'Enter Bank Name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Account number
											</label>
											<Input
												name={'account_number'}
												placeholder={'Enter Account Number'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Account Name
											</label>
											<Input
												name={'account_name'}
												placeholder={'Enter Account name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Permissions
											</label>
											{permissionData !== undefined &&
												options?.length > 0 &&
												permissionData?.length > 0 && (
													<div className='mt-2'>
														<MultiSelect
															options={options}
															value={classValue}
															onChange={setClassValue}
															labelledBy='Select'
														/>
													</div>
												)}
										</div>
										{classInputs.map((input: any, index: number) => (
											<div
												key={index}
												className='flex justify-between items-center w-full flex-wrap'
											>
												<div className='w-[49%]'>
													<label htmlFor={`class-${index}`} className='text-gray-400 text-xs'>
														Class
													</label>
													<select
														id={`class-${index}`}
														value={input.class}
														onChange={(e) => handleClassChange(index, e.target.value)}
														className='appearance-none border-gray-300 border-[0.7px] rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200'
													>
														<option value=''>Select class</option>
														{classData?.data?.map((data: any) => (
															<option
																key={data.id}
																value={`${!data.name ? 'select class' : data?.id}`}
															>
																{data?.name}
															</option>
														))}
													</select>
												</div>
												<div className='w-[49%]'>
													<label htmlFor={`subject-${index}`} className='text-gray-400 text-xs'>
														Subject
													</label>
													<select
														id={`subject-${index}`}
														value={input.subjects}
														onChange={(e) => handleSubjectsChange(index, e)} // Modified this line
														className='appearance-none border-gray-300 border-[0.7px] rounded-lg w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200'
													>
														<option value=''>Select subject</option> {/* Modified this line */}
														{allSubjects?.map((data: any) => (
															<option
																key={data.id}
																value={`${!data.name ? 'select subject' : data?.id}`}
															>
																{data?.name}
															</option>
														))}
													</select>
												</div>
												{classInputs.length > 1 && (
													<IconButton onClick={() => handleRemoveClass(index)}>
														<DeleteForeverIcon className={'text-brand cursor-pointer'} />
													</IconButton>
												)}
											</div>
										))}

										<div className='flex justify-center items-center'>
											<Button
												isLoading={isSubmitting}
												title={'Add Another Class'}
												bgColor={'bg-other'}
												hoverColor={''}
												type={'button'}
												textColor={'text-white'}
												className={'py-3 rounded-lg bg-blue-900 lg:w-40 xs:w-[20rem] my-9  '}
												onClick={(event: any) => handleAddClass(event)}
											/>
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? '  border-gray-700' : ''}`} />
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Medical information</span>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Blood Group
											</label>
											<Input
												name={'blood_group'}
												placeholder={'Blood group'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Genotype
											</label>
											<Input
												name={'genotype'}
												placeholder={'genotype'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Disability
											</label>
											<Select
												name='disability'
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											>
												<option value=''>select an option</option>
												<option value='yes'>Yes</option>
												<option value='no'>No</option>
											</Select>
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? ' border-gray-700' : ''}`} />
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Next of kin information</span>

									<div className='flex justify-between items-start flex-wrap mt-6 '>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Next of kin name
											</label>
											<Input
												name={'next_of_kin_name'}
												placeholder={'Enter next of kin name'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Next of kin email
											</label>
											<Input
												name={'next_of_kin_email'}
												placeholder={'Enter next of kin email'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Next of kin phone number
											</label>
											<Input
												name={'next_of_kin_phone_number'}
												placeholder={'Enter next of kin phone number'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Next of kin address
											</label>
											<Input
												name={'next_of_kin_address'}
												placeholder={'Enter next of kin address'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Next of kin relationship
											</label>
											<Input
												name={'next_of_kin_relationship'}
												placeholder={'Enter next of kin relationship'}
												className={`${
													darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
												}`}
											/>
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? ' border-gray-700' : ''}`} />
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Login information</span>

									<div className=' w-full lg:w-[49%] mt-6'>
										<label htmlFor='text' className='text-gray-400 text-xs'>
											Password
										</label>

										<Input
											name={'password'}
											placeholder={'Enter password'}
											password
											className={`${
												darkMode ? 'placeholder:text-gray-300 bg-dark-200 border-gray-700' : ''
											}`}
										/>
									</div>
								</div>
								<div className='flex justify-center items-center'>
									<Button
										iconClassName='h-[0.9rem] w-3'
										isLoading={isSubmitting}
										title={'Add Teacher'}
										bgColor={'bg-other'}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  '}
									/>
								</div>

								<SuccessModal
									isOpen={modal}
									isClose={() => {
										navigate('/admin/teachers');
										setModal(false);
									}}
									title={'Teacher has been added successfully to the system.'}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</main>
	);
};

export default AddTeachersForm;

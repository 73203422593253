import { left, logo } from '../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import { useAppDispatch } from '../../../hooks';
import { setRole, setToken, setUser } from '../../../states/slices/authReducer';
import { Formik } from 'formik';
import { Fade } from 'react-reveal';
import Input from '../../../components/form/Input';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { loginAPI } from '../../../services/authServices';

function AdminLogin() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const loginMutation = useMutation(loginAPI);

	return (
		<div className='w-screen h-screen bg-admin flex flex-row justify-center lg:justify-between items-center lg:items-start'>
			<Link to={'/'} type='button' className='hidden lg:block'>
				<img src={left} width={50} height={30} alt='left' className='absolute mt-7 ml-7' />
			</Link>
			<div className=' w-[90%] lg:w-[30vw]  h-[90vh] lg:h-fit bg-slate-50 mt-4 lg:mr-14 rounded-lg'>
				<div className='bg-[#DD0046] w-full h-2 rounded-t-lg' />
				<div className='mx-10 my-10 flex flex-col'>
					<img src={logo} width={150} height={100} alt='just image ' className='pt-5 pb-10' />

					<div className='flex flex-col gap-[5px]'>
						<span className='font-semibold text-xl'>Admin Login</span>
						<span className='text-gray-300 text-sm'>Login to access your dashboard</span>
					</div>

					<Fade left>
						<Formik
							initialValues={{ email: '', password: '' }}
							// validationSchema={loginSchema}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								const formData = {
									email: values.email,
									password: values.password,
								};

								try {
									const res = await loginMutation.mutateAsync(formData);

									if (res?.data?.role === 'ADMIN') {
										dispatch(setUser(res?.data));
										dispatch(setRole(res?.data?.role));
										dispatch(setToken(res?.data?.token));
										navigate('/admin/dashboard');
										toast.success('Login successful', {
											duration: 5000,
										});
									} else {
										toast.error('Kindly login as an Admin', {
											duration: 5000,
										});
									}
								} catch (err: any) {
									toast.error(err?.response?.data?.message, {
										duration: 3000,
									});
								}
							}}
						>
							{({
								handleSubmit,
								isSubmitting,
								/* and other goodies */
							}) => (
								<form className='flex flex-col pt-3 md:pt-8' onSubmit={handleSubmit}>
									<div className='flex flex-col pt-4'>
										<label htmlFor='email' className='text-sm'>
											Email
										</label>
										<Input type='email' placeholder='Enter student ID here' name={'email'} />
									</div>
									<div className='flex flex-col pt-4'>
										<label htmlFor='password' className='text-sm'>
											Password
										</label>
										<Input
											type='password'
											placeholder='password'
											isPassword={true}
											name={'password'}
										/>
									</div>
									<div className='flex justify-between mt-2'>
										<span className='flex gap-2'>
											<input type='checkbox' className='text-3xl' />
											<span className='font-[500] text-gray-500 text-xs'>Remember me</span>
										</span>
										<Link to='/forgot-password' className='font-semibold text-xs'>
											Forget password?
										</Link>
									</div>

									<Button
										iconClassName='h-[0.9rem] w-3'
										isLoading={isSubmitting}
										title={'Login'}
										bgColor={'bg-brand'}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-4 rounded-lg bg-brand w-full mt-4'}
									/>
								</form>
							)}
						</Formik>
					</Fade>
				</div>
			</div>
		</div>
	);
}

export default AdminLogin;

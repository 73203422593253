import { useState } from 'react';
import { Checked, Horizontal_Line, leftArrow } from '../../../../../assets';
import DashboardHeader from '../../../../../components/headers/dashboard-header';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import RoleSuccess from '../../modals/RoleSuccess';

const roleInputs = [
	{
		label: 'Role title',
		placeholder: 'Enter title',
	},
	{
		label: 'Role description',
		placeholder: 'Enter role description',
	},
];

interface MyItems {
	label: string;
}
const selector: MyItems[] = [
	{
		label: 'Takes attendance',
	},
	{
		label: 'Marks examination script',
	},
	{
		label: 'Taks classes',
	},
];
const Roles = () => {
	const [checked, setChecked] = useState<MyItems[]>([]);
	const [modal, setModal] = useState(false);

	const isChecked = (item: MyItems) => {
		return checked.some((checked) => checked.label === item.label);
	};
	const handleChecked = (item: MyItems) => {
		// setChecked(item);
		if (isChecked(item)) {
			setChecked(checked.filter((object) => object.label !== item.label));
		} else {
			setChecked([...checked, item]);
		}
	};

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className='flex gap-3'>
				<Link to='/admin/users-and-roles'>
					<img src={leftArrow} alt='' />
				</Link>
				<DashboardHeader title='Users & Roles' subtitle='See and manage users and roles here.' />
			</div>
			<div className='mt-5'>
				<span className='font-medium'>Add new roles</span>
				<div className='lg:px-24 xs:px-0 mt-9'>
					<div className='border-2 rounded-lg bg-white shadow-box w-full lg:h-[700px] xs:h-full lg:px-14 xs:px-4 py-10'>
						<div className='flex lg:flex-row xs:flex-col gap-10 items-start'>
							<div className='flex flex-col gap-5'>
								{roleInputs.map((item) => (
									<div className='flex flex-col gap-[5px] '>
										<label htmlFor='text' className='text-sm text-gray-400'>
											{item.label}
										</label>
										<input
											type='text'
											placeholder={item.placeholder}
											className='border lg:w-[26rem] xs:w-[21rem] rounded-lg py-[0.8rem] pl-3 placeholder:text-sm outline-none'
										/>
									</div>
								))}

								<Button
									iconClassName='h-[0.9rem] w-3'
									// isLoading={loading}
									onClick={() => setModal(true)}
									title={'Add Role'}
									bgColor={'bg-other'}
									hoverColor={''}
									textColor={'text-white'}
									className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem]  my-9  '}
								/>
							</div>
							{modal && (
								<RoleSuccess isOpen={() => setModal(true)} isClose={() => setModal(false)} />
							)}
							<div className='flex flex-col items-start border-l-[2.2px] border-l-[#E5E6EB] h-[40rem] pl-4'>
								<div className=' pb-8'>
									<span className=' font-semibold'>Role permissions</span>
									<p className='text-gray-500'>
										Please select from the list of available permissions.
									</p>
								</div>
								<img src={Horizontal_Line} alt='' />
								<div className='pt-4'>
									<span className='font-semibold'>Permission title</span>
									<div className='flex flex-col gap-4 py-4'>
										{selector.map((item) => (
											<label htmlFor='tag' className='flex gap-3 '>
												<div
													className={`${
														isChecked(item) ? 'bg-[#FC91B3]' : 'bg-transparent'
													} w-7 h-7 rounded-lg border-2 flex justify-center items-center cursor-pointer`}
													onClick={() => handleChecked(item)}
												>
													{isChecked(item) ? (
														<img src={Checked} alt='' width={15} height={15} />
													) : null}
												</div>
												<span>{item.label}</span>
											</label>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Roles;

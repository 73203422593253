import { useState } from "react";
import Modal from "../../../../components/Modal";
import { cancel } from "../../../../assets";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/button";
import DeletedSuccess from "./deletedSuccess";

interface Props {
  isClose: () => void;
  isOpen: () => void;
}
const DeleteUser = ({ isOpen, isClose }: Props) => {
  const [modal, setModal] = useState(false);
  return (
    <Modal maxWidth="xs" open={isOpen} close={isClose}>
      <div className="px-8">
        <div className="flex justify-center items-center flex-col gap-4">
          <img src={cancel} alt="" />
          <Typography textStyle=" px-8 text-center" variant="h5">
            Are you sure you want to delete user?
          </Typography>
        </div>
        <div className="flex flex-col gap-3 mt-9">
          <label htmlFor="text">Why do you want to delete user?</label>
          <textarea
            name=""
            id=""
            rows={5}
            placeholder="Enter reason here..."
            className="placeholder:italic border px-3 pt-2 rounded-lg"
          />
          <Button
            iconClassName="h-[0.9rem] w-3"
            // isLoading={loading}
            title={"Delete user"}
            onClick={() => setModal(true)}
            bgColor={"bg-transparent"}
            hoverColor={""}
            textColor={"text-[#F50C0C]"}
            className={
              "py-4 rounded-lg border-4 w-full mt-5  text-2xl border-[#F50C0C]"
            }
          />
          <Button
            iconClassName="h-[0.9rem] w-3"
            // isLoading={loading}
            title={"close"}
            onClick={isClose}
            bgColor={"bg-transparent"}
            hoverColor={""}
            textColor={"text-black"}
            className={"py-4 rounded-lg border w-full my-2 mb-8 text-xl"}
          />
        </div>
        {modal && (
          <DeletedSuccess
            isOpen={() => setModal(true)}
            isClose={() => setModal(false)}
          />
        )}
      </div>
    </Modal>
  );
};

export default DeleteUser;

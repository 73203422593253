/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { registerAdminAPI } from '../../../services/authServices';
import { getPermissions, getRoles } from '../../../services/Admin/dashboard';
import ImageInput from '../../image-input';
import Input from '../Input';
import Select from '../Select';
import DatePicker from '../DatePicker';
import MultipleSelectCheckmarksV2 from '../../Multiselect/variant';
import Button from '../../button';
import SuccessModal from '../../Modal/SuccessModal';
import { UserData } from '../../../types';
import { updateAdminAPI } from '../../../services/profile';
import { selectDarkmode, setUser } from '../../../states/slices/authReducer';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';

interface profileProps {
	data?: UserData;
	permission?: any[];
}

const AddAdminForm = (props: profileProps) => {
	const { data } = props;
	const [modal, setModal] = useState(false);
	const registerAdminMutation = useMutation(registerAdminAPI);
	const [file, setFile] = useState<any>([]);

	const { data: permissionData, refetch } = useQuery(['permission', 'ADMIN'], () =>
		getPermissions('ADMIN')
	);
	const dispatch = useAppDispatch();
	const [classValue, setClassValue] = useState<any>([]);

	const [date_joined, setDate_joined] = useState<any>(
		moment(data?.date_joined).format('yyyy-MM-DD') ?? ''
	);
	const [dob, setDob] = useState<any>(moment(data?.dob).format('yyyy-MM-DD') ?? '');
	const navigate = useNavigate();
	const darkMode = useSelector(selectDarkmode);
	const options = permissionData?.map((item: any) => ({
		label: item.name,
		value: item.name,
	}));

	useEffect(() => {
		if (data !== undefined) {
			const optionsData = data?.permissions?.map((item: any) => ({
				label: item.name,
				value: item.name,
			}));
			setClassValue(optionsData);
		}
	}, [data]);

	return (
		<main className='lg:px-8 xs:px-4 mt-7'>
			<div className=''>
				<Formik
					initialValues={{
						first_name: data?.first_name ?? '',
						last_name: data?.last_name ?? '',
						other_name: data?.other_name ?? '',
						gender: data?.gender ?? '',
						phone_number: data?.phone_number ?? '',
						email: data?.email ?? '',
						residential_address: data?.address ?? '',
						blood_group: data?.blood_group ?? '',
						genotype: data?.genotype ?? '',
						dob: data?.dob ?? '',
						password: '',
						date_joined: data?.date_joined ?? '',
					}}
					onSubmit={async (values, { setSubmitting, resetForm }) => {
						const formData = new FormData();

						formData.append('first_name', values.first_name);
						formData.append('last_name', values.last_name);
						if (values.other_name) {
							formData.append('other_name', values.other_name);
						}
						formData.append('gender', values.gender);
						formData.append('phone_number', values.phone_number);
						formData.append('email', values.email);
						formData.append('address', values.residential_address);
						formData.append('blood_group', values.blood_group);
						formData.append('genotype', values.genotype);
						if (values.password) {
							formData.append('password', values.password);
						}
						formData.append('dob', dob ? dob : data?.dob);
						formData.append('date_joined', date_joined ? date_joined : data?.date_joined);
						if (file?.length > 0) {
							formData.append('profile_image', file[0]);
						}

						for (let i = 0; i < classValue?.length; i++) {
							formData.append(`permissions[]`, classValue[i]?.value);
						}

						try {
							const res = data
								? await updateAdminAPI(formData, data?.uuid)
								: await registerAdminMutation.mutateAsync(formData);

							if (res?.data) {
								setModal(true);
								resetForm();
								dispatch(setUser(res?.data));
								file.length = 0;
							}
						} catch (err: any) {
							if (err?.response?.data.message) {
								toast.error(err?.response?.data.message, {
									duration: 3000,
								});
							} else {
								toast.error('Something went wrong. Try again later', {
									duration: 3000,
								});
							}
						}
					}}
				>
					{({
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form>
							<div
								className={`border mt-6 lg:px-7 px-4 rounded-lg ${
									darkMode ? 'border-gray-700' : ''
								}`}
							>
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Personal information</span>
									<div className='py-5 w-40'>
										<ImageInput file={file} setFile={setFile} data={data?.profile_image} />
									</div>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												First name
											</label>
											<Input
												name={'first_name'}
												placeholder={'Full name'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Last name
											</label>
											<Input
												name={'last_name'}
												placeholder={'Last name'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Gender
											</label>
											<Select
												name='gender'
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											>
												<option value=''>Select a gender</option>
												<option value='male'>Male</option>
												<option value='female'>Female</option>
											</Select>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Phone number
											</label>
											<Input
												name={'phone_number'}
												placeholder={'Phone number'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'datejoined'}
												type={'date'}
												placeholder={'Date joined'}
												value={date_joined}
												setValue={setDate_joined}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<DatePicker
												name={'dob'}
												type={'date'}
												placeholder={'Date of Birth'}
												value={dob}
												setValue={setDob}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Residential address
											</label>
											<Input
												name={'residential_address'}
												placeholder={'Enter residential address'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Email address
											</label>
											<Input
												name={'email'}
												placeholder={'Enter Email address'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>

										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400 text-xs'>
												Permissions
											</label>

											{permissionData !== undefined &&
												options?.length > 0 &&
												permissionData?.length > 0 && (
													<div className='mt-2'>
														<MultiSelect
															options={options}
															value={classValue}
															onChange={setClassValue}
															labelledBy='Select'
														/>
													</div>
												)}
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? ' border-gray-700 ' : ''}`} />
								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Medical information</span>
									<div className='flex justify-between items-start flex-wrap'>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400'>
												Blood Group
											</label>
											<Input
												name={'blood_group'}
												placeholder={'Blood group'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>
										<div className=' w-full lg:w-[49%]'>
											<label htmlFor='text' className='text-gray-400'>
												Genotype
											</label>
											<Input
												name={'genotype'}
												placeholder={'genotype'}
												className={`${
													darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
												}`}
											/>
										</div>
									</div>
								</div>
								<hr className={`${darkMode ? ' border-gray-700' : ''}`} />

								<div className='pb-7 py-6'>
									<span className='font-medium text-lg'>Login information</span>

									<div className=' w-full lg:w-[49%] mt-6'>
										<label htmlFor='text' className='text-gray-400'>
											Password
										</label>
										<Input
											name={'password'}
											placeholder={'Enter password'}
											password
											className={`${
												darkMode ? 'bg-dark-200 border-gray-700 placeholder:text-gray-400' : ''
											}`}
										/>
									</div>
								</div>
								<div className='flex justify-center items-center'>
									<Button
										iconClassName='h-[0.9rem] w-3'
										isLoading={isSubmitting}
										title={data ? 'Save Changes' : 'Add Admin'}
										bgColor={'bg-other'}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-5 rounded-lg bg-brand lg:w-[25rem] xs:w-[20rem] my-9  '}
									/>
								</div>

								<SuccessModal
									isOpen={modal}
									isClose={() => {
										// navigate("/admin/teachers");
										setModal(false);
									}}
									title={
										data
											? 'Profile has been updated successfully.'
											: 'Profile has been added successfully.'
									}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</main>
	);
};

export default AddAdminForm;

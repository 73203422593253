import { useState } from 'react';
import DashboardHeader from '@/components/headers/dashboard-header';
import {
	ArrowDown,
	DARK_MODE_ARROW,
	UserAdmin,
	UserParent,
	UserStaff,
	UserTeacher,
	WHITE_ARROW_DOWN,
	leftArrow,
} from '@/assets';
import AddTeachersForm from '@/components/form/authForms/AddTeacher';
import AddStudentForm from '@/components/form/authForms/AddStudent';
import AddParentForm from '@/components/form/authForms/AddParents';
import AddAdminForm from '@/components/form/authForms/AddAdmin';
import AddNonTeachersForm from '@/components/form/authForms/AddNonTeacher';
import SelectModal from '../../modals/SelectModal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

export const selectRoleModal = [
	{
		label: 'Admin',
		content: (
			<div>
				<AddAdminForm />
			</div>
		),
		icon: UserAdmin,
	},
	{
		label: 'Teacher',
		content: (
			<div>
				<AddTeachersForm />
			</div>
		),
		icon: UserTeacher,
	},
	{
		label: 'Student',
		content: (
			<div>
				<AddStudentForm />
			</div>
		),
		icon: UserAdmin,
	},
	{
		label: 'Parent',
		content: (
			<div>
				<AddParentForm />
			</div>
		),
		icon: UserParent,
	},

	{
		label: 'Non-teaching Staff',
		content: (
			<div>
				<AddNonTeachersForm />
			</div>
		),
		icon: UserStaff,
	},
];

const UserStudent = () => {
	const [modal, setModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState('Admin');

	const selectedValue = (value: any) => {
		setSelectedUser(value);
		setModal(false);
	};

	const darkMode = useSelector(selectDarkmode);

	return (
		<main className='lg:px-9 xs:px-4 mt-7'>
			<div className='flex justify-between items-start w-full'>
				<div className='flex gap-3'>
					<Link to='/admin/users-and-roles'>
						{darkMode ? (
							<div className='w-10 h-10 justify-center items-center bg-black rounded-full flex'>
								<img src={DARK_MODE_ARROW} alt='' className='w-4' />
							</div>
						) : (
							<img src={leftArrow} alt='left-arrow' />
						)}
					</Link>
					<DashboardHeader
						title={`Add a user (${selectedUser})`}
						subtitle='See and manage users and roles here.'
					/>
				</div>
				<div className=' flex justify-between'>
					<div
						className={`flex gap-4   ${
							darkMode ? 'bg-black' : 'bg-[#F7F7F7]'
						} px-5 py-2 rounded-lg cursor-pointer`}
						onClick={() => setModal(true)}
					>
						<span>{!selectedUser ? 'Select user' : selectedUser}</span>
						{darkMode ? (
							<img src={WHITE_ARROW_DOWN} alt='' className='w-3' />
						) : (
							<img src={ArrowDown} alt='' className='w-3' />
						)}
					</div>
				</div>
			</div>
			{modal && (
				<SelectModal
					isOpen={() => setModal(true)}
					isClose={() => setModal(false)}
					onSelect={selectedValue}
				/>
			)}
			<div>
				{selectedUser !== null && (
					<div className='mt-5'>
						{selectRoleModal.map((item) => (item.label === selectedUser ? item.content : null))}
					</div>
				)}
			</div>
		</main>
	);
};

export default UserStudent;

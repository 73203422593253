import React, { useState } from 'react';
import DashboardHeader from '../../../components/headers/dashboard-header';
import { add } from '../../../assets';
import TeacherEmptyData from './contents/teachersEmptyData';
import { Link, useNavigate } from 'react-router-dom';
import { TableComponent } from '../../../components/table/Table';
import { useMutation, useQuery } from 'react-query';
import { deleteTeacher, getUsers } from '../../../services/Admin/dashboard';
import DropDownV2 from '../../../components/menu-dropdown/dropDownV2';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { toast } from 'react-hot-toast';
import { Skeleton } from '@mui/material';

function Teachers() {
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const deleteTeacherMutation = useMutation(deleteTeacher);

	const params = {
		page,
		role: 'TEACHER',
	};
	const { data: userData, refetch } = useQuery(['TEACHER', params], () => getUsers(params));

	const navigate = useNavigate();

	const USER_COLUMNS = [
		{
			Header: 'FULL NAME',

			Cell: ({ row }: any) => (
				<div className='relative'>
					<span>
						{row.original.first_name} {row.original.last_name}
					</span>
				</div>
			),
		},

		{
			Header: 'ROLE',
			accessor: 'role',
		},

		{
			Header: 'ADDRESS',
			accessor: 'address',
		},
		{
			Header: 'EMAIL',
			accessor: 'email',
		},
		{
			Header: 'UNIQUE NUMBER',
			accessor: 'unique_number',
		},
		{
			Header: 'ACTION',
			accessor: '',
			Cell: ({ row }: any) => (
				<div className='relative'>
					<DropDownV2
						handleDelete={() => {
							setConfirmationModal(true);
							setData(row?.original);
						}}
						handleEdit={() => {
							navigate('/admin/teachers/edit-teachers', {
								state: { data: row?.original },
							});
						}}
						editText='teacher'
						handleView={() =>
							navigate('/admin/teachers/view-teachers', {
								state: { data: row?.original },
							})
						}
						viewText='teacher'
					/>
				</div>
			),
		},
	];

	const handleDelete = async (id: any) => {
		setLoading(true);
		try {
			const res: any = await deleteTeacherMutation?.mutateAsync(id);
			if (res) {
				refetch();
				setLoading(false);
				toast.success(res?.message);
				setConfirmationModal(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};
	const handlePrevious = () => {
		if (page > 1) {
			setPage(page - 1);
		}
	};

	const handleNext = () => {
		setPage(page + 1);
	};

	return (
		<main className='mt-6 lg:px-8 px-1'>
			<DashboardHeader title='Teachers' subtitle='Add and manage teachers here' />
			<div>
				<div className='flex justify-between flex-wrap'>
					<span>Teacher’s list</span>
					<div className='flex lg:gap-5 xs:gap-2 xs:flex-col lg:flex-row flex-wrap'>
						{/* <div className='flex gap-8'>
              <img src={filter} alt='' />
              <img src={print} alt='' />
              <img src={exports} alt='' />
            </div> */}
						<Link to='/admin/teachers/add_teachers'>
							<button
								type='button'
								className='bg-other flex gap-3 h-fit w-fit px-6 py-3 rounded-lg hover:scale-90 transition-all'
							>
								<img src={add} alt='' width={20} height={20} />
								<span className='text-white'>Add new teacher</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
			<div className='mt-7'>
				{userData?.data?.length > 0 ? (
					<>
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={userData?.data}
							COLUMNS={USER_COLUMNS}
							setPage={setPage}
							page={page}
						/>
					</>
				) : userData?.data?.length < 1 ? (
					<div className='text-center text-xs'>
						<TeacherEmptyData />
						{page > 1 && (
							<div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
								<div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
									{page > 1 && (
										<button
											className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
											onClick={() => handlePrevious()}
											// disabled={!canPreviousPage}
										>
											Previous
										</button>
									)}

									<span className='mx-4 text-xs'>
										Page <strong>{page}</strong>{' '}
									</span>
									{data?.length > 14 && (
										<button
											className='cursor-pointer px-4 py-2 w-40 border-2 border-brand rounded-lg text-brand'
											onClick={() => handleNext()}
											// disabled={!canNextPage}
										>
											Next
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				) : (
					<div className='w-full mb-3 px-8 flex justify-between items-center'>
						<Skeleton variant='rectangular' height={50} className='w-[50px] rounded-full mb-4' />
						<div className='w-[80%]'>
							<Skeleton variant='rectangular' height={20} className='w-full mb-4' />
							<Skeleton variant='rectangular' height={20} className='w-[80%] mb-4' />
						</div>
					</div>
				)}
			</div>

			<ConfirmationModal
				loading={loading}
				handleClick={() => handleDelete(data?.uuid)}
				isOpen={confirmationModal}
				setIsOpen={setConfirmationModal}
				title={
					'You are about to delete this teacher information. click cancel to cancel this action or proceed with continue'
				}
			/>
		</main>
	);
}

export default Teachers;

import React, { useState, useRef, useEffect } from "react";
import Modal from "../Modal";
import Button from "../button";
import { Add_subject } from "@/assets";
import { useMutation, useQuery } from "react-query";
import { createTimetable, getTimetable } from "@/services/Admin/dashboard";
import { FieldArray, Formik } from "formik";
import toast from "react-hot-toast";
import Select from "../form/Select";
import moment from "moment";
import { getAllSubject } from "@/services/Admin/subject";
import { useSelector } from "react-redux";
import { selectDarkmode } from "@/states/slices/authReducer";

interface Subject {
  subject_id: string;
  start_time: string;
  end_time: string;
}

interface Values {
  first?: string;
  second?: string;
  third?: string;
  fourth?: string;
  fifth?: string;
  firstEnd?: string;
  secondEnd?: string;
  thirdEnd?: string;
  fourthEnd?: string;
  fifthEnd?: string;
  // Add additional fields as needed
}

interface Subject {
  subject_id: string;
  start_time: string;
  end_time: string;
}

interface Values {
  subjects: Subject[];
}

const Calendar = ({ data }: any) => {
  const [date, setDate] = useState(new Date());
  const darkMode = useSelector(selectDarkmode);

  const { data: subjectData } = useQuery(["subject", 1], () =>
    getAllSubject(1)
  );
  const [timeTableModal, setTimeTableModal] = useState(false);
  const [subjectArray, setSubjectArray] = useState<any>();
  const [classDate, setClassDate] = useState<any>();
  const createTimetableMuation = useMutation(createTimetable);
  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(date);
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const getStartDayOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  const startDay = getStartDayOfMonth(date);

  const params = {
    class_id: data?.id,
    class_date: moment(classDate).format("YYYY-MM"),
  };
  const { data: timeTable, refetch: timeRefetch } = useQuery(
    ["subject", params],
    () => getTimetable(params)
  );
  useEffect(() => {
    timeRefetch();
  }, []);

  const renderWeekdayHeaders = () => {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return weekdays.map((day, index) => (
      <div key={index} className="bg-brand p-4 text-white">
        {weekdays[(index + startDay) % 7]}
      </div>
    ));
  };

  const initialValues: Values = {
    subjects: subjectArray?.map((scheduleItem: any) => ({
      subject_id: scheduleItem.subject_id,
      start_time: moment(scheduleItem.start_time, "HH:mm").format("HH:mm"),
      end_time: moment(scheduleItem.end_time, "HH:mm").format("HH:mm"),
    })),
  };

  const renderDays = () => {
    const daysArray: any[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = `${year}-${month}-${i}`;
      const hasSchedule = timeTable?.data[i]?.items.length > 0;

      daysArray.push(
        <div
          key={i}
          className={`p-4 border  text-sm h-20 text-left ${
            darkMode ? "border-gray-700 text-gray-300" : "text-brand"
          } ${hasSchedule ? "bg-red-100" : ""}`}
          onClick={() => {
            setTimeTableModal(true);
            setClassDate(currentDate);
            setSubjectArray(timeTable?.data[i]?.items);
          }}
        >
          <sup className="text-black font-bold">{i} </sup>{" "}
          <div className="flex justify-center flex-row items-center">
            <div>
              <img src={Add_subject} width={20} height={20} alt="" />
            </div>
            <p className="ml-2 text-xs"> Add Subject</p>
          </div>
        </div>
      );
    }
    return daysArray;
  };

  return (
    <div
      className={`w-full mx-auto  ${
        darkMode ? "bg-black" : "bg-white"
      } rounded shadow overflow-hidden mt-4`}
    >
      <div className="flex items-center justify-between bg-brand p-4">
        <button
          onClick={() =>
            setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1))
          }
          className="px-3 py-1 text-white"
        >
          &lt;
        </button>
        <h2 className="text-lg font-semibold text-white">
          {month} {year}
        </h2>
        <button
          onClick={() =>
            setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1))
          }
          className="px-3 py-1 text-white"
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 text-center">
        {renderWeekdayHeaders()}
        {renderDays()}
      </div>

      <Modal
        open={timeTableModal}
        close={() => setTimeTableModal(false)}
        maxWidth="sm"
      >
        <>
          <p
            className={`text-center text-lg font-semibold ${
              darkMode ? "text-gray-300" : "text-black"
            }`}
          >
            Class Schedule
          </p>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              const payload: {
                class_date: string;
                class_id: string | undefined;
                subjects: Subject[];
              } = {
                class_date: moment(classDate).format("YYYY-MM-DD"),
                class_id: data?.id,
                subjects: values.subjects,
              };

              try {
                const result = await createTimetableMuation.mutateAsync(
                  payload
                );

                if (result?.message) {
                  timeRefetch();
                  toast.success(result.message, {
                    duration: 3000,
                  });
                  setTimeTableModal(false);
                }
              } catch (err: any) {
                toast.error(err?.response?.data?.message, {
                  duration: 3000,
                });
              }
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form
                className="flex flex-col pt-3 md:pt-8 px-4 lg:px-8"
                onSubmit={handleSubmit}
              >
                <FieldArray name="subjects">
                  {({ push, remove }) => (
                    <div>
                      {values.subjects.map((subject, index) => (
                        <div
                          key={index}
                          className="lg:flex justify-between items-start flex-wrap w-full"
                        >
                          <div className="w-full lg:w-[32%]">
                            <label
                              htmlFor={`subjects[${index}].subject_id`}
                              className={` text-xs ${
                                darkMode ? "text-gray-200" : "text-gray-700"
                              }`}
                            >
                              Add subjects ({index + 1})
                            </label>
                            <Select
                              name={`subjects[${index}].subject_id`}
                              className={`py-4 rounded-sm ${
                                darkMode ? "bg-dark-200 border-gray-700" : ""
                              }`}
                            >
                              <option
                                value=""
                                className={`${
                                  darkMode ? "text-gray-200" : "text-gray-700"
                                }`}
                              >
                                Select a subject
                              </option>
                              {subjectData?.data?.map((item: any) => (
                                <option value={item?.id} key={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="w-full lg:w-[32%]">
                            <label
                              className={` text-xs ${
                                darkMode ? "text-gray-200" : "text-gray-700"
                              }`}
                            >
                              Start Time ({index + 1})
                            </label>
                            <input
                              className={`py-4 rounded-sm appearance-none border   w-full  px-3 mt-1 text-gray-700  leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${
                                darkMode
                                  ? "bg-dark-200 border-gray-700"
                                  : "border-gray-300 "
                              }`}
                              type="time"
                              id={`subjects[${index}].start_time`}
                              name={`subjects[${index}].start_time`}
                              required
                              value={subject.start_time}
                              onChange={(e) => {
                                const newStartValue = moment(
                                  e.target.value,
                                  "HH:mm"
                                ).format("HH:mm");
                                setFieldValue(
                                  `subjects[${index}].start_time`,
                                  newStartValue
                                );
                              }}
                            />
                          </div>
                          <div className="w-full lg:w-[32%] ">
                            <label
                              className={` text-xs ${
                                darkMode ? "text-gray-200" : "text-gray-700"
                              }`}
                            >
                              End Time ({index + 1})
                            </label>
                            <input
                              className={`py-4 rounded-sm appearance-none border   w-full  px-3 mt-1 text-gray-700  leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${
                                darkMode
                                  ? "bg-dark-200 border-gray-700"
                                  : "border-gray-300 "
                              }`}
                              type="time"
                              id={`subjects[${index}].end_time`}
                              name={`subjects[${index}].end_time`}
                              required
                              value={subject.end_time}
                              onChange={(e) => {
                                const newStartValue = moment(
                                  e.target.value,
                                  "HH:mm"
                                ).format("HH:mm");
                                setFieldValue(
                                  `subjects[${index}].end_time`,
                                  newStartValue
                                );
                              }}
                            />
                          </div>

                          <div className="lg:flex items-center">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className=" text-xs bg-red-800 py-1 px-2 rounded-md text-white"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-center mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            push({
                              subject_id: "",
                              start_time: "",
                              end_time: "",
                            })
                          }
                          className="bg-other text-white text-xs px-4 py-2 rounded"
                        >
                          Add Another Subject
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>

                <div className="self-center lg:flex justify-center items-center">
                  <Button
                    title="Close"
                    className="bg-transparent border w-44 py-4 mt-5 mb-6"
                    textColor={` ${darkMode ? "text-gray-300" : "text-black"}`}
                    onClick={() => setTimeTableModal(false)}
                  />
                  <Button
                    isLoading={isSubmitting}
                    title="Save Class Schedule"
                    className="bg-other w-44 py-4 ml-3"
                    textColor="text-white"
                    type="submit"
                  />
                </div>
              </form>
            )}
          </Formik>
        </>
      </Modal>
    </div>
  );
};

export default Calendar;

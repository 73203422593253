import React from 'react';
import Modal from '.';
import { IC_CANCEL } from '../../assets';
import Button from '../button';
import { useSelector } from 'react-redux';
import { selectDarkmode } from '@/states/slices/authReducer';

function ConfirmationModal({
	isOpen,
	setIsOpen,
	handleClick,
	title,
	loading,
	btnTitle = 'Delete',
}: any) {
	const darkMode = useSelector(selectDarkmode);
	return (
		<Modal open={isOpen} close={() => setIsOpen(false)} maxWidth='xs'>
			<div className=' flex justify-center flex-col items-center pb-8 px-8'>
				<img src={IC_CANCEL} className='h-16 w-16 mx-auto' />
				<div className='mb-8'>
					<p className={`text-xs text-center ${darkMode ? 'text-gray-200' : ''}`}>{title}</p>
				</div>

				<div className='flex justify-center  items-center'>
					<div className='w-32 ml-4'>
						<Button
							onClick={() => setIsOpen(false)}
							iconClassName='h-[0.9rem] w-3'
							title={'cancel '}
							bgColor={'bg-other'}
							hoverColor={''}
							textColor={'text-white'}
							className={' rounded-lg w-full  text-white !bg-black   '}
						/>
					</div>
					<div className='w-32 ml-4'>
						<Button
							isLoading={loading}
							onClick={handleClick}
							iconClassName='h-[0.9rem] w-3'
							title={btnTitle}
							bgColor={'bg-other'}
							hoverColor={''}
							textColor={'text-white'}
							className={' rounded-lg w-full  text-white !bg-black   '}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default ConfirmationModal;

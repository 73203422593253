import { AiOutlineMenu } from "react-icons/ai";
import { CHEVRON_DOWN, search } from "../../assets";
import Typography from "../Typography";

import UserDropdown from "./UserDropdown";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectDarkmode, selectUser } from "@/states/slices/authReducer";

interface header {
  onMenu?: any;
  handleShowFilter?: any;
}

function Header(props: header) {
  const [isOpen, setIsOpen] = useState(false);
  const darkMode = useSelector(selectDarkmode);
  const user = useSelector(selectUser);
  return (
    <div>
      <div
        // onClick={() => setIsOpen(false)}
        className={`h-16 lg:h-12 flex justify-between ${
          !darkMode
            ? "bg-white !text-dark border-b-[#EDEDED]"
            : "text-[#A2A2A2] bg-dark-200 border-b-dark"
        } border-b  items-center flex-row w-full px-4 lg:px-8 lg:py-8`}
      >
        <div className="flex lg:flex-1 items-center gap-x-5 mr-3 relative"></div>
        <div className="flex flex-1 capitalize lg:flex-none shrink-0 gap-x-3 items-center h-full justify-end lg:justify-start">
          <UserDropdown isOpen={isOpen} setIsOpen={setIsOpen} />
          {user?.first_name} {user?.last_name}
        </div>
        <div className="lg:hidden">
          <AiOutlineMenu onClick={props.onMenu} className="h-10 text-4xl" />
        </div>
      </div>
    </div>
  );
}

export default Header;
